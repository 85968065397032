import type { ReactNode } from 'react'
import type { MessageFormatElement } from 'react-intl'
import type { I18n } from 'types/config'
// third-party
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// project import
import useConfig from 'hooks/useConfig'

import { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

// load locales files
function loadLocaleData(locale: I18n) {
  switch (locale) {
    case 'en':
    default:
      return import('utils/locales/en.json')
  }
}

interface Props {
  children: ReactNode
}

function Locales({ children }: Props) {
  const { i18n } = useConfig()

  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >()

  useEffect(() => {
    loadLocaleData(i18n).then(
      (d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
        setMessages(d.default)
      }
    )
  }, [i18n])

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>
        </IntlProvider>
      )}
    </>
  )
}

export default Locales
