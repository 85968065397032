// material-ui
import type { ChipProps } from '@mui/material'

import type { ReactNode } from 'react'

import type { FeatureFlagType } from './feature-flag'
import type { GenericCardProps } from './root'

export enum NavActionType {
  FUNCTION = 'function',
  LINK = 'link',
}

export interface NavActionProps {
  type: NavActionType
  label: string
  function?: any
  url?: string
  target?: boolean
  icon: GenericCardProps['iconPrimary'] | string
}

export interface BreadcrumbOverride {
  label: string
  url?: string
}

export interface NavItemType {
  breadcrumbs?: boolean
  caption?: ReactNode | string
  children?: NavItemType[]
  elements?: NavItemType[]
  permissions?: string[]
  featureFlag?: FeatureFlagType
  chip?: ChipProps
  color?: 'primary' | 'secondary' | 'default' | undefined
  disabled?: boolean
  external?: boolean
  isDropdown?: boolean
  icon?: GenericCardProps['iconPrimary'] | string
  id?: string
  search?: string
  target?: boolean
  title?: ReactNode | string
  type?: string
  url?: string | undefined
  actions?: NavActionProps[]
}

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top'

export interface MenuProps {
  openedItem: string
  openedComponent: string
  openedHorizontalItem: string | null

  /**
   * Indicate if dashboard layout menu open or not
   */
  isDashboardDrawerOpened: boolean

  /**
   * Indicate if component layout menu open or not
   */
  isComponentDrawerOpened: boolean
}
