import type { WizardConfiguration, WizardTemplateComponentConfig } from 'types/wizard-config'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import ComponentDisplay from 'components/ComponentDisplay'
import React from 'react'
import { ComponentType } from 'types/components'

interface TemplateStepProps {
  selectedConfiguration: WizardConfiguration | null
}

const TemplateStep: React.FC<TemplateStepProps> = ({ selectedConfiguration }) => {
  console.log('selectedConfiguration', selectedConfiguration)

  const lookupMinValue = (component: WizardTemplateComponentConfig, selectedConfiguration: WizardConfiguration) => {
    console.log('target.id', component.targetId)
    console.log('selectedConfiguration.targets', selectedConfiguration.targets)
    const target = selectedConfiguration.targets.find((target) => target.id === component.targetId)
    return target?.minTargetValue ?? null
  }

  const lookupComponentType = (
    component: WizardTemplateComponentConfig,
    selectedConfiguration: WizardConfiguration
  ): ComponentType => {
    const target = selectedConfiguration.targets.find((target) => target.id === component.targetId)
    return target?.format ?? ComponentType.COMPONENT_TYPE_FLOAT
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h5">Templates</Typography>
        <Box>
          {selectedConfiguration?.templates.map((template) => (
            <SimpleTreeView key={template.id} slots={{ collapseIcon: DownOutlined, expandIcon: RightOutlined }}>
              <TreeItem itemId={template.id} label={template.label}>
                {template.componentSetup.map((componentSection) => (
                  <TreeItem
                    key={`${template.id}-${componentSection.id}`}
                    itemId={`${template.id}-${componentSection.id}`}
                    label={`Section: ${componentSection.label}`}
                  >
                    <Box sx={{ pl: 4 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Allocation</TableCell>
                            <TableCell>Max Payout</TableCell>
                            <TableCell>Accelerator</TableCell>
                            <TableCell>Target</TableCell>
                            <TableCell>Min</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {componentSection.components.map((component) => (
                            <TableRow key={`${template.id}-${componentSection.id}-${component.id}`}>
                              <TableCell>{component.label}</TableCell>
                              <TableCell>
                                <ComponentDisplay
                                  componentType={lookupComponentType(component, selectedConfiguration)}
                                  value={component.defaultAllocationPercentage}
                                />
                              </TableCell>
                              <TableCell>
                                <ComponentDisplay
                                  componentType={lookupComponentType(component, selectedConfiguration)}
                                  value={component.defaultPayoutPercentage}
                                />
                              </TableCell>
                              <TableCell>
                                <ComponentDisplay
                                  componentType={lookupComponentType(component, selectedConfiguration)}
                                  value={component.defaultAccelerator}
                                />
                              </TableCell>
                              <TableCell>
                                <ComponentDisplay
                                  componentType={lookupComponentType(component, selectedConfiguration)}
                                  value={
                                    selectedConfiguration.targets.find((target) => target.id === component.targetId)
                                      ?.value || ''
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <ComponentDisplay
                                  componentType={lookupComponentType(component, selectedConfiguration)}
                                  value={lookupMinValue(component, selectedConfiguration)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </TreeItem>
                ))}
              </TreeItem>
            </SimpleTreeView>
          ))}
        </Box>
      </Box>
    </Paper>
  )
}

export default TemplateStep
