import { Alert, Button } from '@mui/material'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { useNavigate } from 'react-router-dom'

function PurcentAlert() {
  const { purcentAppState } = useGlobalState()
  const navigate = useNavigate()

  const takeAction = () => {
    if (purcentAppState?.alert?.link) {
      navigate(purcentAppState.alert.link)
    }
  }

  return (
    <>
      {purcentAppState?.alert && (
        <Alert
          severity={purcentAppState.alert.level}
          sx={{ mt: 0 }}
          action={
            purcentAppState.alert.link && (
              <Button variant="outlined" onClick={takeAction}>
                {purcentAppState.alert.actionMessage}
              </Button>
            )
          }
        >
          {purcentAppState.alert.message}
        </Alert>
      )}
    </>
  )
}

export default PurcentAlert
