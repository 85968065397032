import { useEffect, useState } from 'react'

// A single key that holds all app values in an object
const MAIN_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY || 'applicationState'

export default function useLocalStorage<ValueType>(key: string, defaultValue: ValueType) {
  const [value, setValue] = useState<ValueType>(() => {
    if (typeof window === 'undefined') {
      // If we're on the server or can't access `window`, just return the default value
      return defaultValue
    }

    // Remove all other application state keys to allow us to upgrade the app state format in future releases
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const storageKey = localStorage.key(i)
      if (
        storageKey &&
        storageKey !== MAIN_KEY &&
        (storageKey.includes('applicationState') || storageKey.includes('purcentApp'))
      ) {
        localStorage.removeItem(storageKey)
      }
    }

    // Retrieve the entire app state from localStorage
    const storedAppState = localStorage.getItem(MAIN_KEY)

    if (!storedAppState) {
      // Nothing stored yet, return the default
      return defaultValue
    }

    try {
      const parsedAppState = JSON.parse(storedAppState)
      // If our key exists in the app state, use it; otherwise, fall back to default
      return parsedAppState && parsedAppState.hasOwnProperty(key) ? parsedAppState[key] : defaultValue
    } catch (error) {
      // If parsing fails, fallback to default and reset the storage
      console.error('Error parsing app state:', error)
      return defaultValue
    }
  })

  useEffect(() => {
    // Listen to storage events so all tabs stay in sync
    const listener = (e: StorageEvent) => {
      if (typeof window !== 'undefined' && e.storageArea === localStorage && e.key === MAIN_KEY) {
        try {
          const parsedAppState = e.newValue ? JSON.parse(e.newValue) : {}
          // Update value if present, otherwise revert to default
          setValue(parsedAppState && parsedAppState.hasOwnProperty(key) ? parsedAppState[key] : defaultValue)
        } catch (error) {
          console.error('Error parsing app state:', error)
          setValue(defaultValue)
        }
      }
    }

    window.addEventListener('storage', listener)
    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [key, defaultValue])

  const setValueInLocalStorage = (newValue: ValueType | ((currentValue: ValueType) => ValueType)) => {
    setValue((currentValue) => {
      const result = typeof newValue === 'function' ? (newValue as Function)(currentValue) : newValue

      if (typeof window !== 'undefined') {
        const storedAppState = localStorage.getItem(MAIN_KEY)
        let parsedAppState: Record<string, any> = {}

        if (storedAppState) {
          try {
            parsedAppState = JSON.parse(storedAppState)
          } catch {
            // If parsing fails, start fresh
            parsedAppState = {}
          }
        }

        // Update the key with the new value
        parsedAppState[key] = result

        // Save back to localStorage
        localStorage.setItem(MAIN_KEY, JSON.stringify(parsedAppState))
      }

      return result
    })
  }

  const getValueInLocalStorage = () => {
    if (typeof window !== 'undefined') {
      const storedAppState = localStorage.getItem(MAIN_KEY)
      if (storedAppState) {
        try {
          const parsedAppState = JSON.parse(storedAppState)
          return parsedAppState[key] ?? defaultValue
        } catch {
          return defaultValue
        }
      }
    }
    return defaultValue
  }

  return [value, setValueInLocalStorage, getValueInLocalStorage] as const
}
