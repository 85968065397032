import type { FC } from 'react'
import { Typography } from '@mui/material'

const SimpleTitle: FC<{ title: string }> = ({ title }) => (
  <Typography variant="h2" style={{ marginBottom: '20px' }}>
    {title}
  </Typography>
)

export default SimpleTitle
