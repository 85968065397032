// project import

import type { PalettesProps } from '@ant-design/colors'

import type { PresetColor, ThemeMode } from 'types/config'
// types
import type { PaletteThemeProps } from 'types/theme'
import ThemePurcent from './theme-purcent'

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

function Theme(colors: PalettesProps, presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps {
  return ThemePurcent(colors, mode)
}

export default Theme
