import { ComponentType } from 'types/components'

export const currencyFormatter = new Intl.NumberFormat('default', {
  style: 'currency',
  currency: 'USD',
})

export const twoDigitPercentFormatter = new Intl.NumberFormat('default', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const wholePercentFormatter = new Intl.NumberFormat('default', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const integerFormatter = new Intl.NumberFormat('default', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const decimalFormatter = new Intl.NumberFormat('default', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const numberFormatter = new Intl.NumberFormat('default')

export function formatter(value: number, format: ComponentType | null) {
  switch (format) {
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return twoDigitPercentFormatter.format(value)
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return currencyFormatter.format(value)
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return integerFormatter.format(value)
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return decimalFormatter.format(value)
    default:
      return numberFormatter.format(value)
  }
}
