import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// assets
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'

// material-ui
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// types
import { useGetMenuMaster } from 'api/menu'

// project import
import Avatar from 'components/@extended/Avatar'
import Transitions from 'components/@extended/Transitions'
import useAuth from 'hooks/useAuth'
import { useHasAnyOrganizationPermissions } from 'hooks/useOrganizations'

// types
import { ThemeMode } from 'types/config'
import { Permissions } from 'types/permissions'

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

function Profile() {
  const theme = useTheme()
  const navigate = useNavigate()

  const { auth0User: user, profile } = useAuth()
  const { logout } = useAuth0()
  const { hasPermissions } = useHasAnyOrganizationPermissions([Permissions.ORGANIZATION_WRITE])

  const { menuMaster } = useGetMenuMaster()

  const avatar = user?.avatar
  const handleLogout = async () => {
    try {
      localStorage.clear()
      await logout({
        logoutParams: {
          returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const anchorRef = useRef<any>(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? theme.palette.grey[300] : theme.palette.grey[200]

  const handleProfileClick = () => {
    navigate('/profile')
    handleToggle()
    menuMaster.openedItem = ''
  }

  const handleSettingsClick = () => {
    navigate('/company-settings')
    handleToggle()
  }

  const hoverColor = theme.palette.mode === ThemeMode.DARK ? theme.palette.grey[300] : theme.palette.grey[200]

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': {
            bgcolor: hoverColor,
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1.25} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" src={avatar} size="sm" />
          <Stack>
            <Typography variant="h6">{`${profile?.user?.firstName} ${profile?.user?.lastName}`}</Typography>
            <Typography variant="body2" color="textSecondary">
              {profile?.user?.position}
            </Typography>
          </Stack>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 240,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Paper>
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemIcon-root': { minWidth: 32 },
                      '& .MuiListItemButton-root:hover': { bgcolor: hoverColor },
                    }}
                  >
                    <ListItemButton onClick={handleProfileClick}>
                      <ListItemIcon>
                        <UserOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </ListItemButton>
                    {hasPermissions && (
                      <ListItemButton onClick={handleSettingsClick}>
                        <ListItemIcon>
                          <SettingOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Company Settings" />
                      </ListItemButton>
                    )}
                    <ListItemButton onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </List>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Profile
