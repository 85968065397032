import type { AppState } from '@auth0/auth0-react'
import type { ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

// Create a wrapper component to access the navigate function
function Auth0ProviderWrapper({ children }: { children: ReactNode }) {
  const navigate = useNavigate()

  function onRedirectCallback(appState: AppState | undefined) {
    // Navigate to the stored return path or default to root
    console.log('onRedirectCallback called', appState)
    void navigate(appState?.returnTo ?? window.location.pathname)
  }

  console.log('AuthProvider called')
  console.log('window.location.pathname', window.location.pathname)
  console.log('window.location.origin', window.location.origin)

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
        scope: 'openid profile email',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export { Auth0ProviderWrapper }
