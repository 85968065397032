import type { JSX } from 'react'
// types
import type { KeyedObject } from 'types/root'

// material-ui
import {
  Button,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Paper from 'components/@extended/Paper'
import useAuth from 'hooks/useAuth'

// project imports

import { useFetchPlans } from 'hooks/usePlans'

import * as React from 'react'
import { useNavigate } from 'react-router-dom'

// table columns
interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any) => string | boolean | JSX.Element
}

interface CreateButtonProps {
  userId: string
  compPlanId: string
}

function ViewButton({ userId, compPlanId }: CreateButtonProps) {
  const navigate = useNavigate()

  function handleClick() {
    navigate(`/employee/${userId}/compplan/${compPlanId}`)
  }

  return (
    <Button type="button" variant="contained" color="primary" onClick={handleClick}>
      View
    </Button>
  )
}

interface CompPlansTableProps {
  userId: string
}

export default function CompPlansTable({ userId }: CompPlansTableProps) {
  const theme = useTheme()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const { token } = useAuth()
  const { compPlans, isLoading } = useFetchPlans(token!, userId, null)

  const columns: ColumnProps[] = [
    { id: 'position', label: 'Position', minWidth: 120 },
    { id: 'revision', label: 'Revision', minWidth: 40 },
    { id: 'effectiveDate', label: 'Effective Date', minWidth: 170 },
    {
      id: 'id',
      label: 'View Actions',
      minWidth: 200,
      format: (compPlanId: string) => (
        <>
          <ViewButton userId={userId} compPlanId={compPlanId} />
        </>
      ),
    },
  ]

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  return isLoading ? (
    <Grid container spacing={12}>
      <Grid
        size={{ xs: 12 }}
        style={{
          display: 'flex', // Enables flexbox
          justifyContent: 'center', // Centers horizontally
          alignItems: 'center', // Centers vertically
          height: '100%', // Takes full height of the container
        }}
      >
        <CircularProgress color="primary" />
        Loading
      </Grid>
    </Grid>
  ) : (
    <Paper>
      {/* table */}
      <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              '& th': {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `2px solid ${theme.palette.divider} !important`,
              },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{
                    minWidth: column.minWidth,
                    position: 'sticky !important',
                  }}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {compPlans.plans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: KeyedObject) => (
              <TableRow id={`tble-row-${row.plan.id}`} sx={{ py: 3 }} role="checkbox" tabIndex={-1} key={row.plan.code}>
                {columns.map((column) => {
                  const value = row.plan[column.id]
                  return (
                    <TableCell id={`cell-${column.id}`} key={column.id} align={column.align}>
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {/* table pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={compPlans.plans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
