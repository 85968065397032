import type { ID, OrganizationIDs, PeriodIDs, Revision, Timestamps } from './common'
import type { UserRole } from './role'
import type { User } from './user'

export interface PlanMetadata extends ID, OrganizationIDs, PeriodIDs, Revision, Timestamps {
  effectiveDate: string
  endDate: string | null
  organizationName: string
  basePlus: boolean
  baseSalary: number
  variablePercent: number
  variableCap: number
  periodProration: number
  finalized: boolean
}

export interface UserProfile {
  user: User
  roles: UserRole[]
  plans?: PlanMetadata[]
}

export function getOrganizationLabelMap(userProfile: UserProfile): Record<string, string> {
  return userProfile.roles.reduce(
    (acc: Record<string, string>, role) => {
      acc[role.organization.id] = role.organization.name
      return acc
    },
    {} as Record<string, string>
  )
}

export function checkPermission(userProfile: UserProfile, requiredPermission: string): boolean {
  let found = false
  for (const role of userProfile.roles) {
    if (role.role.permissions.includes(requiredPermission)) {
      found = true
      break
    }
  }
  return found
}

export function checkPermissions(userProfile: UserProfile, requiredPermissions: string[]): boolean {
  let found = false
  for (const role of userProfile.roles) {
    if (
      requiredPermissions.some((permission) => {
        console.log('Checking permission:', permission)
        return role.role.permissions.includes(permission)
      })
    ) {
      found = true
      break
    }
  }
  return found
}

export function getRootOrganizationId(userProfile: UserProfile): string | null {
  if (userProfile && userProfile.roles && userProfile.roles.length > 0) {
    return userProfile.roles[0].organization.rootOrganizationId
  }
  return null
}

export function getTopParentOrganizationId(userProfile: UserProfile): string | null {
  const rootOrg = userProfile.roles.find((role) => role.organization.rootOrganizationId === role.organization.id)
  return rootOrg ? rootOrg.organization.id : null
}

export function getOrganizationIdsWithPermissions(userProfile: UserProfile, requiredPermissions: string[]): string[] {
  if (
    userProfile === null ||
    userProfile === undefined ||
    userProfile.roles === null ||
    userProfile.roles === undefined
  ) {
    return []
  }

  return userProfile.roles.reduce((acc: string[], role) => {
    const hasAllPermissions = requiredPermissions.every((permission) => role.role.permissions.includes(permission))

    if (hasAllPermissions && !acc.includes(role.organization.id)) {
      acc.push(role.organization.id)
    }

    return acc
  }, [])
}

export function getUniqueOrganizationRoles(userProfile: UserProfile, memberRolesOnly = false): UserRole[] {
  const roles = memberRolesOnly ? userProfile.roles.filter((role) => role.role.name === 'Member') : userProfile.roles

  // Create a map to store the highest priority role for each organization
  const orgRoleMap = roles.reduce((acc: Record<string, UserRole>, role) => {
    const orgId = role.organization.id
    const currentPriority = role.role.rank
    const existingRole = acc[orgId]

    // If no existing role for this org, or current role has higher priority (lower index)
    if (!existingRole || existingRole.role.rank > currentPriority) {
      acc[orgId] = role
    }

    return acc
  }, {})

  // Convert map back to array
  return Object.values(orgRoleMap)
}

export function getMemberRole(userProfile: UserProfile): UserRole | null {
  return userProfile.roles.find((role) => role.role.name === 'Member') || null
}

export function getActivePlans(userProfile: UserProfile, currentDate: string): PlanMetadata[] {
  if (!userProfile.plans) {
    return []
  }

  return userProfile.plans.filter((plan) => {
    const effectiveDate = new Date(plan.effectiveDate)
    const effectiveEndDate = plan.endDate ? new Date(plan.endDate) : null
    const now = new Date(currentDate)

    return effectiveDate <= now && (!effectiveEndDate || effectiveEndDate >= now)
  })
}

export function getPlansByOrganizationId(userProfile: UserProfile, organizationId: string): PlanMetadata[] {
  if (!userProfile.plans) {
    return []
  }

  return userProfile.plans.filter((plan) => plan.organizationId === organizationId)
}

export function getLatestPlanByPeriod(userProfile: UserProfile, periodId: string): PlanMetadata | null {
  if (!userProfile.plans) {
    return null
  }

  const plansForPeriod = userProfile.plans.filter((plan) => plan.periodId === periodId)

  if (plansForPeriod.length === 0) {
    return null
  }

  // Sort by revision in descending order and return the latest plan
  return plansForPeriod.sort((a, b) => b.revision - a.revision)[0]
}
