// material-ui
import { Grid2 as Grid, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useFetchPlansWithAccruals } from 'hooks/usePlans'
import React, { useEffect, useState } from 'react'
import { Permissions } from 'types/permissions'
import { getOrganizationIdsWithPermissions } from 'types/user-profile'
import EmployeeDashboard from './EmployeeDashboard'
import ManagerDashboard from './ManagerDashboard'

const Dashboard: React.FC = () => {
  const { token, profile } = useAuth()

  const organizationIdsCanViewManagerDashboard =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.MANAGER_DASHBOARD_READ]) : []

  const showManagerDashboard = organizationIdsCanViewManagerDashboard.length > 0

  const [view, setView] = useState(showManagerDashboard ? 'manager' : 'employee')
  const theme = useTheme()
  const [isReady, setIsReady] = useState(false)

  const DASHBOARD_TITLE = view === 'manager' ? 'Team Dashboard' : 'Employee Dashboard'

  const userId = profile?.user?.id
  const {
    compPlans,
    isLoading: isLoadingPlans,
    isError: isErrorPlans,
  } = useFetchPlansWithAccruals(token!, userId!, null)

  useEffect(() => {
    if (compPlans && !isLoadingPlans && !isErrorPlans) {
      setIsReady(true)
    }
  }, [compPlans, isLoadingPlans, isErrorPlans])

  console.log('isLoadingPlans', isLoadingPlans)
  console.log('isReady', isReady)

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  if (isLoadingPlans || !isReady || !profile) {
    return <Typography>Loading...</Typography>
  }

  return (
    <>
      <Grid container pb={2} spacing={2}>
        <Grid size={{ xs: 9 }}>
          <SimpleTitle title={DASHBOARD_TITLE} />
        </Grid>
        {showManagerDashboard && (
          <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="View toggle"
              size="small"
              sx={{ width: '100%', height: 32, mt: 1 }}
            >
              <ToggleButton value="manager" aria-label="Manager View" sx={{ width: '50%' }}>
                Manager View
              </ToggleButton>
              <ToggleButton value="employee" aria-label="Employee View" sx={{ width: '50%' }}>
                Employee View
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Grid>
      {!showManagerDashboard || view === 'employee' ? (
        <EmployeeDashboard
          key="employee-dashboard"
          user={profile.user}
          plans={compPlans.plans}
          theme={theme}
          status={isErrorPlans ? 400 : 200}
        />
      ) : (
        <ManagerDashboard />
      )}
    </>
  )
}

export default Dashboard
