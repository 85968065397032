import type { ExpandedCompensationPlan } from 'types/comp-plan'
import type { Period } from 'types/periods'
import { Box, CardContent, Typography, useTheme } from '@mui/material'
import Card from 'components/@extended/Card'
import CardFooter from 'components/cards/CardFooter'
import React from 'react'
import { RowType } from 'types/comp-allocations'

interface ProgressOverviewProps {
  plan: ExpandedCompensationPlan
  selectedPeriods: string[]
  periodMap: Record<string, Period>
}

const ProgressOverview: React.FC<ProgressOverviewProps> = ({ plan, selectedPeriods, periodMap }) => {
  const theme = useTheme()

  const baseSalary = plan.plan.baseSalary

  const approvedAccruals = plan.progress || []

  // Calculate total earned considering both ledger and accruals
  const totalEarned = plan.plan.variableAllocations
    .flatMap((table) => table.rows)
    .filter((row) => selectedPeriods.includes(row.periodId!))
    .reduce((acc, row) => {
      // Find matching ledger or progress entry
      const ledgerEntry = plan.ledger?.find((entry) => entry.allocationRowId === row.id)
      const progressEntry = approvedAccruals.find((accrual) => accrual.allocationRowId === row.id)
      const period = periodMap[row.periodId!]

      // Use ledger if period is closed and ledger exists, otherwise use progress
      const amount =
        period.isClosed && ledgerEntry != null ? ledgerEntry.balance || 0 : progressEntry?.proratedPayoutAmount || 0

      return acc + amount
    }, 0)

  const onTargetTotal = plan.plan.variableAllocations
    .flatMap((table) => table.rows) // Get all rows from all allocation tables
    .filter(
      (row) =>
        selectedPeriods.includes(row.periodId!) &&
        row.rowType === RowType.CALCULATION_ROW && // Only include calculation rows
        !row.disabled // Exclude disabled rows
    )
    .reduce((acc, row) => {
      // Calculate target amount for this row: baseSalary * variablePercent * rowAllocationPercent
      const rowTarget = baseSalary * plan.plan.variablePercent * row.allocationPercentage
      return acc + rowTarget
    }, 0)

  const formattedTotalEarned = totalEarned.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const formattedOnTargetTotal = onTargetTotal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const earnedPercentage = onTargetTotal > 0 ? Math.round((totalEarned / onTargetTotal) * 100) : 0

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{ mb: 1, ml: 3 }}>
          <Typography sx={{ fontWeight: '500', fontSize: 18, mt: 1 }}>Total Variable Compensation Earned</Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
            <Typography sx={{ fontWeight: '700', fontSize: 30, mt: 1 }}>${formattedTotalEarned}</Typography>
            <Typography sx={{ fontWeight: '200', fontSize: 30 }}>
              ({earnedPercentage}
              %)
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardFooter>
        <Box sx={{ ml: 5 }}>
          <Typography sx={{ color: theme.palette.grey[400], fontWeight: 'bold', fontSize: 14 }}>
            {`On-Target Variable Compensation: $${formattedOnTargetTotal}`}
          </Typography>
        </Box>
      </CardFooter>
    </Card>
  )
}

export default ProgressOverview
