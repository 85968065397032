import type { ApprovalChain } from 'types/approvals'
import type { LedgerAccount } from 'types/ledger-account'
import type { OrganizationSettingDTO } from 'types/organization'
import { Box, Button, FormControl, Grid2 as Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import { TextField } from 'components/@extended/TextField'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { supportedCurrencies } from 'utils/currency'
import ApprovalSettings from './ApprovalSettings'
import TemplateDefaults from './TemplateDefaults'

interface OrganizationSettingsSectionProps {
  settings: OrganizationSettingDTO[] | undefined
  saveSettings: (settings: OrganizationSettingDTO[]) => Promise<void>
  approvalChains: ApprovalChain[] | undefined
  ledgerAccounts: LedgerAccount[] | null
}

const DEFAULT_SETTINGS: Record<string, Omit<OrganizationSettingDTO, 'organizationId' | 'rootOrganizationId'>> = {
  'default-ledger-account': {
    settingKey: 'default-ledger-account',
    settingName: 'Default Ledger Account',
    settingValue: '',
    settingType: 'string',
  },
  currency: { settingKey: 'currency', settingName: 'Currency', settingValue: 'USD', settingType: 'string' },
  'currency-decimals': {
    settingKey: 'currency-decimals',
    settingName: 'Currency Decimals',
    settingValue: '2',
    settingType: 'integer',
  },
  'default-base-plus': {
    settingKey: 'default-base-plus',
    settingName: 'Default Base Plus',
    settingValue: 'true',
    settingType: 'boolean',
  },
  'default-example-compensation': {
    settingKey: 'default-example-compensation',
    settingName: 'Default Example Compensation',
    settingValue: '100000',
    settingType: 'float',
  },
  'default-variable-compensation': {
    settingKey: 'default-variable-compensation',
    settingName: 'Default Variable Compensation',
    settingValue: '0.1,0.2,0.3',
    settingType: 'string',
  },
  'default-max-payout': {
    settingKey: 'default-max-payout',
    settingName: 'Default Max Payout',
    settingValue: '1.2,1.5,2.0',
    settingType: 'string',
  },
  'default-template-tables': {
    settingKey: 'default-template-tables',
    settingName: 'Default Tables',
    settingValue: '',
    settingType: 'string',
  },
  'target-approval-chain': {
    settingKey: 'target-approval-chain',
    settingName: 'Target Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
  'metric-approval-chain': {
    settingKey: 'metric-approval-chain',
    settingName: 'Metric Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
  'plan-approval-chain': {
    settingKey: 'plan-approval-chain',
    settingName: 'Plan Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
  'accrual-approval-chain': {
    settingKey: 'accrual-approval-chain',
    settingName: 'Accrual Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
  'accrual-ledger-approval-chain': {
    settingKey: 'accrual-ledger-approval-chain',
    settingName: 'Accrual Ledger Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
}

const OrganizationSettingsSection: React.FC<OrganizationSettingsSectionProps> = ({
  settings,
  saveSettings,
  approvalChains,
  ledgerAccounts,
}) => {
  const [localSettings, setLocalSettings] = useState<OrganizationSettingDTO[]>([])
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (settings && settings.length > 0) {
      const settingsMap = settings.reduce(
        (acc, setting) => {
          acc[setting.settingKey] = setting
          return acc
        },
        {} as Record<string, OrganizationSettingDTO>
      )

      const completeSettings = Object.keys(DEFAULT_SETTINGS).map((key) => ({
        ...DEFAULT_SETTINGS[key],
        ...settingsMap[key],
        organizationId: settings[0]?.organizationId || '',
        rootOrganizationId: settings[0]?.rootOrganizationId || '',
      }))

      setLocalSettings(completeSettings)
    } else {
      // If settings is undefined or empty, use default settings
      const defaultCompleteSettings = Object.keys(DEFAULT_SETTINGS).map((key) => ({
        ...DEFAULT_SETTINGS[key],
        organizationId: '',
        rootOrganizationId: '',
      }))
      setLocalSettings(defaultCompleteSettings)
    }
  }, [settings])

  const handleSave = async () => {
    try {
      await saveSettings(localSettings)
      enqueueSnackbar('Settings saved successfully', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Failed to save settings', { variant: 'error' })
    }
  }

  // Update the handleChange function to handle both string and number indices
  const handleChange = (keyOrIndex: string | number, value: string) => {
    setLocalSettings((prevSettings) =>
      prevSettings.map((setting, index) =>
        typeof keyOrIndex === 'string'
          ? setting.settingKey === keyOrIndex
            ? { ...setting, settingValue: value }
            : setting
          : index === keyOrIndex
            ? { ...setting, settingValue: value }
            : setting
      )
    )
  }

  const getCurrencySettings = () => {
    const currencyCode = localSettings.find((s) => s.settingName === 'Currency')?.settingValue || 'USD'
    const currencyDecimals = Number.parseInt(
      localSettings.find((s) => s.settingName === 'Currency Decimals')?.settingValue || '2',
      10
    )
    return { currency: currencyCode, currencyDecimals }
  }

  const renderAccountingSettings = () => (
    <Paper elevation={0} sx={{ mb: 4 }}>
      <Typography variant="h5" gutterBottom mb={2}>
        Accounting Settings
      </Typography>
      <Grid container spacing={3} p={2}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink variant="outlined">
              Default Ledger Account
            </InputLabel>
            <Select
              fullWidth
              label="Default Ledger Account"
              variant="outlined"
              value={localSettings.find((s) => s.settingName === 'Default Ledger Account')?.settingValue || ''}
              onChange={(e) => handleChange('default-ledger-account', e.target.value)}
              style={{ borderRadius: '21px' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {(ledgerAccounts || [])?.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.label} ({account.externalId})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink variant="outlined">
              Currency
            </InputLabel>
            <Select
              fullWidth
              label="Currency"
              variant="outlined"
              value={localSettings.find((s) => s.settingName === 'Currency')?.settingValue || ''}
              onChange={(e) => handleChange('currency', e.target.value)}
              style={{ borderRadius: '21px' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {(supportedCurrencies || []).map((currency) => (
                <MenuItem key={currency.code} value={currency.code}>
                  {currency.code} -{currency.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <TextField
            variant="outlined"
            pill
            shrink
            type="number"
            fullWidth
            label="Currency Decimals"
            slotProps={{
              input: {
                inputProps: { min: 0, max: 4 },
                inputMode: 'numeric',
              },
            }}
            value={localSettings.find((s) => s.settingName === 'Currency Decimals')?.settingValue || ''}
            onChange={(e) => handleChange('currency-decimals', e.target.value)}
          />
        </Grid>
      </Grid>
    </Paper>
  )

  return (
    <Box>
      {renderAccountingSettings()}
      <TemplateDefaults
        settings={localSettings}
        handleChange={handleChange}
        currency={getCurrencySettings().currency}
        currencyDecimals={getCurrencySettings().currencyDecimals}
      />
      <ApprovalSettings settings={localSettings} handleChange={handleChange} approvalChains={approvalChains} />

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Settings
        </Button>
      </Box>
    </Box>
  )
}

export default OrganizationSettingsSection
