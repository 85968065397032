import type { OrganizationContactInfo, OrganizationSetting, OrganizationSettingDTO } from 'types/organization'
import type { UserRole } from 'types/role'
import { Box, CircularProgress, Grid2 as Grid, Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useFetchApprovalChains } from 'hooks/useApprovals'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { useFetchOrganization, useFetchOrganizationSettings } from 'hooks/useOrganizations'
import React, { useEffect, useState, useTransition } from 'react'
import OrganizationContactSection from './OrganizationContactSection'
import OrganizationSettingsSection from './OrganizationSettingsSection'

const OrganizationSettingsPage: React.FC = () => {
  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const theme = useTheme()

  const [organizationId, setOrganizationId] = useState('')
  const [isPending, startTransition] = useTransition()
  const { isLoading: isLoadingOrg, isError: isErrorOrg, organization } = useFetchOrganization(organizationId, token!)
  const {
    settings,
    isLoading: isLoadingSettings,
    isError: isErrorSettings,
    saveSettings: originalSaveSettings,
  } = useFetchOrganizationSettings(organizationId, token!)

  const saveSettings = (settings: OrganizationSettingDTO[]) => originalSaveSettings(settings as OrganizationSetting[])

  const {
    approvalChains,
    isLoading: isLoadingApprovalChains,
    isError: isErrorApprovalChains,
  } = useFetchApprovalChains(token!, organization?.rootOrganizationId || '')

  const {
    ledgerAccounts,
    isLoading: isLoadingLedgerAccounts,
    isError: isErrorLedgerAccounts,
  } = useFetchLedgerAccounts(organization?.rootOrganizationId || '', token!, organization?.rootOrganizationId !== '')

  // Remove the state declaration and just use undefined for now
  const contactInfo = undefined

  // TODO: Add hook to fetch contact info
  // const { data: contactInfo, isLoading: isLoadingContactInfo } = useFetchOrganizationContactInfo(organizationId, token!)

  // New function to find the first top-level organization
  const findFirstTopLevelOrg = (roles: UserRole[]) => {
    if (!roles) return ''

    // Create a map of all organizations
    const organizationMap = new Map(roles.map((role) => [role.organization.id, role.organization]))

    // Find the first org that doesn't have a parent or its parent isn't in our org list
    const firstTopLevelOrg = roles.find(
      (role) => !role.organization.parentId || !organizationMap.has(role.organization.parentId)
    )

    return firstTopLevelOrg?.organization.id || ''
  }

  useEffect(() => {
    if (organizationId === '' && profile && profile?.roles?.length > 0) {
      const firstTopLevelOrgId = findFirstTopLevelOrg(profile.roles)
      startTransition(() => {
        setOrganizationId(firstTopLevelOrgId)
      })
    }
  }, [profile])

  const isLoading =
    isLoadingOrg ||
    isLoadingSettings ||
    isLoadingProfile ||
    isLoadingApprovalChains ||
    isLoadingLedgerAccounts ||
    // isLoadingContactInfo ||
    isPending
  const isError = isErrorOrg || isErrorSettings || isErrorApprovalChains || isErrorLedgerAccounts

  const handleOrganizationChange = (selected: string) => {
    startTransition(() => {
      setOrganizationId(selected)
    })
  }

  const handleContactInfoChange = (updatedInfo: Partial<OrganizationContactInfo>) => {
    // TODO: Add API call to update organization contact info
    console.log('Contact info updated:', updatedInfo)
  }

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress color="primary" />
      </Grid>
    )
  }

  if (isError) {
    return <div>Failed to load organization data or settings</div>
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: '20px',
        }}
      >
        <Typography variant="h2">{organization?.name || 'Loading...'} Team Settings</Typography>
        <SingleOrganizationSelector
          userProfile={profile!}
          selectedOrganizationId={organizationId}
          handleChange={handleOrganizationChange}
          width={400}
          labelBackgroundColor={theme.palette.background.default}
        />
      </Box>
      {isPending ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <>
          {organization && (
            <OrganizationContactSection
              organization={organization}
              contactInfo={contactInfo}
              onContactInfoChange={handleContactInfoChange}
              theme={theme}
            />
          )}
          <OrganizationSettingsSection
            settings={settings}
            saveSettings={saveSettings}
            approvalChains={approvalChains}
            ledgerAccounts={ledgerAccounts}
          />
        </>
      )}
    </>
  )
}

export default OrganizationSettingsPage
