// material-ui
import { useTheme } from '@mui/material/styles'
import logo from 'assets/images/Purcent-Logo-Color-W145.png'

import logoDark from 'assets/images/Purcent-Logo-LightColor-W145.png'
import { ThemeMode } from 'types/config'

// ==============================|| LOGO SVG ||============================== //

function LogoMain({ reverse, ...others }: { reverse?: boolean }) {
  const theme = useTheme()
  return <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Purcent" width="100" />
}

export default LogoMain
