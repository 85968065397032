import type { Component, TargetDTO } from 'types/components'
import { CardContent, Divider, Grid2 as Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Paper from 'components/@extended/Paper'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentField from 'components/ComponentField'
import { useCreatePlanContext } from 'contexts/CreatePlanContextProvider'
import { Fragment } from 'react'
import { ComponentType, unformatValue } from 'types/components'
import { createIdLabelMap } from 'types/periods'

interface PlanTargetRowProps {
  componentId: string
  component?: Component
  target?: TargetDTO
  reviewOnly?: boolean
  periodLabel?: string
  handleUpdateTarget: (value: string | number, compositeId: string) => void
}

const PlanTargetRow: React.FC<PlanTargetRowProps> = ({
  componentId,
  component,
  target,
  reviewOnly,
  periodLabel,
  handleUpdateTarget,
}) => {
  const theme = useTheme()

  if (component == null || target == null) {
    return <></>
  }

  // Use ComponentDisplay for range display
  const rangeDisplay =
    component.targetMin != null && component.targetMax != null ? (
      <>
        <ComponentDisplay
          componentType={component.componentType}
          value={component.targetMin}
          currency="USD"
          currencyDecimals={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
          decimalPlaces={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
          fontSize="small"
        />
        {' - '}
        <ComponentDisplay
          componentType={component.componentType}
          value={component.targetMax}
          currency="USD"
          currencyDecimals={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
          decimalPlaces={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
          fontSize="small"
        />
      </>
    ) : (
      ''
    )

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid size={{ xs: 12, sm: 4 }}>
        <Typography variant="h6">{component.label}</Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Typography align={reviewOnly ? 'center' : 'left'}>{periodLabel ?? ''}</Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        {component.isOrganizationLevel || reviewOnly ? (
          <Typography align={reviewOnly ? 'center' : 'left'}>
            <ComponentDisplay
              componentType={component.componentType}
              value={target.value}
              currency="USD"
              currencyDecimals={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
              decimalPlaces={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
              typographyComponent="div"
              fontWeight="normal"
              color="inherit"
            />
          </Typography>
        ) : (
          <div>
            <ComponentField
              componentType={component.componentType}
              label={`Enter value for ${component.label}`}
              value={target.value}
              onChange={(value) => handleUpdateTarget(value, `${componentId}_${target.periodId}`)}
              name={`${componentId}_${target.periodId}`}
              currency="USD"
              currencyDecimals={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
              decimalPlaces={component.componentType === ComponentType.COMPONENT_TYPE_FLOAT ? 2 : 0}
              disabled={false}
              placeholder=""
              shrink
              pill
            />
            {rangeDisplay && (
              <Typography
                variant="caption"
                display="block"
                style={{ color: theme.palette.text.secondary, fontSize: '12px' }}
              >
                Range: {rangeDisplay}
              </Typography>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  )
}

interface PlanTargetsProps {
  reviewOnly?: boolean
}

const PlanTargets: React.FC<PlanTargetsProps> = ({ reviewOnly = false }) => {
  const { compensationTemplate, plan, targetDTOs, setTargetDTOs } = useCreatePlanContext()

  if (compensationTemplate == null) {
    return <></>
  }

  const componentsMap =
    compensationTemplate != null && compensationTemplate.components != null
      ? compensationTemplate.components.reduce((acc: Record<string, Component>, component) => {
          if (component != null && component.id != null) {
            acc[component.id] = component
          }
          return acc
        }, {})
      : {}

  // Updated to use a composite key of componentId and periodId
  const targetsComponentIdMap = targetDTOs.reduce((acc: Record<string, TargetDTO>, target) => {
    const key = `${target.componentId}_${target.periodId}`
    acc[key] = target
    return acc
  }, {})

  const activeTargetComponentIds = plan.variableAllocations.reduce((acc: Set<string>, table) => {
    const activeTargetsForTable = table.rows.reduce((rowAcc: Set<string>, row) => {
      if (!(row.disabled ?? false)) {
        ;(row.componentIds ?? []).forEach((id) => {
          const periodId = row.periodId // Assuming periodId is available in row
          const compositeKey = `${id}_${periodId}`
          rowAcc.add(compositeKey)
        })
      }
      return rowAcc
    }, new Set<string>())

    activeTargetsForTable.forEach((id) => acc.add(id))
    return acc
  }, new Set<string>())
  // Convert Set back to array if needed elsewhere as array
  const uniqueActiveTargetComponentIds = Array.from(activeTargetComponentIds)

  const periodIdLabelMap = createIdLabelMap(compensationTemplate.period)

  const handleUpdateTarget = (value: string | number, compositeId: string) => {
    const [componentId, periodId] = compositeId.split('_')
    const component = componentsMap[componentId]

    const unformattedValue = unformatValue(value.toString(), component.componentType)

    const newTargets = targetDTOs.map((t) =>
      t.componentId === componentId && t.periodId === periodId ? { ...t, value: unformattedValue } : t
    )
    setTargetDTOs(newTargets)
  }

  return (
    <div style={{ width: '100%', marginLeft: reviewOnly ? '16px' : '0px' }}>
      <Paper sx={{ marginTop: 3, paddingTop: 1, width: '100%' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid size={4}>
              <Typography variant="h3">Target</Typography>
            </Grid>
            <Grid size={4}>
              <Typography variant="h5" align={reviewOnly ? 'center' : 'left'}>
                Period
              </Typography>
            </Grid>
            <Grid size={4}>
              <Typography variant="h5" align={reviewOnly ? 'center' : 'left'}>
                Value
              </Typography>
            </Grid>
          </Grid>
          {uniqueActiveTargetComponentIds.map((compositeId, index) => {
            const [componentId, periodId] = compositeId.split('_')
            const component = componentsMap[componentId]
            const target = targetsComponentIdMap[compositeId]
            const periodLabel = periodIdLabelMap[periodId]

            if (component === undefined) {
              return <></>
            }

            return (
              <Fragment key={compositeId}>
                {index > 0 && (
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Divider />
                  </div>
                )}

                <PlanTargetRow
                  componentId={componentId}
                  component={component}
                  target={target}
                  reviewOnly={reviewOnly}
                  periodLabel={periodLabel}
                  handleUpdateTarget={handleUpdateTarget}
                />
              </Fragment>
            )
          })}
        </CardContent>
      </Paper>
    </div>
  )
}

export default PlanTargets
