import { useParams } from 'react-router-dom'

import CompPlansTable from './CompPlansTable'

// ==============================|| SAMPLE PAGE ||============================== //

function CompPlans() {
  const { userId } = useParams()

  return <CompPlansTable userId={userId!} />
}

export default CompPlans
