import type { Theme } from '@mui/material'
import type { Organization, OrganizationContactInfo } from 'types/organization'
import {
  Alert,
  Button,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import { TextField } from 'components/@extended/TextField'
import useAuth from 'hooks/useAuth'
import { useOrganizationContactInfo } from 'hooks/useOrganizations'
import React, { useEffect, useState } from 'react'

interface OrganizationContactSectionProps {
  organization: Organization
  contactInfo?: OrganizationContactInfo
  onContactInfoChange: (contactInfo: Partial<OrganizationContactInfo>) => void
  theme: Theme
}

const US_STATES = [
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
] as const

const OrganizationContactSection: React.FC<OrganizationContactSectionProps> = ({
  organization,
  contactInfo: propContactInfo,
  onContactInfoChange,
  theme,
}) => {
  const { token } = useAuth()
  const {
    saveContactInfo,
    isLoading,
    contactInfo: fetchedContactInfo,
  } = useOrganizationContactInfo(organization.id, token!)

  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [streetAddress2, setStreetAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')

  const [snackbar, setSnackbar] = useState<{
    open: boolean
    message: string
    severity: 'success' | 'error'
  }>({
    open: false,
    message: '',
    severity: 'success',
  })

  useEffect(() => {
    const infoToUse = fetchedContactInfo || propContactInfo

    if (infoToUse && infoToUse.organizationId === organization.id) {
      setContactName(infoToUse.contactName || '')
      setContactEmail(infoToUse.contactEmail || '')
      setContactPhone(infoToUse.contactPhone || '')
      setStreetAddress(infoToUse.streetAddress || '')
      setStreetAddress2(infoToUse.streetAddress2 || '')
      setCity(infoToUse.city || '')
      setState(infoToUse.state || '')
      setZipCode(infoToUse.zipCode || '')
    }
  }, [fetchedContactInfo, propContactInfo])

  // Only show for root organizations
  const isRootOrg = organization.id === organization.rootOrganizationId
  if (!isRootOrg) return null

  const handleChange = (field: keyof OrganizationContactInfo, value: string) => {
    const updatedInfo: Partial<OrganizationContactInfo> = {
      contactName,
      contactEmail,
      contactPhone,
      streetAddress,
      streetAddress2,
      city,
      state,
      zipCode,
    }

    // Update the corresponding state based on the field
    switch (field) {
      case 'contactName':
        setContactName(value)
        break
      case 'contactEmail':
        setContactEmail(value)
        break
      case 'contactPhone':
        setContactPhone(value)
        break
      case 'streetAddress':
        setStreetAddress(value)
        break
      case 'streetAddress2':
        setStreetAddress2(value)
        break
      case 'city':
        setCity(value)
        break
      case 'state':
        setState(value)
        break
      case 'zipCode':
        setZipCode(value)
        break
    }

    updatedInfo[field] = value
    onContactInfoChange(updatedInfo)
  }

  const handleSave = () => {
    saveContactInfo({
      organizationId: organization.id,
      contactName: contactName || '',
      contactEmail: contactEmail || '',
      contactPhone: contactPhone || '',
      streetAddress: streetAddress || '',
      streetAddress2: streetAddress2 || '',
      city: city || '',
      state: state || '',
      zipCode: zipCode || '',
      country: 'US',
    })
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Contact information saved successfully',
          severity: 'success',
        })
      })
      .catch((error) => {
        console.error('Failed to save contact info:', error)
        setSnackbar({
          open: true,
          message: 'Failed to save contact information',
          severity: 'error',
        })
      })
  }

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }))
  }

  return (
    <>
      <Paper elevation={0} sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom mb={2}>
          Contact Information
        </Typography>
        <Grid container spacing={3} p={2}>
          <Grid size={{ xs: 12, sm: 4 }}>
            <TextField
              key="contactName"
              variant="outlined"
              pill
              shrink
              fullWidth
              label="Contact Name"
              value={contactName}
              onChange={(e) => handleChange('contactName', e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <TextField
              key="contactEmail"
              variant="outlined"
              pill
              shrink
              fullWidth
              label="Contact Email"
              type="email"
              value={contactEmail}
              onChange={(e) => handleChange('contactEmail', e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <TextField
              key="contactPhone"
              variant="outlined"
              pill
              shrink
              fullWidth
              label="Contact Phone"
              value={contactPhone}
              onChange={(e) => handleChange('contactPhone', e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              key="streetAddress"
              variant="outlined"
              pill
              shrink
              fullWidth
              label="Street Address"
              value={streetAddress}
              onChange={(e) => handleChange('streetAddress', e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              key="streetAddress2"
              variant="outlined"
              pill
              shrink
              fullWidth
              label="Street Address Line 2"
              value={streetAddress2}
              onChange={(e) => handleChange('streetAddress2', e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              key="city"
              variant="outlined"
              pill
              shrink
              fullWidth
              label="City"
              value={city}
              onChange={(e) => handleChange('city', e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                shrink
                variant="outlined"
                sx={{
                  '&.MuiInputLabel-shrink': {
                    background: theme.palette.background.paper,
                  },
                }}
              >
                State
              </InputLabel>
              <Select
                key="state"
                value={state}
                onChange={(e) => handleChange('state', e.target.value)}
                label="State"
                style={{ borderRadius: '21px' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {US_STATES.map(([code, name]) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              key="zipCode"
              variant="outlined"
              pill
              shrink
              fullWidth
              label="ZIP Code"
              value={zipCode}
              onChange={(e) => handleChange('zipCode', e.target.value)}
            />
          </Grid>
          <Grid size={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" onClick={handleSave} disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save Contact Info'}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default OrganizationContactSection
