import type { FC } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import React from 'react'

interface SuccessStepProps {
  isSuccess: boolean
  isProcessing: boolean
}

const SuccessContent: React.FC = () => {
  return (
    <>
      <Typography variant="h4" align="center">
        Setup Completed Successfully!
      </Typography>
      <Typography variant="body1" align="center">
        Congratulations! You have successfully completed the Purcent Setup Wizard. Your organization is now set up and
        ready to go.
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1" align="center" color="text.secondary">
          You can now start using the platform to manage your team's variable compensation.
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1" align="center">
          The next step is to select a template for creating your first compensation plan for a team member.
        </Typography>
      </Box>
    </>
  )
}

const ErrorContent: React.FC = () => {
  return (
    <>
      <Typography variant="h4" align="center">
        Setup Failed
      </Typography>
      <Typography variant="body1" align="center">
        There was an error during the setup process. Please contact support for assistance.
      </Typography>
    </>
  )
}

const LoadingSpinner: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
)

const SuccessStep: React.FC<SuccessStepProps> = ({ isSuccess, isProcessing }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4,
        gap: 3,
      }}
    >
      {isProcessing ? <LoadingSpinner /> : isSuccess ? <SuccessContent /> : <ErrorContent />}
    </Paper>
  )
}

export default SuccessStep
