import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'

interface WarningDialogProps {
  open: boolean
  onClose: () => void
  displayMessage: string
}

const WarningDialog: React.FC<WarningDialogProps> = ({ open, onClose, displayMessage }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="warning-dialog-title"
      aria-describedby="warning-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="warning-dialog-description">{displayMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WarningDialog
