import type { PeriodLevels } from 'services/periodServices'
import type { Period } from 'types/periods'
import type { PeriodConfiguration, WizardConfiguration } from 'types/wizard-config'
import { Box, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import PeriodsTable from 'components/PeriodsTable'
import React, { useEffect, useState } from 'react'
import { generatePeriods, getCurrentRootPeriod } from 'services/periodServices'

interface PeriodStepProps {
  periodConfiguration: PeriodConfiguration
  selectedConfiguration: WizardConfiguration | null
  setSelectedConfiguration: (configuration: WizardConfiguration) => void
  existingPeriods: Period[]
}

const PeriodStep: React.FC<PeriodStepProps> = ({
  periodConfiguration,
  selectedConfiguration,
  setSelectedConfiguration,
  existingPeriods,
}) => {
  const [periods, setPeriods] = useState<Period[]>([])
  const [rootPeriod, setRootPeriod] = useState<Period | null>(null)

  useEffect(() => {
    const generateInitialPeriods = () => {
      const rootOrgId = periodConfiguration.rootOrganizationId
      const startDate = new Date(periodConfiguration.startDate)
      const generatedPeriods = generatePeriods(
        rootOrgId,
        startDate,
        periodConfiguration.depth as PeriodLevels,
        periodConfiguration.length
      )

      setPeriods(generatedPeriods)
      if (selectedConfiguration) {
        setSelectedConfiguration({ ...selectedConfiguration, periods: generatedPeriods })
      }
    }

    generateInitialPeriods()
  }, [periodConfiguration])

  useEffect(() => {
    if (selectedConfiguration) {
      setSelectedConfiguration({ ...selectedConfiguration, periods })
    }
  }, [periods])

  useEffect(() => {
    if (existingPeriods && existingPeriods.length > 0) {
      setRootPeriod(getCurrentRootPeriod(existingPeriods))
    } else {
      setRootPeriod(getCurrentRootPeriod(periods))
    }
  }, [existingPeriods, periods])

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        gap: 2,
      }}
    >
      <Box
        sx={{
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!existingPeriods ? (
          <>
            <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
              <Typography variant="h5">Generate Periods</Typography>
              <Typography variant="h5">
                Current Active Period:
                {rootPeriod?.label}
              </Typography>
            </Box>

            <PeriodsTable periods={periods || []} />
          </>
        ) : (
          <>
            <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
              <Typography variant="h5">Existing Periods</Typography>
              <Typography variant="h5">
                Current Active Period:
                {rootPeriod?.label}
              </Typography>
            </Box>
            <PeriodsTable periods={existingPeriods || []} />
          </>
        )}
      </Box>
    </Paper>
  )
}

export default PeriodStep
