import { Box } from '@mui/material'
// material-ui
import { styled } from '@mui/material/styles'

// ==============================|| DRAWER HEADER - STYLED ||============================== //

interface Props {
  open: boolean
}

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<Props>(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0),
}))

export default DrawerHeaderStyled
