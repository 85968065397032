import type { Role } from 'types/role'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { fetchWithTokenWithStatus } from 'hooks/http'
import useSWR from 'swr'

export function useRoles(token: string, forceFresh: boolean = false) {
  const { purcentAppState } = useGlobalState()
  const params: [string, string][] = []
  if (purcentAppState?.selectedOrganization?.rootOrganizationId) {
    params.push(['rootOrganizationId', purcentAppState?.selectedOrganization?.rootOrganizationId])
  }

  const { data, error, isLoading } = useSWR(
    [`roles`, token, params],
    ([url, token, params]) => fetchWithTokenWithStatus(url, token, params),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      dedupingInterval: forceFresh ? 0 : 24 * 60 * 60 * 1000, // 0 if forceFresh, else 24 hours
      revalidateOnMount: forceFresh,
    }
  )

  return {
    roles: (data?.data as Role[]) || [],
    isLoading,
    isError: error,
  }
}
