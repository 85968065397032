// material-ui
import { Grid2 as Grid, Paper, Stack, Typography } from '@mui/material'

// project import

// ================================|| RESET PASSWORD ||================================ //

function PendingSetup() {
  return (
    <Grid container spacing={3} sx={{ minHeight: '100vh' }} alignItems="center">
      <Grid size={{ xs: 4 }}></Grid>
      <Grid size={{ xs: 4 }}>
        <Paper
          variant="outlined"
          elevation={0}
          sx={{ padding: 1, borderRadius: 2, mx: 'auto', p: 3, textAlign: 'center' }}
        >
          <Stack sx={{ mb: { xs: -0.5, sm: 0.5 } }} spacing={1}>
            <img src="PurcentLogo.svg" alt="logo" />
            <Typography variant="h3">Setup In Progress</Typography>
            <Typography>Please contact your account owner for status</Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid size={{ xs: 4 }}></Grid>
    </Grid>
  )
}

export default PendingSetup
