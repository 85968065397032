import type { SelectChangeEvent } from '@mui/material'
import type { Period } from 'types/periods'
import type { PeriodConfiguration, WizardConfiguration } from 'types/wizard-config'
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Paper from 'components/@extended/Paper'
import dayjs from 'dayjs'
import React from 'react'

interface GeneralStepProps {
  wizardConfiguration: WizardConfiguration[]
  selectedBusinessCategory: WizardConfiguration | null
  setBusinessCategory: (category: WizardConfiguration | null) => void
  periodConfiguration: PeriodConfiguration
  setPeriodConfiguration: (configuration: PeriodConfiguration) => void
  existingPeriods: Period[]
}

const GeneralStep: React.FC<GeneralStepProps> = ({
  wizardConfiguration,
  selectedBusinessCategory,
  setBusinessCategory,
  periodConfiguration,
  setPeriodConfiguration,
  existingPeriods,
}) => {
  const handleCategorySelectionChange = (event: SelectChangeEvent) => {
    const selectedCategory = wizardConfiguration.find((category) => category.id === event.target.value)
    setBusinessCategory(selectedCategory ?? null)
  }

  const handlePeriodTypeChange = (event: SelectChangeEvent) => {
    setPeriodConfiguration({ ...periodConfiguration, type: event.target.value as 'CALENDAR' | 'FISCAL' })
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography sx={{ pt: 2, textDecoration: 'underline' }} variant="h5">
          General Configuration
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl variant="standard" sx={{ minWidth: 200, maxWidth: 250 }}>
            <InputLabel>Select Type of Business</InputLabel>
            <Select value={selectedBusinessCategory?.id} onChange={(event) => handleCategorySelectionChange(event)}>
              {wizardConfiguration.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!existingPeriods && (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <FormControl variant="standard" fullWidth sx={{ minWidth: 200, maxWidth: 250 }}>
                <InputLabel>Financial Year Type</InputLabel>
                <Select
                  value={periodConfiguration.type}
                  label="Financial Year Type"
                  onChange={(event) => handlePeriodTypeChange(event)}
                >
                  <MenuItem value="CALENDAR" selected>
                    Calendar Year
                  </MenuItem>
                  <MenuItem value="FISCAL">Custom Fiscal Year</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="standard" fullWidth>
                {periodConfiguration.type === 'CALENDAR' ? (
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Select Starting Year"
                        views={['year']}
                        value={dayjs(periodConfiguration.startDate)}
                        onChange={(date) =>
                          setPeriodConfiguration({
                            ...periodConfiguration,
                            startDate: date ? date.startOf('year').format('DD-MM-YYYY') : '',
                          })
                        }
                        slotProps={{
                          textField: {
                            variant: 'standard',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                ) : (
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Select Starting Year"
                        views={['year', 'month']}
                        value={dayjs(periodConfiguration.startDate)}
                        onChange={(date) =>
                          setPeriodConfiguration({
                            ...periodConfiguration,
                            startDate: date ? date.startOf('month').format('DD-MM-YYYY') : '',
                          })
                        }
                        slotProps={{
                          textField: {
                            variant: 'standard',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                )}
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Generate How Many Years?</InputLabel>
                <Select
                  value={periodConfiguration.length}
                  label="Length"
                  onChange={(e) =>
                    setPeriodConfiguration({
                      ...periodConfiguration,
                      length: Number.parseInt(e.target.value as string),
                    })
                  }
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Period Breakdown</InputLabel>
                <Select
                  value={periodConfiguration.depth}
                  label="Depth"
                  onChange={(e) =>
                    setPeriodConfiguration({ ...periodConfiguration, depth: e.target.value as 'MONTH' | 'QUARTER' })
                  }
                >
                  <MenuItem value="MONTH" selected>
                    Month
                  </MenuItem>
                  <MenuItem value="QUARTER">Quarter</MenuItem>
                  <MenuItem value="BIANNUAL">Bi-Annual</MenuItem>
                  <MenuItem value="ANNUAL">Annual</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default GeneralStep
