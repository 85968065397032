import type { OrganizationStatusResponse } from 'types/organization'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { fetchWithToken } from './http'

export function useOrganizationStatus(token: string, organizationId: string | null, shouldFetch: boolean = true) {
  const [isLoading, setIsLoading] = useState(false)
  const shouldFetchData = token != null && token.trim() !== '' && shouldFetch

  // Determine is the org id should be in the path or not. It is only in the path if the org id is not null
  const apiPath = organizationId ? `organizations/${organizationId}/status` : `organizations/status`

  const { data, error, mutate } = useSWR(
    shouldFetchData ? [apiPath, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    if (shouldFetchData) {
      setIsLoading(true)
      mutate().finally(() => {
        setIsLoading(false)
      })
    }
  }, [shouldFetchData, mutate])

  const handleRefetch = () => {
    setIsLoading(true)
    mutate().finally(() => {
      setIsLoading(false)
    })
  }

  return {
    organizationStatus: data as OrganizationStatusResponse,
    isLoadingOrganizationStatus: isLoading,
    isError: error,
    handleRefetch,
  }
}
