import type { FC } from 'react'
// Types
import type { CreateTargetDTO } from 'types/components'
import type { OrganizationCoreSetting } from 'types/organization'

import type { Period } from 'types/periods'
// Icons
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
// MUI Components
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'

// Custom Components & Hooks
import AnimateButton from 'components/@extended/AnimateButton'
import Paper from 'components/@extended/Paper'
import ComponentField from 'components/ComponentField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import TrueFalseSelector from 'components/TrueFalseSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useGetRootOrganizationComponentLabelsWithStatus } from 'hooks/useComponents'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'

import { usePeriods } from 'hooks/usePeriods'
import { useSnackbar } from 'notistack'
import { startTransition, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ComponentType, ProrationMode } from 'types/components'
import { ThemeMode } from 'types/config'
import { createIdLabelMap, createPeriodToRootPeriodMap } from 'types/periods'
import { getOrganizationLabelMap } from 'types/user-profile'

interface TargetMeta {
  targetName: string
  orgLevel: boolean
  targetMin: number | null
  targetMax: number | null
  targetDefault: number | boolean | null
  targetFormat: ComponentType
  isExpanded: boolean
  restrictedVisibility: boolean
  prorationMode: ProrationMode
}

const DEFAULT_TARGET_META: TargetMeta = {
  targetName: '',
  orgLevel: true,
  targetMin: null,
  targetMax: null,
  targetDefault: null,
  targetFormat: ComponentType.COMPONENT_TYPE_INTEGER,
  isExpanded: false,
  restrictedVisibility: false,
  prorationMode: ProrationMode.PRORATION_MODE_NONE,
}

const CreateTargets: FC = () => {
  const theme = useTheme()
  const hoverBackgroundColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.secondary.dark

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const { purcentAppState } = useGlobalState()

  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [targetMetas, setTargetMetas] = useState<TargetMeta[]>([{ ...DEFAULT_TARGET_META }])

  const [isReviewMode, setIsReviewMode] = useState(false)
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const [isReviewClicked, setIsReviewClicked] = useState(false)
  const [reviewTargets, setReviewTargets] = useState<CreateTargetDTO[]>([])
  const [availableLabels, setAvailableLabels] = useState<string[]>(['Revenue', 'Cost', 'Profit'])

  const { periods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || ''
  )
  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganizations,
    token!
  )
  const { labels, isLoading: isLoadingLabels } = useGetRootOrganizationComponentLabelsWithStatus(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || '',
    true
  )

  const [coreSettingsMap, setCoreSettingsMap] = useState<Record<string, OrganizationCoreSetting>>({})

  const organizationLabelMap = useMemo(() => (profile ? getOrganizationLabelMap(profile) : {}), [profile])

  const periodLabelMap = useMemo(() => {
    if (!periods?.periods) return {}
    return periods.periods.reduce((acc: Record<string, string>, period) => {
      const idLabelMap = createIdLabelMap(period)
      Object.entries(idLabelMap).forEach(([id, label]) => {
        const newLabel = label.includes(period.label) ? label : `${label} (${period.label})`
        acc[id] = newLabel
      })
      return acc
    }, {})
  }, [periods])

  const rootPeriodMap = useMemo(() => {
    if (isLoadingPeriods || !periods?.periods) return {}
    return createPeriodToRootPeriodMap(periods.periods)
  }, [periods])

  // Update available labels if `labels` prop changes
  useEffect(() => {
    if (!isLoadingLabels) {
      setAvailableLabels(labels)
    }
  }, [labels])

  // Build core settings map
  useEffect(() => {
    if (coreSettings && coreSettings.length > 0) {
      const newCoreSettingsMap = coreSettings.reduce(
        (acc, setting) => {
          acc[setting.organizationId] = setting
          return acc
        },
        {} as Record<string, OrganizationCoreSetting>
      )
      setCoreSettingsMap(newCoreSettingsMap)
    }
  }, [coreSettings])

  // Reset review states when the browser back button is pressed
  useEffect(() => {
    const handlePopState = () => {
      console.log('log 4')
      setIsReviewClicked(false)
      setIsReviewLoading(false)
    }

    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [])

  const handleMetricChange = (index: number, field: keyof TargetMeta, value: any) => {
    const newMetas = [...targetMetas]
    newMetas[index] = { ...newMetas[index], [field]: value }
    setTargetMetas(newMetas)
  }

  const handleProrationModeChange = (index: number, value: ProrationMode) => {
    handleMetricChange(index, 'prorationMode', value)
  }

  const addMetric = () => {
    setTargetMetas((prev) => [...prev, { ...DEFAULT_TARGET_META }])
  }

  const removeMetric = (index: number) => {
    setTargetMetas((prev) => prev.filter((_, i) => i !== index))
  }

  const handleOrganizationChange = useCallback((orgs: string[]) => {
    startTransition(() => setSelectedOrganizations(orgs))
  }, [])

  const handlePeriodChange = useCallback((periods: string[]) => {
    startTransition(() => setSelectedPeriods(periods))
  }, [])

  const handleExpand = (index: number) => {
    handleMetricChange(index, 'isExpanded', !targetMetas[index].isExpanded)
  }

  const isTargetMetasValid = useCallback(() => {
    return targetMetas.every((targetMeta) => targetMeta.targetName !== '' && targetMeta.targetDefault !== null)
  }, [targetMetas])

  const getSelectedRootPeriods = useCallback(
    (periodIds: string[]): Period[] => {
      if (!periods?.periods) return []

      const findPeriodById = (period: Period, id: string): Period | null => {
        if (period.id === id) return period
        return period.children?.map((child) => findPeriodById(child, id)).find((res) => res !== null) || null
      }

      // Avoid selecting periods that are descendants of another selected period
      const isChildOf = (parent: Period, childId: string): boolean => {
        if (!parent.children) return false
        return parent.children.some((child) => child.id === childId || isChildOf(child, childId))
      }

      const selectedRoots: Period[] = []
      for (const id of periodIds) {
        for (const rootPeriod of periods.periods) {
          const found = findPeriodById(rootPeriod, id)
          if (found) {
            // Ensure no duplicates or child-of conflicts
            const isChildOfAnotherSelected = selectedRoots.some((selected) => isChildOf(selected, found.id!))
            if (!isChildOfAnotherSelected) {
              selectedRoots.push(found)
            }
            break
          }
        }
      }
      return selectedRoots
    },
    [periods]
  )

  const generateTargetsForOrganization = useCallback(
    (rootOrgId: string, orgId: string, rootPeriod: Period, span: number, selectedPeriodsList: string[]) => {
      const generatedTargets: CreateTargetDTO[] = []

      const processPeriods = (period: Period, currentSpan: number) => {
        targetMetas.forEach((target) => {
          let calculatedPeriodValue = 0
          if (target.targetFormat !== ComponentType.COMPONENT_TYPE_BOOL) {
            calculatedPeriodValue = Number.parseFloat(target.targetDefault?.toString() ?? '0')
          }

          if (
            target.prorationMode === ProrationMode.PRORATION_MODE_AMORTIZE ||
            target.prorationMode === ProrationMode.PRORATION_MODE_CUMULATIVE
          ) {
            calculatedPeriodValue /= currentSpan
          }

          const rootPeriodId = rootPeriodMap[period.id!]
          const newTarget: CreateTargetDTO = {
            rootOrganizationId: rootOrgId,
            organizationId: orgId,
            rootPeriodId,
            periodId: period.id!,
            label: target.targetName,
            isOrganizationLevel: target.orgLevel,
            isRestricted: target.restrictedVisibility,
            componentType: target.targetFormat,
            targetMin: target.targetMin?.toString() ?? '',
            targetMax: target.targetMax?.toString() ?? '',
            value:
              target.targetFormat !== ComponentType.COMPONENT_TYPE_BOOL
                ? calculatedPeriodValue.toString()
                : (target.targetDefault?.toString() ?? ''),
          }

          if (selectedPeriodsList.includes(period.id!)) {
            generatedTargets.push(newTarget)
          }
        })

        period.children?.forEach((child) => {
          processPeriods(child, currentSpan * (period.children?.length || 1))
        })
      }

      processPeriods(rootPeriod, span)
      return generatedTargets
    },
    [rootPeriodMap, targetMetas]
  )

  const createReviewTargets = useCallback(() => {
    if (!purcentAppState?.selectedOrganization?.rootOrganizationId) return
    const rootOrgId = purcentAppState.selectedOrganization.rootOrganizationId
    const _periods = getSelectedRootPeriods(selectedPeriods)
    let combinedTargets: CreateTargetDTO[] = []

    selectedOrganizations.forEach((orgId) => {
      _periods.forEach((_period) => {
        const newTargets = generateTargetsForOrganization(rootOrgId, orgId, _period, _periods.length, selectedPeriods)
        combinedTargets = [...combinedTargets, ...newTargets]
      })
    })

    setReviewTargets(combinedTargets)
    setIsReviewMode(true)
    setIsReviewLoading(false)
  }, [getSelectedRootPeriods, generateTargetsForOrganization, purcentAppState, selectedOrganizations, selectedPeriods])

  const handleReview = () => {
    setIsReviewClicked(true)
    setIsReviewLoading(true)
    // Delay execution to ensure UI updates
    setTimeout(() => {
      createReviewTargets()
    }, 0)
  }

  const handleTargetChange = (index: number, value: string) => {
    const updated = [...reviewTargets]
    updated[index].value = value
    setReviewTargets(updated)
  }

  const handleCreateTargets = () => {
    postWithToken('components/targets', token!, { targets: reviewTargets })
      .then(() => {
        enqueueSnackbar('Targets created successfully', { variant: 'success' })
        // Reset form
        setIsReviewMode(false)
        setSelectedOrganizations([])
        setSelectedPeriods([])
        setTargetMetas([{ ...DEFAULT_TARGET_META }])
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to create targets'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
      .finally(() => {
        startTransition(() => navigate('/admin/components/targets'))
      })
  }

  const handleBackFromReview = () => {
    setIsReviewMode(false)
    setIsReviewClicked(false)
  }

  const renderInputField = (index: number, field: keyof TargetMeta, label: string) => {
    const targetMeta = targetMetas[index]
    const value = targetMeta[field] ?? ''
    const orgSetting = coreSettingsMap[selectedOrganizations[0]] ?? { currency: 'USD', currencyDecimals: 0 }

    return (
      <ComponentField
        id={`target-${index}-${String(field)}`}
        componentType={targetMeta.targetFormat}
        label={label}
        value={value}
        onChange={(newVal) => handleMetricChange(index, field, newVal)}
        name={`target-${index}-${String(field)}`}
        currency={orgSetting.currency}
        currencyDecimals={orgSetting.currencyDecimals}
        decimalPlaces={2}
        variant="standard"
        shrink
      />
    )
  }

  const renderOrgPeriodConfig = () => (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h4" gutterBottom>
        Select Teams & Periods for Targets
      </Typography>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          {isLoadingProfile ? (
            <CircularProgress />
          ) : (
            <MultiOrganizationSelector
              userProfile={profile!}
              selectedOrganizationIds={selectedOrganizations}
              handleChange={handleOrganizationChange}
              label="Select Teams"
              labelBackgroundColor={theme.palette.background.paper}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          {isLoadingPeriods ? (
            <CircularProgress />
          ) : (
            <MultiPeriodSelector
              periods={periods?.periods || []}
              selectedPeriods={selectedPeriods}
              handleChange={handlePeriodChange}
              label="Select Periods"
              disableMultiRootSelection
              labelBackgroundColor={theme.palette.background.paper}
              disableClosed
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )

  const renderTargetMetaConfig = () => (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h4" gutterBottom>
        Targets
      </Typography>
      <Grid container spacing={3}>
        {targetMetas.map((targetMeta, index) => (
          <Grid container size={{ xs: 12 }} spacing={2} key={`target-meta-${index}`}>
            <Grid size={{ xs: 12, sm: 1 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IconButton onClick={() => handleExpand(index)}>
                {targetMeta.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              {isLoadingLabels ? (
                <CircularProgress />
              ) : (
                <Autocomplete
                  freeSolo
                  options={availableLabels}
                  value={targetMeta.targetName}
                  onInputChange={(_, newValue) => handleMetricChange(index, 'targetName', newValue || '')}
                  renderInput={(params) => <TextField {...params} label="Name" variant="standard" fullWidth />}
                />
              )}
            </Grid>
            <Grid size={{ xs: 12, sm: 1 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={String(targetMeta.orgLevel)}
                  onChange={(e) => handleMetricChange(index, 'orgLevel', e.target.value === 'true')}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: hoverBackgroundColor,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="true">Team</MenuItem>
                  <MenuItem value="false">Individual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Format</InputLabel>
                <Select
                  value={targetMeta.targetFormat}
                  onChange={(e) => handleMetricChange(index, 'targetFormat', e.target.value as ComponentType)}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: hoverBackgroundColor,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={ComponentType.COMPONENT_TYPE_INTEGER}>Integer</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_CURRENCY}>Currency</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_PERCENTAGE}>Percentage</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_BOOL}>Boolean</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_FLOAT}>Float</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Proration Mode</InputLabel>
                <Select
                  key={`proration-mode-${index}`}
                  value={
                    targetMeta.targetFormat === ComponentType.COMPONENT_TYPE_BOOL
                      ? ProrationMode.PRORATION_MODE_NONE
                      : targetMeta.prorationMode
                  }
                  variant="standard"
                  onChange={(e) => handleProrationModeChange(index, e.target.value as ProrationMode)}
                  fullWidth
                  disabled={targetMeta.targetFormat === ComponentType.COMPONENT_TYPE_BOOL}
                >
                  <MenuItem value={ProrationMode.PRORATION_MODE_NONE}>None</MenuItem>
                  <MenuItem value={ProrationMode.PRORATION_MODE_AMORTIZE}>Amortize</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>{renderInputField(index, 'targetDefault', 'Value')}</Grid>
            <Grid size={{ xs: 12, sm: 1 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IconButton onClick={() => removeMetric(index)}>
                <DeleteOutlined />
              </IconButton>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Collapse in={targetMeta.isExpanded}>
                <Paper elevation={0} sx={{ p: 2, backgroundColor: theme.palette.grey[100] }}>
                  <Typography variant="h6" gutterBottom>
                    Advanced Settings (when creating new components)
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 4 }}>
                      <TrueFalseSelector
                        name={`restricted-visibility-${index}`}
                        label="Restricted Visibility"
                        value={targetMeta.restrictedVisibility}
                        onChange={(value) => handleMetricChange(index, 'restrictedVisibility', value)}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>{renderInputField(index, 'targetMin', 'Target Min')}</Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>{renderInputField(index, 'targetMax', 'Target Max')}</Grid>
                  </Grid>
                </Paper>
              </Collapse>
            </Grid>
          </Grid>
        ))}
        <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <IconButton color="primary" onClick={addMetric} sx={{ width: '250px' }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <PlusOutlined style={{ marginRight: 8 }} />
              {targetMetas.length > 0 ? 'Add Another Target' : 'Add Target'}
            </Box>
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )

  const renderReviewConfig = () => {
    if (isLoadingCoreSettings) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      )
    }

    const handleRemoveReviewTarget = (index: number) => {
      setReviewTargets((prev) => prev.filter((_, i) => i !== index))
    }

    return (
      <>
        <Typography variant="h4" gutterBottom>
          Review Targets
        </Typography>
        <Paper sx={{ mb: 3 }}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="review-targets-table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  <TableCell>Organization</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewTargets.map((target, index) => {
                  const orgSettings = coreSettingsMap[target.organizationId] || {
                    currency: 'USD',
                    currencyDecimals: 0,
                    percentageDecimals: 2,
                  }
                  const targetMeta = targetMetas.find((meta) => meta.targetName === target.label)
                  return (
                    <TableRow
                      key={`review-target-${index}`}
                      sx={{ py: 3, '&:hover': { backgroundColor: hoverBackgroundColor } }}
                    >
                      <TableCell>{organizationLabelMap[target.organizationId]}</TableCell>
                      <TableCell>{periodLabelMap[target.periodId]}</TableCell>
                      <TableCell>{target.label}</TableCell>
                      <TableCell>{target.isOrganizationLevel ? 'Individual' : 'Team'}</TableCell>
                      <TableCell>
                        <ComponentField
                          componentType={targetMeta?.targetFormat as ComponentType}
                          label=""
                          value={target.value}
                          onChange={(value) => handleTargetChange(index, value)}
                          name={`review-target-${index}`}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveReviewTarget(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    )
  }

  return (
    <>
      {!isReviewMode && (
        <>
          <Typography variant="h3" gutterBottom>
            Configure Targets
          </Typography>
          {renderOrgPeriodConfig()}
          {renderTargetMetaConfig()}
          <Box sx={{ mb: 3 }}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <AnimateButton>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    startTransition(() => {
                      navigate('/admin/components/targets')
                    })
                  }}
                  disabled={isReviewLoading || isReviewClicked}
                >
                  Cancel
                </Button>
              </AnimateButton>
              <AnimateButton>
                <Button
                  id="review-targets-button"
                  variant="contained"
                  onClick={handleReview}
                  disabled={
                    isLoadingCoreSettings ||
                    isReviewLoading ||
                    isReviewClicked ||
                    selectedOrganizations.length === 0 ||
                    selectedPeriods.length === 0 ||
                    !isTargetMetasValid()
                  }
                >
                  {isReviewLoading ? <CircularProgress size={24} color="inherit" /> : 'Review'}
                </Button>
              </AnimateButton>
            </Stack>
          </Box>
        </>
      )}
      {isReviewMode && (
        <>
          {renderReviewConfig()}
          <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
            <Stack direction="row" spacing={2}>
              <AnimateButton>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    startTransition(() => {
                      navigate('/admin/components/targets')
                    })
                  }}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleBackFromReview}>
                Back
              </Button>
              <Button
                id="create-targets-button"
                variant="contained"
                onClick={handleCreateTargets}
                disabled={isLoadingCoreSettings}
              >
                Create Targets
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </>
  )
}

export default CreateTargets
