import Locales from 'components/Locales'
import ScrollTop from 'components/ScrollTop'

import { Auth0ProviderWrapper } from 'contexts/Auth0ProviderWrapper'
import { AuthProvider } from 'contexts/AuthContext'
import { ConfigProvider } from 'contexts/ConfigContext'

import { GlobalStateProvider } from 'contexts/GlobalStateContext'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import ThemeCustomization from 'themes'

import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// scroll bar
import 'simplebar-react/dist/simplebar.min.css'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
// google-fonts
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'

import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'

import '@fontsource/inter/600.css'

import '@fontsource/inter/700.css'
import '@fontsource/poppins/400.css'

import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'

import '@fontsource/poppins/700.css'
import '@fontsource/public-sans/400.css'
import '@fontsource/public-sans/500.css'

import '@fontsource/public-sans/600.css'
import '@fontsource/public-sans/700.css'

const container = document.getElementById('root')
const root = createRoot(container!)

// Register the service worker
// the prompting for reload should be removed and we should just reload the page when a
// new version is available. this prompt is here for testing purposes. We will see if
// this works in production.
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting && window.confirm('New version available! Do you want to reload?')) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    }
  },
})

root.render(
  <ConfigProvider>
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <BrowserRouter basename={process.env.REACT_APP_BASE_NAME || '/'}>
            <Auth0ProviderWrapper>
              <AuthProvider>
                <GlobalStateProvider>
                  <App />
                </GlobalStateProvider>
              </AuthProvider>
            </Auth0ProviderWrapper>
          </BrowserRouter>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  </ConfigProvider>
)

reportWebVitals()
