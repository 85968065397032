import type { UserProfile } from 'types/user-profile'
import { Grid2 as Grid, Typography } from '@mui/material'

import React from 'react'

interface ProfileSectionProps {
  profile: UserProfile // Define the prop type
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ profile }) => {
  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12 }}>
        <Typography variant="subtitle1">Email:</Typography>
        <Typography variant="body1">{profile.user.email}</Typography>
        <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
          Name:
        </Typography>
        <Typography variant="body1">
          {profile.user.firstName} {profile.user.lastName}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default ProfileSection
