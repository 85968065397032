import type { TextFieldVariants } from '@mui/material'
import { InputAdornment } from '@mui/material'
import { TextField } from 'components/@extended/TextField'
import React from 'react'
import { NumericFormat } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  decimalPlaces: number
  pill?: boolean
  shrink?: boolean
}

const NumericFormatCustom = function NumericFormatCustom({
  ref,
  ...props
}: CustomProps & { ref?: React.RefObject<HTMLInputElement | null> }) {
  const { onChange, decimalPlaces, pill = false, shrink = false, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: string }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      decimalScale={decimalPlaces}
      pill={pill}
      shrink={shrink}
    />
  )
}

interface PercentageFieldProps {
  id?: string
  label?: string
  value: string
  onChange: (value: string) => void
  name: string
  decimalPlaces?: number
  style?: React.CSSProperties // Add the optional style property
  variant?: TextFieldVariants
  pill?: boolean
  shrink?: boolean
  disabled?: boolean
}

const PercentageField: React.FC<PercentageFieldProps> = ({
  id,
  label,
  value,
  onChange,
  name,
  decimalPlaces = 2,
  style, // Destructure the style prop
  variant = 'standard',
  pill = false,
  shrink = false,
}) => {
  const handleChange = (event: { target: { name: string; value: string } }) => {
    // Convert the percentage back to a decimal
    const decimalValue = (Number.parseFloat(event.target.value) / 100).toString()
    onChange(decimalValue)
  }

  // Convert the decimal to a percentage for display
  const displayValue = (Number.parseFloat(value) * 100).toFixed(decimalPlaces)

  return (
    <TextField
      id={id}
      fullWidth
      label={label}
      value={displayValue}
      onChange={handleChange}
      name={name}
      variant={variant}
      pill={pill}
      shrink={shrink}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          decimalScale: decimalPlaces,
          fixedDecimalScale: true,
        },
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      style={style}
    />
  )
}

export default PercentageField
