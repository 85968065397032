import Snackbar from 'components/@extended/Snackbar'
import { ProtectedRoute } from 'components/ProtectedRoute'

// project import

import Notistack from 'components/third-party/Notistack'
import { useGlobalState } from 'contexts/GlobalStateContext'

import useAuth from 'hooks/useAuth'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginRoutes from 'routes/LoginRoutes'
import MainRoutes from 'routes/MainRoutes'

function App() {
  const { profile, isLoading } = useAuth()
  const { onChangeOrganizationSelection } = useGlobalState()

  useEffect(() => {
    const getUser = async () => {
      console.log('profile', profile)
      console.log('isLoading', isLoading)

      if (!isLoading && profile && profile.roles && profile.roles.length > 0) {
        console.log('setting organization', profile.roles[0].organization)
        onChangeOrganizationSelection(profile.roles[0].organization || null)
      }
    }

    getUser()
  }, [profile, isLoading])

  const renderRoutes = (routes: typeof LoginRoutes | typeof MainRoutes) =>
    routes.children.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children?.map((child) => (
          <Route
            key={child.path}
            path={child.path}
            element={child.protected ? <ProtectedRoute>{child.element}</ProtectedRoute> : child.element}
          />
        ))}
      </Route>
    ))

  return (
    <>
      <Notistack>
        <Routes>
          {renderRoutes(LoginRoutes)}
          {renderRoutes(MainRoutes)}
        </Routes>
        <Snackbar />
      </Notistack>
    </>
  )
}

export default App
