import type { ID, Label, PeriodIDs } from './common'
import type { ComponentType } from './components'

export enum RowType {
  DISPLAY_ROW = 'DISPLAY_ROW',
  CALCULATION_ROW = 'CALCULATION_ROW',
  FIXED_VALUE_ROW = 'FIXED_VALUE_ROW',
}

export interface ComponentIDs {
  componentIds?: string[] | null
}

export interface VariableAllocationTable extends ID, Label, ComponentIDs {
  rows: VariableAllocationRow[]
}

export interface VariableAllocationRow extends ID, PeriodIDs, Label, ComponentIDs {
  rowType: RowType
  disabled?: boolean | null
  restrictedVisibility: boolean
  allocationPercentage: number
  minAllocationPercentage?: number | null
  maxAllocationPercentage?: number | null
  defaultPayout: number
  minPayout: number
  maxPayout: number
  formulaRequirements: string[]
  formulaOptions: string[]
  achievementMultiplier: string
  componentType?: ComponentType | null
  componentSlugs?: string[] | null
  componentIds?: string[] | null
  targetSlugs?: string[] | null
  additionalInputs?: { [key: string]: RangedValue }
  outputVariable?: string | null
  updatedTargetValues?: { [key: string]: number } | null
  updatedMinimumAchievement?: number | null
  ledgerAccountId?: string | null
}

export interface RangedValue {
  value: number
  min: number
  max: number
}

export interface VariableCompAllocationTableExtended {
  label: string
  rows: VariableCompAllocationRowExtended[]
}

export interface VariableCompAllocationRowExtended {
  includedInPlan: boolean
}

export function getRowTypeLabel(rowType: RowType) {
  switch (rowType) {
    case RowType.DISPLAY_ROW:
      return 'Display Only'
    case RowType.CALCULATION_ROW:
      return 'Variable'
    case RowType.FIXED_VALUE_ROW:
      return 'Fixed Value'
    default:
      return rowType
  }
}
