import type { Theme } from '@mui/material/styles'
import type { FC } from 'react'
import type { ExpandedTarget } from 'types/components'
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid2 as Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import OperatorLegend from 'components/OperatorLegend'
import { Fragment, useEffect, useState } from 'react'
import { RowType } from 'types/comp-allocations'
import { getTargetCompositeId } from 'types/components'

interface FormulaEditorProps {
  handleFormulaChange: (requirements: string[], options: string[]) => void
  selectedTargets: ExpandedTarget[]
  currentRequirements: string[]
  currentOptions: string[]
  organizationLabels: Record<string, string>
  theme: Theme
  rowType: RowType
  isReviewMode?: boolean
}

/**
 *
 * This component is a WIP and is not yet fully functional.
 *
 * @param param0 T
 * @returns
 */
const FormulaEditor: FC<FormulaEditorProps> = ({
  handleFormulaChange,
  selectedTargets,
  currentRequirements,
  currentOptions,
  organizationLabels,
  theme,
  rowType,
  isReviewMode = false,
}) => {
  const [initialized, setInitialized] = useState(false)
  const [editMode, setEditMode] = useState<number | null>(null)
  const [formulas, setFormulas] = useState(
    currentRequirements.map((requirement, index) => ({ requirement, formula: currentOptions[index] }))
  )
  const [variableMap, setVariableMap] = useState<
    Record<string, { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }>
  >(
    {} as Record<
      string,
      { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }
    >
  )

  useEffect(() => {
    if (selectedTargets.length > 0) {
      initializeFormulasWithVariables()
      mapVariablesToTargets()
      setInitialized(true)
    }
  }, [selectedTargets])

  useEffect(() => {
    if (Object.keys(variableMap).length > 0) {
      initializeFormulasWithVariables()
    }
  }, [variableMap, currentRequirements, currentOptions])

  console.log('formulas', formulas)
  console.log('currentRequirements', currentRequirements)
  console.log('currentOptions', currentOptions)
  console.log('variableMap', variableMap)
  console.log('selectedTargets', selectedTargets)

  const mapVariablesToTargets = () => {
    console.log('mapping variables to targets')
    const varOptions = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ]
    const newVariableMap: Record<
      string,
      { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }
    > = {}
    selectedTargets.forEach((target, index) => {
      const targetId = getTargetCompositeId(target.target)
      newVariableMap[targetId] = {
        targetId,
        targetVar: `Target ${varOptions[index]}`,
        metricVar: `Actual ${varOptions[index]}`,
        targetSlug: target.target.slug,
        metricSlug: target.component.slug,
      }
    })
    setVariableMap(newVariableMap)
  }

  const initializeFormulasWithVariables = () => {
    console.log('initializing formulas with variables')
    const updatedFormulas = currentRequirements.map((requirement, index) => {
      const formula = currentOptions[index]
      return {
        requirement: replaceSlugsWithVariables(requirement),
        formula: replaceSlugsWithVariables(formula),
      }
    })
    setFormulas(updatedFormulas)
  }

  const replaceSlugsWithVariables = (text: string) => {
    Object.keys(variableMap).forEach((key) => {
      const { targetSlug, metricSlug, targetVar, metricVar } = variableMap[key]
      text = text.replace(new RegExp(`\\b${targetSlug}\\b`, 'g'), targetVar)
      text = text.replace(new RegExp(`\\b${metricSlug}\\b`, 'g'), metricVar)
    })
    return text
  }

  const replaceVariablesWithSlugs = (text: string) => {
    Object.keys(variableMap).forEach((key) => {
      const { targetSlug, metricSlug, targetVar, metricVar } = variableMap[key]
      text = text.replace(new RegExp(`\\b${targetVar}\\b`, 'g'), targetSlug)
      text = text.replace(new RegExp(`\\b${metricVar}\\b`, 'g'), metricSlug)
    })
    return text
  }

  const updateFormulasOnRowChange = (newFormulas: { requirement: string; formula: string }[]) => {
    const updatedRequirements = newFormulas.map((formula) => formula.requirement).map(replaceVariablesWithSlugs)
    const updatedOptions = newFormulas.map((formula) => formula.formula).map(replaceVariablesWithSlugs)

    handleFormulaChange(updatedRequirements, updatedOptions)
  }

  const handleAddFormula = () => {
    setFormulas([...formulas, { requirement: '', formula: '' }])
  }

  const handleDeleteFormula = (index: number) => {
    const newFormulas = formulas.filter((_, i) => i !== index)
    setFormulas(newFormulas)
    updateFormulasOnRowChange(newFormulas)
  }

  const handleToggleEditMode = (index: number) => {
    if (isReviewMode) return
    setEditMode(editMode === index ? null : index)
  }

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const variableLegend = document.getElementById('variable-legend')
    if (variableLegend && variableLegend.contains(event.target as Node)) {
      return
    }
    setEditMode(null)
  }

  const handleEditFormula = (index: number, field: 'requirement' | 'formula', value: string) => {
    const newFormulas = [...formulas]
    newFormulas[index][field] = value
    setFormulas(newFormulas)
  }

  const getTargetLabel = (target: ExpandedTarget) => {
    const targetLabel = target.target.label
    const orgLabel = organizationLabels[target.target.organizationId!]
    const targetWithOrgRemoved = targetLabel.replace(orgLabel, '')

    if (orgLabel == undefined) {
      return targetWithOrgRemoved
    }

    return `${orgLabel} ${targetWithOrgRemoved}`
  }

  const variableLegend = () => {
    const handleCopyVariable = (variable: string) => {
      navigator.clipboard
        .writeText(variable)
        .then(() => {
          // Optionally, you can show a success message here
          console.log('Variable copied to clipboard')
        })
        .catch((err) => {
          console.error('Failed to copy variable: ', err)
        })
    }

    return (
      <Accordion defaultExpanded id="variable-legend">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: theme.palette.divider }} // Example of using theme color
        >
          <Typography variant="h5" align="center" color={theme.palette.primary.contrastText}>
            Component Variables Legend
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" m={1}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 6 }}>
                <Typography variant="h5">Component</Typography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Typography variant="h5">Target Variable</Typography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Typography variant="h5">Actual Variable</Typography>
              </Grid>
              {selectedTargets.map((target) => {
                const targetId = getTargetCompositeId(target.target)
                const targetLabel = getTargetLabel(target)
                return (
                  <Fragment key={targetId}>
                    <Grid size={{ xs: 6 }}>
                      <Typography variant="body2">{targetLabel}</Typography>
                    </Grid>
                    <Grid size={{ xs: 3 }} container alignItems="center">
                      <Typography variant="body2" mr={1}>
                        {variableMap[targetId].targetVar}
                      </Typography>
                      <Tooltip title="Copy Target Variable">
                        <IconButton size="small" onClick={() => handleCopyVariable(variableMap[targetId].targetVar)}>
                          <CopyOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid size={{ xs: 3 }} container alignItems="center">
                      <Typography variant="body2" mr={1}>
                        {variableMap[targetId].metricVar}
                      </Typography>
                      <Tooltip title="Copy Actual Variable">
                        <IconButton size="small" onClick={() => handleCopyVariable(variableMap[targetId].metricVar)}>
                          <CopyOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                )
              })}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  const getFormulatColumnLabel = (rowType: RowType) => {
    if (rowType === RowType.CALCULATION_ROW) {
      return 'Formula'
    }

    if (rowType === RowType.DISPLAY_ROW) {
      return 'Display Value'
    }

    return 'Payout Value'
  }

  if (!initialized) {
    return null // Return empty component if not initialized
  }

  return (
    <Box display="flex" flexDirection="row" width="100%">
      <Box width="70%" pr={2}>
        <Box mb={2} width="100%">
          {variableLegend()}
        </Box>
        <Box mt={2} width="100%">
          <Grid container spacing={2}>
            <Grid size={{ xs: 5 }}>
              <Typography variant="h5" align="center">
                Requirement
              </Typography>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography variant="h5" align="center">
                {getFormulatColumnLabel(rowType)}
              </Typography>
            </Grid>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid container spacing={2} p={2}>
                {formulas.map((formula, index) => (
                  <Fragment key={index}>
                    <Grid size={{ xs: 5 }}>
                      {editMode === index && !isReviewMode ? (
                        <TextField
                          fullWidth
                          variant="standard"
                          multiline
                          value={formula.requirement}
                          onChange={(e) => handleEditFormula(index, 'requirement', e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleClickAway(e as any)
                            }
                          }}
                          sx={{
                            '& .MuiInputBase-input': {
                              textAlign: 'center',
                            },
                          }}
                        />
                      ) : (
                        <Typography onClick={() => handleToggleEditMode(index)}>
                          <Grid container>
                            <Grid size={{ xs: 10 }}>
                              {formula.requirement ? (
                                <Typography fontStyle="italic" align="center" ml={10}>
                                  {formula.requirement}
                                </Typography>
                              ) : (
                                <Box ml={10}>
                                  <Typography
                                    align="center"
                                    fontStyle="italic"
                                    color="textSecondary"
                                    fontSize="0.75rem"
                                  >
                                    {isReviewMode ? 'No requirement set' : 'Click to edit'}
                                  </Typography>
                                  {!isReviewMode && (
                                    <Typography
                                      align="center"
                                      fontStyle="italic"
                                      color="textSecondary"
                                      fontSize="0.75rem"
                                    >
                                      {`(example: Actual A < 50)`}
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            </Grid>
                            {!isReviewMode && (
                              <Grid size={{ xs: 2 }}>
                                <EditOutlined />
                              </Grid>
                            )}
                          </Grid>
                        </Typography>
                      )}
                    </Grid>
                    <Grid size={{ xs: 5 }}>
                      {editMode === index && !isReviewMode ? (
                        <TextField
                          fullWidth
                          variant="standard"
                          multiline
                          value={formula.formula}
                          onChange={(e) => handleEditFormula(index, 'formula', e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleClickAway(e as any)
                            }
                          }}
                          sx={{
                            '& .MuiInputBase-input': {
                              textAlign: 'center',
                            },
                          }}
                        />
                      ) : (
                        <Typography onClick={() => handleToggleEditMode(index)}>
                          <Grid container>
                            <Grid size={{ xs: 10 }}>
                              {formula.formula ? (
                                <Typography fontStyle="italic" align="center" ml={10}>
                                  {formula.formula}
                                </Typography>
                              ) : (
                                <Box ml={10}>
                                  <Typography
                                    align="center"
                                    fontStyle="italic"
                                    color="textSecondary"
                                    fontSize="0.75rem"
                                  >
                                    {isReviewMode ? 'No formula set' : 'Click to edit'}
                                  </Typography>
                                  {!isReviewMode && (
                                    <Typography
                                      align="center"
                                      fontStyle="italic"
                                      color="textSecondary"
                                      fontSize="0.75rem"
                                    >
                                      (example: Actual A/Target A or 0.9)
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            </Grid>
                            {!isReviewMode && (
                              <Grid size={{ xs: 2 }}>
                                <EditOutlined />
                              </Grid>
                            )}
                          </Grid>
                        </Typography>
                      )}
                    </Grid>
                    {!isReviewMode && (
                      <Grid size={{ xs: 2 }} style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={() => handleDeleteFormula(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Grid>
                    )}
                    {index < formulas.length - 1 && (
                      <Grid size={{ xs: 12 }}>
                        <Divider />
                      </Grid>
                    )}
                  </Fragment>
                ))}
              </Grid>
            </ClickAwayListener>
            {!isReviewMode && (
              <Grid size={{ xs: 12 }}>
                <Button onClick={handleAddFormula}>Add Another Formula</Button>
              </Grid>
            )}
          </Grid>
          <Grid size={{ xs: 12 }} mt={6}>
            <Typography variant="body2" color="textSecondary" align="left">
              Important notes:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2" color="textSecondary">
                  The list of conditions will be evaluated sequentially.
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="textSecondary">
                  The first condition that matches your situation will be used in the payout calculation.
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="textSecondary">
                  Subsequent conditions will not be considered once a match is found.
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="textSecondary">
                  Fixed payout values are still subject to variable max payout limits.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Box>
      </Box>
      <Box width="30%" pl={2}>
        <OperatorLegend theme={theme} />
      </Box>
    </Box>
  )
}

export default FormulaEditor
