import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SimpleTitle from 'components/SimpleTitle'
import { postWithTokenWithStatus } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { usePeriods } from 'hooks/usePeriods'
import React, { FC, startTransition, useEffect, useState } from 'react'
import { Period, createIdToPeriodFromList } from 'types/periods'

const RunReports: FC = () => {
  const { token, profile } = useAuth()
  const theme = useTheme()

  const [reportType, setReportType] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [rootPeriodId, setRootPeriodId] = useState<string>('')
  const [periods, setPeriods] = useState<Period[]>([])
  const [periodMap, setPeriodMap] = useState<{ [key: string]: Period }>({})
  const [primaryAggregation, setPrimaryAggregation] = useState('NONE')
  const [secondaryAggregation, setSecondaryAggregation] = useState('NONE')
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  })

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    rootOrganizationId,
    !!token && selectedOrganizations.length > 0 && rootOrganizationId !== ''
  )

  useEffect(() => {
    if (fetchedPeriods) {
      setPeriods(fetchedPeriods.periods || [])
      if (fetchedPeriods.periods.length > 0) {
        setRootPeriodId(fetchedPeriods.periods[0].id!)
        setPeriodMap(createIdToPeriodFromList(fetchedPeriods.periods))
      }
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (profile && profile.roles && profile.roles.length > 0 && rootOrganizationId === '') {
      const orgId = profile.roles[0].organization.rootOrganizationId
      if (orgId) {
        setRootOrganizationId(orgId)
      }
    }
  }, [profile])

  useEffect(() => {
    if (selectedOrganizations.length === 0) {
      setSelectedPeriods([])
    }
  }, [selectedOrganizations])

  const handleReportTypeChange = (event: SelectChangeEvent) => {
    setReportType(event.target.value as string)
  }

  const handleOrganizationChange = (selected: string[]) => {
    startTransition(() => {
      setSelectedPeriods([])
      setSelectedOrganizations(selected)
    })
  }

  const handlePeriodChange = (selected: string[]) => {
    setSelectedPeriods(selected)
    if (selected.length > 0) {
      const rootPeriodId = periodMap[selected[0]]?.rootPeriodId
      if (rootPeriodId) {
        setRootPeriodId(rootPeriodId)
      }
    }
  }

  const handlePrimaryAggregationChange = (event: SelectChangeEvent) => {
    setPrimaryAggregation(event.target.value as string)
  }

  const handleSecondaryAggregationChange = (event: SelectChangeEvent) => {
    setSecondaryAggregation(event.target.value as string)
  }

  const handleSubmit = () => {
    const requestBody = {
      reportType,
      rootOrganizationId,
      organizationIds: selectedOrganizations,
      periodIds: selectedPeriods,
      rootPeriodId,
      ...(reportType === 'ACCRUAL_PROGRESS' || reportType === 'ACCRUAL_LEDGER'
        ? {
            primaryAggregation,
            secondaryAggregation,
          }
        : {}),
    }

    postWithTokenWithStatus('reports/download', token!, requestBody)
      .then(({ data, status }) => {
        if (status !== 200 || !data.downloadUrl) {
          throw new Error(data.error || 'Unexpected response from server')
        }

        const link = document.createElement('a')
        link.href = data.downloadUrl
        link.setAttribute('download', '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setSnackbar({
          open: true,
          message: 'Download started. Please check your downloads folder.',
          severity: 'success',
        })
      })
      .catch((error) => {
        console.error('Error running report:', error)
        setSnackbar({
          open: true,
          message: error.message || 'Error running report',
          severity: 'error',
        })
      })
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 150px)' }}>
      <SimpleTitle title="Run Reports" />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={4} sx={{ width: '50%', mt: 2, mb: 4 }} justifyContent="center">
          <Grid size={{ xs: 12 }}>
            <FormControl variant="outlined" sx={{ width: '100%' }}>
              <InputLabel shrink sx={{ backgroundColor: theme.palette.background.default }}>
                Report Type
              </InputLabel>
              <Select
                labelId="report-type-label"
                id="report-type-select"
                value={reportType}
                variant="outlined"
                label="Report Type"
                sx={{ borderRadius: '21px' }}
                onChange={handleReportTypeChange}
              >
                <MenuItem value="TEAM_ACTUALS">Team Actuals Report</MenuItem>
                <MenuItem value="EMPLOYEE_ACTUALS">Employee Actuals Report</MenuItem>
                <MenuItem value="ACCRUAL_PROGRESS">Accruals Progress Report</MenuItem>
                <MenuItem value="ACCRUAL_LEDGER">Accruals Ledger Report</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <MultiOrganizationSelector
              userProfile={profile!}
              selectedOrganizationIds={selectedOrganizations}
              handleChange={handleOrganizationChange}
              labelBackgroundColor={theme.palette.background.default}
            />
          </Grid>

          <Grid size={{ xs: 12 }}>
            <MultiPeriodSelector
              periods={periods}
              selectedPeriods={selectedPeriods}
              handleChange={handlePeriodChange}
              disabled={isLoadingPeriods || selectedOrganizations.length === 0 || periods.length === 0}
              label="Selected Periods"
              labelBackgroundColor={theme.palette.background.default}
              onlySingleRootPeriodSelection={true}
            />
          </Grid>

          {(reportType === 'ACCRUAL_PROGRESS' || reportType === 'ACCRUAL_LEDGER') && (
            <>
              <Grid size={{ xs: 12 }}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel shrink sx={{ backgroundColor: theme.palette.background.default }}>
                    Primary Aggregation
                  </InputLabel>
                  <Select
                    labelId="primary-aggregation-label"
                    id="primary-aggregation-select"
                    value={primaryAggregation}
                    variant="outlined"
                    label="Primary Aggregation"
                    sx={{ borderRadius: '21px' }}
                    onChange={handlePrimaryAggregationChange}
                    defaultValue="NONE"
                  >
                    <MenuItem value="NONE">None</MenuItem>
                    <MenuItem value="LEDGER_ACCOUNT">Ledger Account</MenuItem>
                    <MenuItem value="TEAM">Team</MenuItem>
                    <MenuItem value="EMPLOYEE">Employee</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid size={{ xs: 12 }}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel shrink sx={{ backgroundColor: theme.palette.background.default }}>
                    Secondary Aggregation
                  </InputLabel>
                  <Select
                    labelId="secondary-aggregation-label"
                    id="secondary-aggregation-select"
                    value={secondaryAggregation}
                    variant="outlined"
                    label="Secondary Aggregation"
                    sx={{ borderRadius: '21px' }}
                    onChange={handleSecondaryAggregationChange}
                    defaultValue="NONE"
                  >
                    <MenuItem value="NONE">None</MenuItem>
                    <MenuItem value="FISCAL_YEAR">Fiscal Period</MenuItem>
                    <MenuItem value="SUB_PERIOD">Fiscal Sub-Period</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                !reportType ||
                selectedOrganizations.length === 0 ||
                selectedPeriods.length === 0 ||
                rootOrganizationId === ''
              }
            >
              Run Report
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default RunReports
