import type { ChangeEvent, FC } from 'react'
import type { MetricQueryParams, MetricSearchResponse } from 'types/components'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Box, CircularProgress, Grid2 as Grid, IconButton, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useSearchMetrics } from 'hooks/useComponents'
import useLocalStorage from 'hooks/useLocalStorage'
import { usePeriods } from 'hooks/usePeriods'
import { startTransition, useCallback, useEffect, useState } from 'react'
import { createIdLabelMap } from 'types/periods'
import { getOrganizationLabelMap } from 'types/user-profile'
import MetricsTable from '../MetricsTable'

const ManageMetrics: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'metrics-selected-organizations',
    []
  )
  const [page, setPage] = useLocalStorage<number>('metrics-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('metrics-rows-per-page', 25)
  const [sort, setSort] = useLocalStorage<string>('metrics-sort', 'label')
  const [order, setOrder] = useLocalStorage<string>('metrics-order', 'asc')

  const [selectedPeriods, setSelectedPeriods] = useLocalStorage<string[]>('metrics-selected-periods', [])
  const [periodLabelMap, setPeriodLabelMap] = useState<{ [key: string]: string }>({})
  const [organizationLabelMap, setOrganizationLabelMap] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(false)
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')
  const [metrics, setMetrics] = useState<MetricSearchResponse>({
    metrics: [],
    paginationInfo: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      perPage: 0,
    },
  })

  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const theme = useTheme()
  const { periods, isLoadingPeriods } = usePeriods(token!, rootOrganizationId, rootOrganizationId !== '')

  const queryParams: MetricQueryParams = {
    periodIds: selectedPeriods,
    organizationIds: selectedOrganizations,
    searchTerm,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order }],
  }

  const { metrics: metricsRsp, isLoading: isLoadingMetrics } = useSearchMetrics(token!, queryParams)

  useEffect(() => {
    startTransition(() => {
      setIsLoading(isLoadingMetrics || isLoadingPeriods || isLoadingProfile)
    })
  }, [isLoadingMetrics, isLoadingPeriods, isLoadingProfile])

  useEffect(() => {
    if (metricsRsp) {
      setMetrics(metricsRsp)
    }
  }, [metricsRsp])

  useEffect(() => {
    if (periods) {
      const newPeriodLabelMap =
        periods && periods.periods
          ? periods.periods.reduce((acc: { [key: string]: string }, period) => {
              const idLabelMap = createIdLabelMap(period)
              Object.entries(idLabelMap).forEach(([id, label]) => {
                const newLabel = label.includes(period.label) ? label : `${label} (${period.label})`
                acc[id] = newLabel
              })
              return acc
            }, {})
          : {}

      setPeriodLabelMap(newPeriodLabelMap)
    }
  }, [periods])

  useEffect(() => {
    if (profile) {
      const newOrganizationLabelMap = getOrganizationLabelMap(profile)
      setOrganizationLabelMap(newOrganizationLabelMap)
      // find the first root organization
      const org = profile.roles.find((role) => role.organization.rootOrganizationId != null)
      if (org) {
        setRootOrganizationId(org.organization.rootOrganizationId!)
      }
    }
  }, [profile])

  useEffect(() => {}, [selectedOrganizations, selectedPeriods, searchTerm])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const handleOrganizationChange = (newOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newOrganizations)
      setPage(0)
    })
  }

  const handlePeriodChange = (newPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newPeriods)
      setPage(0)
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    startTransition(() => {
      setPage(newPage)
    })
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    startTransition(() => {
      setRowsPerPage(+event?.target?.value!)
      setPage(0)
    })
  }

  return (
    <>
      <Grid container pb={2}>
        <Grid size={{ xs: 4 }}>
          <SimpleTitle title="Actuals" />
        </Grid>
        <Grid size={{ xs: 8 }} />
      </Grid>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 3 }}>
          <SearchInput
            id="metric-search-input"
            key="metric-search-input"
            onSearch={handleSearch}
            initialValue={searchTerm}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 2 }}></Grid>
        <Grid size={{ xs: 3 }}>
          <MultiPeriodSelector
            periods={periods != null ? periods.periods : []}
            selectedPeriods={selectedPeriods}
            handleChange={handlePeriodChange}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Grid container>
            <Grid size={{ xs: 11 }}>
              <MultiOrganizationSelector
                userProfile={profile!}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
                labelBackgroundColor={theme.palette.background.default}
              />
            </Grid>
            <Grid size={{ xs: 1 }}>
              <Tooltip title="Clear Filters">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedOrganizations([])
                    setSelectedPeriods([])
                    setSearchTerm('')
                  }}
                >
                  <CloseCircleOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <MetricsTable
          selectedOrganizations={selectedOrganizations}
          selectedPeriods={selectedPeriods}
          searchTerm={searchTerm}
          periodLabelMap={periodLabelMap}
          organizationLabelMap={organizationLabelMap}
          metrics={metrics}
          page={page}
          rowsPerPage={rowsPerPage}
          sort={sort}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSort={handleSort}
        />
      )}
    </>
  )
}

export default ManageMetrics
