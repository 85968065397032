import { DeleteOutlined } from '@ant-design/icons'
import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField as MuiTextField,
  Select,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AnimateButton from 'components/@extended/AnimateButton'
import Paper from 'components/@extended/Paper'
import { TextField } from 'components/@extended/TextField'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { useArchivePlan, useClosePlan } from 'hooks/usePlans'
import { useRoles } from 'hooks/useRoles'
import { useCreateUser, useDeleteUserFromOrganization, useFetchUser, useUpdateUser } from 'hooks/useUsers'
import type { FC } from 'react'
import { startTransition, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { ArchivePlanParams, ClosePlanParams } from 'types/comp-plan'
import { Permissions } from 'types/permissions'
import type { UserData } from 'types/user'
import { getTopParentOrganizationId, getUniqueOrganizationRoles } from 'types/user-profile'

const CreateUser: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const userId = searchParams.get('userId') || undefined
  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const theme = useTheme()
  const { roles, isLoading: isLoadingRoles, isError: isRolesError } = useRoles(token!, true)
  const [ledgerAccountId, setLedgerAccountId] = useState('')
  const [rootOrganizationId, setRootOrganizationId] = useState<string | null>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)

  console.log('Current state:', { userId, token, rootOrganizationId, ledgerAccountId })

  const {
    ledgerAccounts,
    isLoading: isLoadingLedgerAccounts,
    refetch: refetchLedgerAccounts,
  } = useFetchLedgerAccounts(rootOrganizationId || '', token!, !!rootOrganizationId)

  console.log('Ledger accounts:', { ledgerAccounts, isLoadingLedgerAccounts })

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [position, setPosition] = useState('')
  const [teamAssignments, setTeamAssignments] = useState<UserData['roles']>([
    { organizationId: '', roleId: '', delete: false },
  ])
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const {
    userProfile,
    isLoading: isLoadingUser,
    mutate: refetchUserProfile,
  } = useFetchUser(token!, userId || null, [['explicitRolesOnly', 'true']], !!userId)

  const { createUser, isLoading: isCreatingUser } = useCreateUser(token!)
  const { updateUser, isLoading: isUpdatingUser } = useUpdateUser(token!)
  const { deleteUser, isLoading: isDeleting } = useDeleteUserFromOrganization(token!)

  const { archivePlan, isLoading: isArchivingPlan } = useArchivePlan(token!)
  const { closePlan, isLoading: isClosingPlan } = useClosePlan(token!)

  useEffect(() => {
    console.log('Profile effect triggered', { profile })
    if (profile) {
      const firstRootOrgId = getTopParentOrganizationId(profile)
      console.log('Setting rootOrganizationId:', firstRootOrgId)
      setRootOrganizationId(firstRootOrgId)
    }
  }, [profile])

  const fetchLedgerAccounts = useCallback(() => {
    if (rootOrganizationId && token && !ledgerAccounts) {
      console.log('Fetching ledger accounts')
      refetchLedgerAccounts()
    }
  }, [rootOrganizationId, token, refetchLedgerAccounts])

  useEffect(() => {
    console.log('Root organization or token effect triggered', { rootOrganizationId, token })
    fetchLedgerAccounts()
  }, [fetchLedgerAccounts])

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.user.firstName || '')
      setLastName(userProfile.user.lastName || '')
      setEmail(userProfile.user.email || '')
      setStartDate(userProfile.user.startDate ? dayjs(userProfile.user.startDate) : null)
      setPosition(userProfile.user.position || '')
      setLedgerAccountId(userProfile.user.ledgerAccountId || '')
      setTeamAssignments(
        userProfile.roles.map((role) => ({
          organizationId: role.organization.id,
          roleId: role.role.id,
          delete: false,
        })) || [{ organizationId: '', roleId: '', delete: false }]
      )
      setEndDate(userProfile.user.endDate ? dayjs(userProfile.user.endDate) : null)

      // Set rootOrganizationId based on the first role's organization
      if (userProfile.roles.length > 0) {
        setRootOrganizationId(userProfile.roles[0].organization.rootOrganizationId)
      }
    }
  }, [userProfile])

  useEffect(() => {
    if (token && !roles.length && !isLoadingRoles) {
      // Force a re-render to trigger a new roles fetch
      setRootOrganizationId((prevId) => prevId)
    }
  }, [token, roles, isLoadingRoles])

  // Add this function to check if the user can change roles
  const canChangeRoles = (organizationId: string): string[] => {
    if (!profile) {
      return []
    }

    // check to make sure the roles are loaded
    if (isLoadingRoles || !roles) {
      return []
    }

    const roleForOrganization = getUniqueOrganizationRoles(profile).find(
      (userRole) => userRole.organization.id === organizationId
    )

    if (!roleForOrganization) {
      return []
    }

    // Greater than or equal to the role for the organization since lower # is higher ranking
    return roles.filter((role) => role.rank >= roleForOrganization.role.rank).map((role) => role.name)
  }

  // Add this function to check for MEMBER_WRITE permission
  const hasMemberWritePermission = (organizationId: string): boolean => {
    if (!profile) {
      return false
    }
    const roleForOrganization = getUniqueOrganizationRoles(profile).find(
      (userRole) => userRole.organization.id === organizationId
    )
    return roleForOrganization?.role.permissions.includes(Permissions.MEMBERS_WRITE) || false
  }
  const handleTeamAssignmentChange = (
    index: number,
    field: 'organizationId' | 'roleId' | 'delete',
    value: string | boolean
  ) => {
    const newAssignments = [...teamAssignments]
    if (field === 'delete') {
      newAssignments[index][field] = value as boolean
    } else {
      newAssignments[index][field] = value as string

      // Reset roleId when organizationId changes
      if (field === 'organizationId') {
        newAssignments[index].roleId = ''
      }
    }
    setTeamAssignments(newAssignments)
  }

  const addTeamAssignment = () => {
    setTeamAssignments([...teamAssignments, { organizationId: '', roleId: '', delete: false }])
  }

  const removeTeamAssignment = (index: number) => {
    const newAssignments = [...teamAssignments]
    newAssignments[index].delete = !newAssignments[index].delete
    setTeamAssignments(newAssignments)
  }

  const handleDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue)
  }

  // Add this helper function before isFormValid
  const validateMemberRole = (assignments: UserData['roles']): boolean => {
    const memberRoleCount = assignments
      .filter((assignment) => !assignment.delete)
      .filter((assignment) => {
        const role = roles.find((r) => r.id === assignment.roleId)
        return role?.name === 'Member'
      }).length

    return memberRoleCount === 1
  }

  const isFormValid = () => {
    const hasMemberRole = validateMemberRole(teamAssignments)

    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      email.trim() !== '' &&
      position.trim() !== '' &&
      startDate !== null &&
      ledgerAccountId.trim() !== '' &&
      hasMemberRole && // Add member role validation
      teamAssignments.some((assignment) =>
        userId
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      )
    )
  }

  const handleSubmit = async () => {
    if (!startDate) return

    if (!validateMemberRole(teamAssignments)) {
      setErrorMessage('User must have exactly one "Member" role')
      return
    }

    const userData = {
      firstName,
      lastName,
      email,
      position,
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString() || null,
      ledgerAccountId,
      roles: teamAssignments.filter((assignment) => !assignment.delete),
    }

    try {
      if (userId) {
        await updateUser(userId, userData)
        setSnackbar({
          open: true,
          message: 'User updated successfully',
          severity: 'success',
        })
      } else {
        await createUser(userData)
        setSnackbar({
          open: true,
          message: 'User created successfully',
          severity: 'success',
        })
        startTransition(() => {
          navigate('/organization')
        })
      }
    } catch (error) {
      console.error('Error handling user action:', error)
      setErrorMessage('An error occurred while saving the user')
    }
  }

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    if (!userId || !rootOrganizationId) return

    try {
      const success = await deleteUser(userId, rootOrganizationId)
      if (success) {
        setDeleteDialogOpen(false)
        setSnackbar({
          open: true,
          message: 'User archived successfully',
          severity: 'success',
        })
        startTransition(() => {
          navigate('/organization')
        })
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to archive user',
          severity: 'error',
        })
      }
    } catch (error) {
      console.error('Error archiving user:', error)
      setSnackbar({
        open: true,
        message: 'An error occurred while trying to archive the user',
        severity: 'error',
      })
    }
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  const getValidationMessages = () => {
    const messages: string[] = []

    if (firstName.trim() === '') messages.push('First name is required')
    if (lastName.trim() === '') messages.push('Last name is required')
    if (email.trim() === '') messages.push('Email is required')
    if (position.trim() === '') messages.push('Position is required')
    if (!startDate) messages.push('Start date is required')
    if (ledgerAccountId.trim() === '') messages.push('Ledger account is required')

    const memberRoleCount = teamAssignments
      .filter((assignment) => !assignment.delete)
      .filter((assignment) => {
        const role = roles.find((r) => r.id === assignment.roleId)
        return role?.name === 'Member'
      }).length

    if (memberRoleCount === 0) messages.push('User must have one "Member" role')
    if (memberRoleCount > 1) messages.push('User cannot have more than one "Member" role')

    if (
      !teamAssignments.some((assignment) =>
        userId
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      )
    ) {
      messages.push('At least one team assignment is required')
    }

    return messages.join('\n')
  }

  // State for modals and confirmation text
  const [finalizeModalOpen, setFinalizeModalOpen] = useState(false)
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const [archiveConfirmationText, setArchiveConfirmationText] = useState('')
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)

  // Add state for finalize end date
  const [finalizeEndDate, setFinalizeEndDate] = useState<Dayjs | null>(null)

  // Handlers for Finalize Plan
  const handleOpenFinalizeModal = (planId: string) => {
    setSelectedPlanId(planId)
    setFinalizeModalOpen(true)
  }
  const handleCloseFinalizeModal = () => {
    setFinalizeModalOpen(false)
  }
  const handleFinalizePlan = () => {
    if (!selectedPlanId || !finalizeEndDate) return

    // Find the selected plan from userProfile.plans
    const selectedPlan = userProfile?.plans?.find((plan) => plan.id === selectedPlanId)
    if (!selectedPlan) return

    closePlan({
      planId: selectedPlanId,
      endDate: finalizeEndDate.toISOString(),
      rootOrganizationId: selectedPlan.rootOrganizationId,
    } as ClosePlanParams)
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Plan finalized successfully',
          severity: 'success',
        })
        setFinalizeModalOpen(false)

        // Refetch the target user's profile
        return refetchUserProfile()
      })
      .catch((error) => {
        console.error('Error finalizing plan:', error)
        setSnackbar({
          open: true,
          message: 'Failed to finalize the plan',
          severity: 'error',
        })
      })
  }

  // Handlers for Archive Plan
  const handleOpenArchiveModal = (planId: string) => {
    setSelectedPlanId(planId)
    setArchiveModalOpen(true)
  }
  const handleCloseArchiveModal = () => {
    setArchiveModalOpen(false)
    setArchiveConfirmationText('')
  }
  const handleArchivePlan = () => {
    if (!selectedPlanId || !rootOrganizationId) return

    const selectedPlan = userProfile?.plans?.find((plan) => plan.id === selectedPlanId)
    if (!selectedPlan) return

    archivePlan({
      planId: selectedPlanId,
      rootOrganizationId: selectedPlan.rootOrganizationId,
    } as ArchivePlanParams)
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Plan archived successfully',
          severity: 'success',
        })
        setArchiveModalOpen(false)

        // Refetch the target user's profile
        return refetchUserProfile()
      })
      .catch((error) => {
        console.error('Error archiving plan:', error)
        setSnackbar({
          open: true,
          message: 'Failed to archive the plan',
          severity: 'error',
        })
      })
  }

  if (
    isLoadingProfile ||
    isLoadingRoles ||
    isLoadingLedgerAccounts ||
    isLoadingUser ||
    isArchivingPlan ||
    isClosingPlan
  ) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (isRolesError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">Error loading roles</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h2">{userId ? 'Edit Team Member' : 'Create New Team Member'}</Typography>
        {userId && (
          <IconButton onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
            <DeleteOutlined />
          </IconButton>
        )}
      </Box>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Archive Team Member</DialogTitle>
        <DialogContent>
          Are you sure you want to archive this team member? This action can be reversed by an administrator.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={isDeleting}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={20} /> : null}
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>

      {/* User Info Section */}
      <Paper>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Employee Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label="First Name"
              variant="outlined"
              pill
              shrink
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label="Last Name"
              variant="outlined"
              pill
              shrink
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label="Email"
              variant="outlined"
              pill
              shrink
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              disabled={!!userId}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label="Position"
              variant="outlined"
              pill
              shrink
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiInputLabel-root': {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
            >
              <InputLabel shrink variant="outlined">
                Ledger Account
              </InputLabel>
              <Select
                variant="outlined"
                value={ledgerAccountId}
                onChange={(e) => setLedgerAccountId(e.target.value)}
                fullWidth
                style={{ borderRadius: '21px' }}
              >
                {ledgerAccounts?.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiInputLabel-root': {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
            >
              <InputLabel shrink variant="outlined">
                Start Date
              </InputLabel>
              <DatePicker
                value={startDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '21px',
                        '& fieldset': {
                          borderRadius: '21px',
                        },
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiInputLabel-root': {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
            >
              <InputLabel shrink variant="outlined">
                End Date (optional)
              </InputLabel>
              <DatePicker
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '21px',
                        '& fieldset': {
                          borderRadius: '21px',
                        },
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Employee Plans Section */}
      {userProfile?.plans && userProfile.plans.length > 0 && (
        <Paper sx={{ mb: 3 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Employee Plan(s)
          </Typography>
          <Grid container spacing={2}>
            {userProfile.plans.map((plan) => (
              <Grid key={plan.id} size={{ xs: 12 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    padding: 2,
                  }}
                >
                  <Box>
                    <Typography variant="body1">
                      <strong>Team:</strong> {plan.organizationName || 'N/A'}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Start Date:</strong> {new Date(plan.effectiveDate).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1">
                      <strong>End Date:</strong>{' '}
                      {plan.endDate ? new Date(plan.endDate).toLocaleDateString() : 'Present'}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{plan.basePlus ? 'Total Compensation' : 'Base Compensation'} :</strong> $
                      {(plan.baseSalary * plan.periodProration).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Variable Compensation:</strong> {plan.variablePercent * 100}% (Max Variable Compensation:{' '}
                      {plan.variableCap * 100}
                      %)
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenFinalizeModal(plan.id || '')}
                      disabled={plan.finalized}
                    >
                      Finalize Plan
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleOpenArchiveModal(plan.id || '')}
                      disabled={plan.finalized}
                    >
                      Archive Plan
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {/* Finalize Plan Modal */}
      <Dialog open={finalizeModalOpen} onClose={handleCloseFinalizeModal}>
        <DialogTitle>Finalize Plan</DialogTitle>
        <DialogContent>
          <Typography>
            To finalize this plan, please select an <strong>End Date</strong> below.
          </Typography>
          <InputLabel shrink>End Date</InputLabel>
          <DatePicker
            value={finalizeEndDate}
            onChange={(newValue) => setFinalizeEndDate(newValue)}
            sx={{ mt: 2, width: '100%' }}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFinalizeModal} disabled={isClosingPlan}>
            Cancel
          </Button>
          <Button
            onClick={handleFinalizePlan}
            color="primary"
            disabled={!finalizeEndDate || isClosingPlan}
            startIcon={isClosingPlan ? <CircularProgress size={20} /> : null}
          >
            Finalize Plan
          </Button>
        </DialogActions>
      </Dialog>

      {/* Archive Plan Modal */}
      <Dialog open={archiveModalOpen} onClose={handleCloseArchiveModal}>
        <DialogTitle>Archive Plan</DialogTitle>
        <DialogContent>
          <Typography>
            To archive this plan, please type <strong>"Archive Plan"</strong> in the box below to confirm.
          </Typography>
          <MuiTextField
            variant="outlined"
            fullWidth
            value={archiveConfirmationText}
            onChange={(e) => setArchiveConfirmationText(e.target.value)}
            placeholder="Type 'Archive Plan' to confirm"
            sx={{ mt: 2, borderRadius: '50px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseArchiveModal} disabled={isArchivingPlan}>
            Cancel
          </Button>
          <Button
            onClick={handleArchivePlan}
            color="error"
            disabled={archiveConfirmationText !== 'Archive Plan' || isArchivingPlan}
            startIcon={isArchivingPlan ? <CircularProgress size={20} /> : null}
          >
            Archive Plan
          </Button>
        </DialogActions>
      </Dialog>

      {/* Roles Section */}
      <Paper>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4">Add to Team(s)</Typography>
        </Box>
        <Grid container spacing={3}>
          {teamAssignments.map((assignment, index) => {
            const isNewRow = !assignment.delete && (!assignment.organizationId || !assignment.roleId)
            const canChangeRolesForOrg = assignment.organizationId ? canChangeRoles(assignment.organizationId) : []
            const hasWritePermission = isNewRow ? true : hasMemberWritePermission(assignment.organizationId)
            const currentRoleInAllowedRoles =
              isNewRow || canChangeRolesForOrg.includes(roles.find((r) => r.id === assignment.roleId)?.name || '')
            const isDisabled = assignment.delete || (!isNewRow && (!hasWritePermission || !currentRoleInAllowedRoles))

            const tooltipTitle = isDisabled ? 'Insufficient permissions or role has been disabled' : ''

            const rolesToShow = isDisabled ? roles : roles.filter((role) => canChangeRolesForOrg.includes(role.name))

            return (
              <Grid key={index} size={{ xs: 12 }}>
                <Tooltip title={tooltipTitle} arrow>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      opacity: isDisabled ? 0.5 : 1,
                      backgroundColor: isDisabled
                        ? (theme) => alpha(theme.palette.action.disabled, 0.1)
                        : 'transparent',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <SingleOrganizationSelector
                        userProfile={profile!}
                        selectedOrganizationId={assignment.organizationId}
                        handleChange={(value) => handleTeamAssignmentChange(index, 'organizationId', value)}
                        showLabelAbove
                        disabled={isDisabled}
                        label="Team"
                        labelBackgroundColor={theme.palette.background.paper}
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{
                          '& .MuiInputLabel-root': {
                            backgroundColor: theme.palette.background.paper,
                          },
                        }}
                      >
                        <InputLabel shrink variant="outlined">
                          Role
                        </InputLabel>
                        <Select
                          variant="outlined"
                          value={assignment.roleId}
                          onChange={(e) => handleTeamAssignmentChange(index, 'roleId', e.target.value)}
                          label="Role"
                          fullWidth
                          style={{ borderRadius: '21px' }}
                          disabled={isDisabled || !assignment.organizationId}
                        >
                          {assignment.organizationId &&
                            rolesToShow.map((role) => (
                              <MenuItem key={role.id} value={role.id}>
                                {role.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <IconButton
                      onClick={() => removeTeamAssignment(index)}
                      disabled={isDisabled || teamAssignments.length <= 1}
                    >
                      <DeleteOutlined color={isDisabled ? 'disabled' : 'inherit'} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>
            )
          })}
          <Grid size={{ xs: 12 }}>
            <Button startIcon={<AddIcon />} onClick={addTeamAssignment} sx={{ mt: 1 }}>
              Add Another Team
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box mt={3}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <AnimateButton>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                startTransition(() => {
                  navigate('/organization')
                })
              }}
            >
              Cancel
            </Button>
          </AnimateButton>
          <Tooltip title={!isFormValid() ? getValidationMessages() : ''} arrow placement="top-start">
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!isFormValid() || isCreatingUser || isUpdatingUser}
                startIcon={isCreatingUser || isUpdatingUser ? <CircularProgress size={20} /> : null}
              >
                {userId ? 'Update User' : 'Create User'}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Box>

      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CreateUser
