import type { FC } from 'react'
import type { ApprovalChain } from 'types/approvals'
import type { OrganizationSettingDTO } from 'types/organization'
import { FormControl, Grid2 as Grid, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material'
import Paper from 'components/@extended/Paper'

interface ApprovalSettingsProps {
  settings: OrganizationSettingDTO[]
  handleChange: (index: number, value: string) => void
  approvalChains: ApprovalChain[] | undefined
}

const ApprovalSettings: FC<ApprovalSettingsProps> = ({ settings, handleChange, approvalChains }) => {
  const theme = useTheme()

  return (
    <Paper elevation={0}>
      <Typography variant="h5" gutterBottom mb={2}>
        Approval Chains
      </Typography>
      <Grid container spacing={3} p={2}>
        {['Metric', 'Accrual', 'Target', 'Plan', 'Accrual Ledger'].map((type) => (
          <Grid size={{ xs: 12, sm: 6 }} key={type}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiInputLabel-root': {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
            >
              <InputLabel shrink variant="outlined">
                {`${type} Approval Chain`}
              </InputLabel>
              <Select
                fullWidth
                label={`${type} Approval Chain`}
                variant="outlined"
                value={settings.find((s) => s.settingName === `${type} Approval Chain`)?.settingValue || ''}
                onChange={(e) =>
                  handleChange(
                    settings.findIndex((s) => s.settingName === `${type} Approval Chain`),
                    e.target.value
                  )
                }
                style={{ borderRadius: '21px' }}
              >
                {approvalChains && approvalChains.length > 0 ? (
                  approvalChains.map((chain) => (
                    <MenuItem key={chain.id} value={chain.id?.toString() ?? ''}>
                      {chain.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No approval chains available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

export default ApprovalSettings
