import type { User as Auth0User } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import { deleteWithTokenWithStatus, fetchWithTokenWithStatus, postWithTokenWithStatus } from 'hooks/http'
import React, { createContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import type { MFAEnrollmentsResponse } from 'types/auth'
import type { UserProfile } from 'types/user-profile'

export interface AuthContextProps {
  auth0User: Auth0User | undefined
  profile: UserProfile | undefined
  token: string | null
  isLoggedIn: boolean
  isLoading: boolean
  errorProfile: string | null
  errorToken: string | null
  requestStatus: number | null
  refetchProfile: () => void
  refreshToken: () => Promise<void>
}

const AuthContext = createContext<AuthContextProps>({
  auth0User: undefined,
  profile: undefined,
  token: null,
  isLoggedIn: false,
  isLoading: true,
  errorProfile: null,
  errorToken: null,
  requestStatus: null,
  refetchProfile: () => {},
  refreshToken: () => Promise.resolve(),
})

interface UserProfileResponseWithStatus {
  data: UserProfile
  status: number
}

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    user: auth0User,
    isLoading: isLoadingAuth0User,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0()
  const [token, setToken] = useState<string | null>(null)
  const [isLoadingToken, setIsLoadingToken] = useState(true)
  const [errorToken, setErrorToken] = useState<string | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined)
  const [requestStatus, setRequestStatus] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorProfile, setErrorProfile] = useState<string | null>(null)

  const {
    data,
    error: swrError,
    isLoading: swrLoading,
    mutate,
  } = useSWR<UserProfileResponseWithStatus, Error>(token ? [`profile`, token] : null, ([url, token]) =>
    fetchWithTokenWithStatus(url as string, token as string, null)
  )

  console.log('data in ProfileProvider', data)
  console.log('swrError in ProfileProvider', swrError)
  console.log('swrLoading in ProfileProvider', swrLoading)

  const fetchToken = async () => {
    console.log('fetchToken called')
    setIsLoading(true)
    if (!isLoadingAuth0User && isAuthenticated) {
      console.log('Fetching the token in AuthContext')
      try {
        const accessToken = await getAccessTokenSilently()
        setErrorToken(null)
        setToken(accessToken)
        setIsLoadingToken(false)
      } catch (error) {
        // Type guard for Auth0 errors
        if (error instanceof Error) {
          console.error(`Token error: ${error.name}:`, error.message)

          switch (error.name) {
            case 'Missing Refresh Token':
            case 'invalid_grant':
              // Serious authentication errors that require re-auth
              setErrorToken('Session expired. Please log in again.')
              setToken(null)
              loginWithRedirect()
              break

            case 'TimeoutError':
              // Network timeout - could retry
              setErrorToken('Network timeout. Will retry automatically.')
              // Retry after 5 seconds
              setTimeout(fetchToken, 5000)
              break

            case 'Token expired':
            case 'login_required':
              // Try silent refresh first
              try {
                await getAccessTokenSilently({ cacheMode: 'off' })
              } catch (refreshError) {
                console.error('Error refreshing token:', refreshError)
                setErrorToken('Unable to refresh session. Please log in again.')
                loginWithRedirect()
              }
              break

            default:
              // Unknown errors - log but don't disrupt user session
              setErrorToken('Authentication error. Please try refreshing the page.')
              console.error('Unexpected auth error:', error)
          }
        } else {
          setErrorToken('An unexpected error occurred')
          console.error('Non-Error object thrown:', error)
        }
        setIsLoadingToken(false)
      }
    } else {
      if (isLoadingAuth0User) {
        console.log('Loading Auth0 user in AuthContext')
      } else if (!isAuthenticated) {
        console.log('Not authenticated in AuthContext')
        setToken(null)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchToken()

    // Only set up interval if we're authenticated
    if (isAuthenticated) {
      // Set timer to refresh token every 5 minutes
      const interval = setInterval(
        async () => {
          try {
            await fetchToken()
          } catch (error) {
            console.error('Token refresh failed:', error)
          }
        },
        2 * 60 * 1000
      )

      return () => clearInterval(interval)
    } else {
      if (!isLoadingAuth0User) {
        console.log('Loading Auth0 user completed in AuthContext')
        if (!isAuthenticated) {
          console.log('Not authenticated in AuthContext')
          loginWithRedirect()
        }
      } else {
        console.log('Loading Auth0 user in AuthContext')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoadingAuth0User])

  useEffect(() => {
    function fetchUserProfileData() {
      if (swrLoading || isLoadingToken) {
        console.log('swrLoading or isLoadingToken', swrLoading, isLoadingToken)
        setIsLoading(true)
        return
      }
      if (swrError) {
        console.log('swrError', swrError)
        setErrorProfile(
          typeof swrError === 'string'
            ? swrError
            : (swrError?.message ?? 'An swr error occurred while fetching profile data')
        )
        return
      }
      console.log('data in fetchUserProfileData', data)
      setProfile(data?.data)
      setRequestStatus(data?.status ?? null)
      setIsLoading(false)
    }

    fetchUserProfileData()
  }, [data, swrError, swrLoading, isLoadingToken])

  useEffect(() => {
    if (auth0User) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [auth0User])

  // forced refresh of the token
  const refreshToken = async () => {
    fetchToken()
  }

  const refetchProfile = () => mutate()

  return (
    <AuthContext
      value={{
        auth0User,
        profile,
        token,
        isLoggedIn,
        isLoading: isLoadingAuth0User || isLoading,
        errorProfile,
        errorToken,
        requestStatus,
        refetchProfile,
        refreshToken,
      }}
    >
      {isLoadingAuth0User || isLoading || profile === undefined || !token ? null : children}
    </AuthContext>
  )
}

function usePasswordReset(token: string) {
  const triggerPasswordReset = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/password-reset', token, {})
      return response
    } catch (error) {
      console.error('Error triggering password reset:', error)
      throw error
    }
  }, [token])

  return { triggerPasswordReset }
}

function useMFAEmail(token: string) {
  const triggerMFAEmail = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/mfa-email', token, {})
      return response
    } catch (error) {
      console.error('Error triggering MFA email:', error)
      throw error
    }
  }, [token])

  return { triggerMFAEmail }
}

function useSignOutAll(token: string) {
  const triggerSignOutAll = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/sign-out-all', token, {})
      return response
    } catch (error) {
      console.error('Error signing out of all devices:', error)
      throw error
    }
  }, [token])

  return { triggerSignOutAll }
}

function useMFAEnrollments(token: string, shouldFetch: boolean = true) {
  const key = shouldFetch ? ['auth/mfa-enrollments', token] : null
  const { data, error, isLoading, mutate } = useSWR<MFAEnrollmentsResponse>(
    key,
    ([url, token]) => fetchWithTokenWithStatus(url, token, null),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const deleteMFAEnrollment = React.useCallback(
    async (enrollmentId: string) => {
      try {
        const response = await deleteWithTokenWithStatus(`auth/mfa-enrollments/${enrollmentId}`, token)
        await mutate() // Refetch the enrollments after deletion
        return response
      } catch (error) {
        console.error('Error deleting MFA enrollment:', error)
        throw error
      }
    },
    [token, mutate]
  )

  const revalidate = () => {
    if (key) {
      mutate()
    }
  }

  return {
    mfaEnrollments: data?.data || [],
    isLoading,
    isError: error,
    deleteMFAEnrollment,
    revalidate,
  }
}

export { AuthContext, AuthProvider, useMFAEmail, useMFAEnrollments, usePasswordReset, useSignOutAll }
