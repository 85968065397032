import type { JSX } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { ApprovalBatchQueryParams } from 'types/approvals'

import { CloseCircleOutlined } from '@ant-design/icons'
import EditOutlined from '@ant-design/icons/EditOutlined'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material'
import type { PaginationInfo } from 'types/common'
import type { KeyedObject } from 'types/root'

import Paper from 'components/@extended/Paper'
// Assuming you have a hook to fetch approval batches
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import { useFetchApprovalBatches } from 'hooks/useApprovals'
import useAuth from 'hooks/useAuth'
import useLocalStorage from 'hooks/useLocalStorage'
import { ApprovalBatchStatus } from 'types/approvals'
import { ThemeMode } from 'types/config'

// Define the columns for the table
interface ColumnProps {
  id: string | null
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any) => string | boolean | JSX.Element
}

const ManageApprovals: React.FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { token } = useAuth()
  const [page, setPage] = useLocalStorage<number>('approvals-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('approvals-rows-per-page', 10)
  const [status, setStatus] = useLocalStorage<string>('approvals-status', '')
  const [batchType, setBatchType] = useLocalStorage<string>('approvals-batch-type', '')
  const [label] = useState<string>('')

  const [searchTerm, setSearchTerm] = useState<string>('')

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const formatEnumString = (str: string): string => {
    if (str === 'metric') {
      return 'Actuals'
    }

    if (str === 'accrual') {
      return 'Accrual Progress'
    }

    return str
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const [params, setParams] = useState<ApprovalBatchQueryParams>({
    batchTypes: [],
    page: 0,
    perPage: 10,
    status: '',
    label: '',
  })

  useEffect(() => {
    setParams({
      batchTypes: batchType ? [batchType] : [],
      page,
      perPage: rowsPerPage,
      status,
      label,
    })
  }, [page, rowsPerPage, status, batchType, label])

  const { approvals, isLoading } = useFetchApprovalBatches(token!, params)

  const paginationInfo: PaginationInfo = approvals?.paginationInfo ?? {
    currentPage: 0,
    perPage: 10,
    totalItems: 0,
    totalPages: 0,
  }

  useEffect(() => {
    console.log('Approvals data:', approvals)
  }, [approvals])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const columns: ColumnProps[] = [
    { id: 'label', label: 'Approval Batch', minWidth: 100 },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      format: (status: string) => formatEnumString(status),
    },
    { id: 'batchType', label: 'Batch Type', minWidth: 100, format: (type: string) => formatEnumString(type) },
    { id: 'items', label: 'Item Count', minWidth: 100, format: (items: any) => (items ? items.length : 0) },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 50,
      format: (batchId: string) => (
        <Box display="flex">
          <Tooltip title="View Details">
            <IconButton color="primary" onClick={() => navigate(`/admin/approvals/${batchId}/edit`)}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ]

  return (
    <>
      <Grid container pb={2}>
        <Grid size={{ xs: 4 }}>
          <SimpleTitle title="Approvals" />
        </Grid>
        <Grid size={{ xs: 8 }} />
      </Grid>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 3 }}>
          <SearchInput
            id="approval-search-input"
            key="approval-search-input"
            onSearch={handleSearch}
            initialValue={searchTerm}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 4 }}></Grid>
        <Grid size={{ xs: 2 }}>
          <Box sx={{ width: '100%' }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                shrink
                variant="outlined"
                sx={{
                  '&.MuiInputLabel-shrink': {
                    background: theme.palette.background.default,
                  },
                }}
              >
                Select Status
              </InputLabel>
              <Select
                fullWidth
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                variant="outlined"
                style={{ borderRadius: '21px' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: hoverColor,
                      },
                    },
                  },
                }}
              >
                {Object.values(ApprovalBatchStatus)
                  .filter(
                    (status) =>
                      ![
                        ApprovalBatchStatus.ALL,
                        ApprovalBatchStatus.INITIAL,
                        ApprovalBatchStatus.INVALID,
                        ApprovalBatchStatus.ITEM_UPDATED,
                      ].includes(status)
                  )
                  .map((status) => (
                    <MenuItem key={status} value={status}>
                      {formatEnumString(status)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid size={{ xs: 3 }}>
          <Grid container>
            <Grid size={{ xs: 11 }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  shrink
                  variant="outlined"
                  sx={{
                    '&.MuiInputLabel-shrink': {
                      background: theme.palette.background.default,
                    },
                  }}
                >
                  Select Type
                </InputLabel>
                <Select
                  fullWidth
                  value={batchType}
                  onChange={(e) => setBatchType(e.target.value)}
                  label="Batch Type"
                  variant="outlined"
                  style={{ borderRadius: '21px' }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: hoverColor,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">All Types</MenuItem>
                  <MenuItem value="plan">Plan</MenuItem>
                  <MenuItem value="metric">Actuals</MenuItem>
                  <MenuItem value="accrual">Accrual Progress</MenuItem>
                  <MenuItem value="target">Target</MenuItem>
                  <MenuItem value="accrual_ledger">Accrual Ledger</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 1 }}>
              <Tooltip title="Clear Filters">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setStatus('')
                    setBatchType('')
                    setSearchTerm('')
                  }}
                >
                  <CloseCircleOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Paper> */}
      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            size={{ xs: 12 }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      id={`cell-col-${column.id || ''}`}
                      sx={{
                        minWidth: column.minWidth,
                        position: 'sticky !important',
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {approvals?.batches?.length > 0 ? (
                  approvals.batches.map((row: KeyedObject, indx) => (
                    <TableRow id={`tble-row-${indx}`} sx={{ py: 3 }} role="checkbox" tabIndex={-1} key={`key-${indx}`}>
                      {columns.map((column) => {
                        const value = column.id ? row[column.id] : row
                        console.log(`Rendering cell for column ${column.id}:`, value)
                        return (
                          <TableCell id={`cell-${column.id}`} key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={paginationInfo.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  )
}

export default ManageApprovals
