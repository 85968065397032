import AuthLayout from 'layout/Auth'
import Login from 'pages/auth/login'
import Logout from 'pages/auth/logout'

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          path: 'logout',
          element: <Logout />,
          protected: false,
        },
        {
          path: 'login',
          element: <Login />,
          protected: false,
        },
      ],
    },
  ],
}

export default LoginRoutes
