import type { FC } from 'react'
import type { Period } from 'types/periods'
import { Box, CircularProgress } from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { usePeriods } from 'hooks/usePeriods'
import { Suspense, useEffect, useState } from 'react'
import { getRootOrganizationId } from 'types/user-profile'
import PeriodsTable from '../../../components/PeriodsTable'
import PeriodsWizard from './PeriodsWizard'

const ManagePeriods: FC = () => {
  const [periods, setPeriods] = useState<Period[]>([])

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ManagePeriodsContent periods={periods} setPeriods={setPeriods} />
    </Suspense>
  )
}

function ManagePeriodsContent({ periods, setPeriods }: { periods: Period[]; setPeriods: (periods: Period[]) => void }) {
  const [isLoading, setIsLoading] = useState(false)

  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const { periods: fetchedPeriods, isLoadingPeriods, handleRefetch: refetchPeriods } = usePeriods(token!, null, !!token)

  useEffect(() => {
    setIsLoading(isLoadingPeriods || isLoadingProfile)
  }, [isLoadingPeriods, isLoadingProfile])

  if (!profile) return null
  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <SimpleTitle title="Periods" />
              {!isLoadingPeriods && (
                <PeriodsWizard
                  rootOrganizationID={getRootOrganizationId(profile)!}
                  existingPeriods={fetchedPeriods?.periods}
                  refetchPeriods={refetchPeriods}
                />
              )}
            </Box>
          </Box>
          <Box>
            {isLoadingPeriods ? (
              <CircularProgress />
            ) : (
              <PeriodsTable periods={fetchedPeriods?.periods || []} token={token!} onPeriodsUpdate={refetchPeriods} />
            )}
          </Box>
        </div>
      )}
    </div>
  )
}

const LoadingSpinner: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
)

export default ManagePeriods
