import type { Theme } from '@mui/material'
import { Box, LinearProgress, linearProgressClasses, styled, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import type { FC } from 'react'
import { startTransition } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeMode } from 'types/config'
import type { UserPeriodOverview } from 'types/dashboard'

interface TeamEmployeeOverviewsProps {
  userPeriodOverviews: UserPeriodOverview[]
  theme: Theme
}

const TeamEmployeeOverviews: FC<TeamEmployeeOverviewsProps> = ({ userPeriodOverviews, theme }) => {
  const navigate = useNavigate()

  if (!userPeriodOverviews) {
    return null
  }

  console.log(theme.palette.grey)

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.background.default : theme.palette.action.hover

  const baseBarColor = theme.palette.mode === ThemeMode.DARK ? theme.palette.info : theme.palette.primary
  const barColor = theme.palette.mode === ThemeMode.DARK ? 'primary' : 'primary'
  const barBackgroundColor = theme.palette.mode === ThemeMode.DARK ? baseBarColor.darker : baseBarColor.lighter
  const barBackgroundColor2 = theme.palette.mode === ThemeMode.DARK ? baseBarColor.light : baseBarColor.darker

  const OverTargetLinearProgress = styled(LinearProgress)({
    backgroundColor: barBackgroundColor,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: barBackgroundColor2,
    },
  })

  const DefaultLinearProgress = styled(LinearProgress)({
    backgroundColor: barBackgroundColor,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: barBackgroundColor,
    },
  })

  return (
    <Paper sx={{ pl: 4, pr: 4, pt: 2, pb: 2, mt: 2 }}>
      <Typography
        variant='h4'
        sx={{ p: 2 }}
      >
        Employee Overviews
      </Typography>
      {userPeriodOverviews.filter(employee => employee.planItems.length > 0).length === 0 ?
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography
            variant='body1'
            color='text.secondary'
          >
            No employee overviews to display
          </Typography>
        </Box>
      : userPeriodOverviews
          .filter(employee => employee.planItems.length > 0)
          .map(employee => {
            const totalOnTargetAmount = employee.planItems.reduce((sum, item) => sum + item.onTargetAmount, 0)
            const totalPayoutAmount = employee.planItems.reduce((sum, item) => sum + item.payoutAmount, 0)
            const progressPercentage = (totalPayoutAmount / totalOnTargetAmount) * 100

            const overTarget = (100 / progressPercentage) * 100
            const linearProgress =
              progressPercentage > 100 ?
                <OverTargetLinearProgress
                  color={barColor}
                  variant='determinate'
                  value={overTarget}
                  sx={{ height: 12, borderRadius: 5 }}
                />
              : <DefaultLinearProgress
                  color={barColor}
                  variant='determinate'
                  value={progressPercentage}
                  sx={{ height: 12, borderRadius: 5 }}
                />

            return (
              <Box
                key={employee.userId}
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: hoverColor,
                  },
                }}
                onClick={() => startTransition(() => navigate(`/employee/${employee.userId}/compplan`))}
              >
                <Typography variant='subtitle1'>
                  {employee.firstName} {employee.lastName} - {employee.organizationName}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Box sx={{ width: '100%', mr: 1 }}>{linearProgress}</Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                  >
                    Earned: ${totalPayoutAmount.toLocaleString('en-US', { maximumFractionDigits: 0 })} / On-Target: $
                    {totalOnTargetAmount.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ mr: 2 }}
                  >
                    {progressPercentage.toFixed(0)}%
                  </Typography>
                </Box>
              </Box>
            )
          })
      }
    </Paper>
  )
}

export default TeamEmployeeOverviews
