// material-ui
import { Stack, Typography } from '@mui/material'

function Footer() {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: '0px 16px 0px',
        mt: 'auto',
        bottom: 0,
        py: 2,
        width: '100%',
      }}
    >
      <Typography variant="caption">&copy; 2025 Purcent LLC - A Boost Phase Venture. All rights reserved</Typography>
      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center"></Stack>
    </Stack>
  )
}

export default Footer
