import { Grid2 as Grid } from '@mui/material'
import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

import PlanAllocationTables from './PlanAllocationTables'
import PlanComponentSummary from './PlanComponentSummary'
import PlanTargets from './PlanTargets'

function ReviewPlan() {
  const { plan, employeeName, compensationTemplate } = useCreatePlanContext()

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid size={{ xs: 12 }}>
          <CompensationSummaryCard
            basePlus={plan.basePlus}
            variablePercent={plan.variablePercent || 0}
            baseSalary={plan.baseSalary || 0}
            variableCap={plan.variableCap || -0}
            name={employeeName}
            position={plan.position}
            effectiveDate={plan.effectiveDate}
            period={compensationTemplate?.period}
            periodId={plan.periodId}
            periodProration={plan.periodProration}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanComponentSummary />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanTargets reviewOnly />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanAllocationTables reviewOnly />
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewPlan
