import type { ChangeEvent, FC, JSX } from 'react'
import type { TemplateQueryParams } from 'types/comp-template'
import type { KeyedObject } from 'types/root'
import {
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid2 as Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import Paper from 'components/@extended/Paper'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SearchInput from 'components/SearchInput'
import useAuth from 'hooks/useAuth'
import useLocalStorage from 'hooks/useLocalStorage'
import { usePeriods } from 'hooks/usePeriods'
import { useSearchOrganizationTemplates } from 'hooks/useTemplates'
import { startTransition, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createIdLabelMap, getClosedPeriodIds } from 'types/periods'
import { getOrganizationLabelMap } from 'types/user-profile'
import { wholePercentFormatter } from 'utils/formatting'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: KeyedObject) => string | boolean | JSX.Element
}

interface TemplatesTableProps {
  withCreateButtons?: boolean
  deleteTemplate: (token: string, id: string) => Promise<{ message: string }>
}

const TemplatesTable: FC<TemplatesTableProps> = ({ withCreateButtons = true, deleteTemplate }) => {
  // Navigation and theme hooks
  const navigate = useNavigate()
  const theme = useTheme()
  const { token, profile } = useAuth()

  // Local storage hooks
  const [page, setPage] = useLocalStorage<number>('templates-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('templates-rows-per-page', 10)
  const [search, setSearch] = useLocalStorage<string>('templates-search', '')
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'templates-selected-organizations',
    []
  )
  const [sort, setSort] = useLocalStorage<string>('templates-table-sort', 'label')
  const [order, setOrder] = useLocalStorage<string>('templates-table-order', 'asc')
  const [selectedPeriods, setSelectedPeriods] = useLocalStorage<string[]>('templates-selected-periods', [])

  // State hooks
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [templateToDelete, setTemplateToDelete] = useState<{ id: string; name: string } | null>(null)
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')

  // Refs
  const tableContainerRef = useRef(null)

  // Query parameters
  const queryParams: TemplateQueryParams = {
    organizationIds: selectedOrganizations,
    searchTerm: search,
    periodIds: selectedPeriods,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order }],
  }

  // Data fetching hooks
  const { templates, isLoading, refetch } = useSearchOrganizationTemplates(token!, queryParams)
  const { periods, isLoadingPeriods } = usePeriods(token!, rootOrganizationId, rootOrganizationId !== '')

  // Add this after periods is fetched
  const closedPeriodIds = periods?.periods ? getClosedPeriodIds(periods.periods) : []

  useEffect(() => {
    if (tableContainerRef.current) {
      ;(tableContainerRef.current as HTMLElement).scrollTop = 0
    }
  }, [sort, order, page, rowsPerPage])

  // Add new useEffect to trigger refetch when selectedPeriods changes
  useEffect(() => {
    refetch()
  }, [selectedPeriods, refetch])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  // table columns
  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 300 },
    {
      id: 'organizationName',
      label: 'Team',
      minWidth: 200,
      format: (value: any, row: any) => {
        if (Array.isArray(row.organizationIds) && row.organizationIds.length >= 4) {
          return `(${row.organizationIds.length}) Teams`
        }

        return Array.isArray(row.organizationIds)
          ? row.organizationIds.map((id: string) => organizationMap[id]).join(', ')
          : organizationMap[row.organizationIds]
      },
    },
    { id: 'position', label: 'Position', minWidth: 170 },
    {
      id: 'variable_percent',
      label: 'Variable Compensation',
      minWidth: 180,
      format: (value: any, row: any) => wholePercentFormatter.format(row.variablePercent),
    },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 180,
      format: (templateId: string, row: any) => {
        const isPeriodClosed = closedPeriodIds.includes(row.periodId)

        return (
          <Box display="flex">
            <Tooltip title="Duplicate Template">
              <IconButton
                color="primary"
                onClick={() =>
                  startTransition(() => {
                    navigate(`/admin/templates/${templateId}/copy`)
                  })
                }
              >
                <CopyOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={() =>
                  startTransition(() => {
                    navigate(`/admin/templates/${templateId}/edit`)
                  })
                }
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
            {withCreateButtons && (
              <Tooltip
                title={isPeriodClosed ? 'Cannot create plan - period is closed' : 'Create Plan from this Template'}
              >
                <span>
                  <IconButton
                    color="primary"
                    onClick={() => navigate(`/create-comp-plan?templateId=${templateId}`)}
                    disabled={isPeriodClosed}
                  >
                    <PlusCircleOutlined />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Delete Template">
              <IconButton color="error" onClick={() => handleDeleteClick(templateId, row.label)}>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  const handleDeleteClick = (templateId: string, templateName: string) => {
    setTemplateToDelete({ id: templateId, name: templateName })
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    if (templateToDelete && token) {
      deleteTemplate(token, templateToDelete.id)
        .then(() => {
          setDeleteDialogOpen(false)
          setTemplateToDelete(null)
          refetch() // Trigger a re-fetch of the templates
        })
        .catch((error) => {
          console.error('Failed to delete template:', error)
          // Handle error (e.g., show error message to user)
        })
    }
  }

  useEffect(() => {
    if (profile) {
      const org = profile.roles.find((role) => role.organization.rootOrganizationId != null)
      if (org) {
        setRootOrganizationId(org.organization.rootOrganizationId!)
      }
    }
  }, [profile])

  useEffect(() => {
    if (periods?.periods && selectedPeriods.length === 0) {
      const allPeriodIds = periods.periods.flatMap((period) => {
        const idLabelMap = createIdLabelMap(period)
        return Object.keys(idLabelMap)
      })
      setSelectedPeriods(allPeriodIds)
    }
  }, [periods])

  return (
    <>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 3 }}>
          <SearchInput
            id="template-search-input"
            key="template-search-input"
            onSearch={handleSearch}
            initialValue={search}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 3 }}></Grid>
        <Grid size={{ xs: 6 }}>
          <Box display="flex" gap={2} justifyContent="flex-end" alignItems="center">
            {isLoadingPeriods ? (
              <CircularProgress size={20} />
            ) : (
              <MultiPeriodSelector
                periods={periods != null ? periods.periods : []}
                selectedPeriods={selectedPeriods}
                handleChange={setSelectedPeriods}
                label="Filter Periods"
                labelBackgroundColor={theme.palette.background.default}
                width="200px"
              />
            )}
            <MultiOrganizationSelector
              userProfile={profile!}
              selectedOrganizationIds={selectedOrganizations}
              handleChange={setSelectedOrganizations}
              selectAll
              labelBackgroundColor={theme.palette.background.default}
              width="350px"
            />
            <Tooltip title="Clear Filters">
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedOrganizations([])
                  setSelectedPeriods([])
                  setSearch('')
                }}
              >
                <CloseCircleOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            size={{ xs: 12 }}
            style={{
              display: 'flex', // Enables flexbox
              justifyContent: 'center', // Centers horizontally
              alignItems: 'center', // Centers vertically
              height: '100%', // Takes full height of the container
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          {/* table */}
          <TableContainer
            ref={tableContainerRef}
            sx={{
              maxHeight: 'calc(100vh - 400px)',
              minHeight: '300px',
              width: '100%',
            }}
          >
            <Table
              key="templates-table"
              stickyHeader
              size="small"
              aria-label="sticky table"
              sx={{
                minWidth: '1030px',
                width: '100%',
                tableLayout: 'auto',
              }}
            >
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      id={`cell-col-${column.id || ''}`}
                      sx={{
                        minWidth: column.minWidth,
                        position: 'sticky !important',
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      {column.id !== 'label' && column.id !== 'position' ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          active={sort === column.id}
                          direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.length > 0 ? (
                  templates.map((row: KeyedObject) => (
                    <TableRow
                      id={`table-row-${row.id}`}
                      sx={{ py: 3 }}
                      role="checkbox"
                      tabIndex={-1}
                      key={`table-row-${row.id}`}
                    >
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell
                            id={`cell-${column.id}`}
                            key={`cell-${column.id}`}
                            align={column.align}
                            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                          >
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{ px: '20px' }}>
            {withCreateButtons ? (
              <AnimateButton>
                <Tooltip title="Create a new template">
                  <Button
                    variant="contained"
                    startIcon={<PlusOutlined />}
                    color="primary"
                    onClick={() =>
                      startTransition(() => {
                        navigate('/admin/templates/create')
                      })
                    }
                  >
                    Template
                  </Button>
                </Tooltip>
              </AnimateButton>
            ) : (
              <div />
            )}
            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={templates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-template-dialog"
        aria-describedby="delete-template-description"
      >
        <DialogTitle id="delete-template-dialog">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-template-description">
            Are you sure you want to delete{' '}
            <Typography component="span" color="primary" fontWeight="bold" display="inline">
              {templateToDelete?.name}
            </Typography>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TemplatesTable
