import type { SxProps } from '@mui/system'
import type { To } from 'history'

// material-ui
import { ButtonBase } from '@mui/material'
import { APP_DEFAULT_PATH } from 'config'

import useAuth from 'hooks/useAuth'
import { Link } from 'react-router-dom'
import LogoIcon from './LogoIcon'
// project import
import Logo from './LogoMain'

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean
  isIcon?: boolean
  sx?: SxProps
  to?: To
}

function LogoSection({ reverse, isIcon, sx, to }: Props) {
  const { isLoggedIn } = useAuth()

  return (
    <ButtonBase disableRipple {...(isLoggedIn && { component: Link, to: !to ? APP_DEFAULT_PATH : to, sx })}>
      {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
    </ButtonBase>
  )
}

export default LogoSection
