import type { UserProfile } from 'types/user-profile'
// OrganizationsSection.tsx
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'

import React from 'react'

interface OrganizationsSectionProps {
  profile: UserProfile // Define the prop type
}

const OrganizationsSection: React.FC<OrganizationsSectionProps> = ({ profile }) => {
  const sortedRoles = profile?.roles.slice().sort((a, b) => a.organization.name.localeCompare(b.organization.name))

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Organizations
      </Typography>
      <Paper sx={{ width: '100%', overflowX: 'auto', mb: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRoles.map((roleEntry) => (
              <TableRow key={`${roleEntry.organization.id}-${roleEntry.role.id}`}>
                <TableCell>{roleEntry.organization.name}</TableCell>
                <TableCell>{roleEntry.role.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default OrganizationsSection
