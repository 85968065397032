import type { Theme } from '@mui/material'
import type { FC } from 'react'
import type { ApprovalBatchStatusChange, ApprovalNote, NoteDTO, TimelineItem } from 'types/approvals'
import { EditOutlined } from '@ant-design/icons'
import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import { useSubmitApprovalNote } from 'hooks/useApprovals'
import useAuth from 'hooks/useAuth'
import { useRef, useState, useTransition } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApprovalBatchStatus } from 'types/approvals'
import { ThemeMode } from 'types/config'

interface ApprovalNotesProps {
  batchId: string
  notes: ApprovalNote[]
  userNameMap: Record<string, string>
  history: ApprovalBatchStatusChange[]
  setNotes: (notes: ApprovalNote[]) => void
  isPlanApproval: boolean
  planUserId?: string
  planId?: string
  revision?: number
  isApproved: boolean
  theme: Theme
}

const ApprovalNotes: FC<ApprovalNotesProps> = ({
  batchId,
  notes,
  userNameMap,
  history,
  setNotes,
  isPlanApproval,
  planUserId,
  planId,
  revision,
  isApproved,
  theme,
}) => {
  const navigate = useNavigate()
  const [isPending, startTransition] = useTransition()
  const [newNote, setNewNoteLocal] = useState('')
  const { token } = useAuth()
  const submitNote = useSubmitApprovalNote(token!)
  const notesEndRef = useRef<HTMLDivElement>(null)

  const hoverBackgroundColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.secondary.dark

  const handleAddNote = async () => {
    if (newNote.trim() !== '') {
      const noteDTO: NoteDTO = {
        batchId,
        content: newNote,
      }
      const updatedBatch = await submitNote(noteDTO)
      setNewNoteLocal('') // Clear the textarea after submitting
      setNotes(updatedBatch.notes) // Update notes with the response
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevent the default action to avoid a newline in textarea
      handleAddNote()
    }
  }

  const lookupUserName = (userId: string) => {
    const userName = userNameMap[userId]
    if (!userName) {
      console.warn(`User ID ${userId} not found in userNameMap`)
      return 'System'
    }
    return userName
  }

  const formatDate = (timestamp: string) => {
    return new Date(timestamp).toLocaleDateString()
  }

  // Merge notes and history into unified timeline items, then sort by timestamp
  const timelineItems: TimelineItem[] = [
    ...notes.map((note) => ({
      ...note,
      isNote: true,
      userId: note.authorId,
    })),
    ...history.map((change) => ({
      ...change,
      isNote: false,
      userId: change.changedById, // Ensure userId is correctly mapped
    })),
  ].sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())

  // Filter out redundant "item updated" status changes and blank action updates
  const filteredTimelineItems = timelineItems
    .filter((item, index, array) => {
      if (index === 0) return true
      const prevItem = array[index - 1]
      return !(
        (!item.isNote &&
          item.newStatus === ApprovalBatchStatus.ITEM_UPDATED &&
          prevItem.isNote &&
          prevItem.content &&
          prevItem.content.includes('updated compensation plan')) ||
        (!item.isNote &&
          item.newStatus === ApprovalBatchStatus.ITEM_UPDATED &&
          (!item.action || (item.action as unknown as string) === ''))
      )
    })
    .filter((item, index, array) => {
      if (index === 0) return true
      return !(item.newStatus === ApprovalBatchStatus.ITEM_UPDATED && item.oldStatus == ApprovalBatchStatus.PENDING)
    })

  const handleEditPlan = () => {
    startTransition(() => {
      navigate(`/create-comp-plan?userId=${planUserId}&planId=${planId}&revision=${revision}`)
    })
  }

  return (
    <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
      {isPlanApproval && (
        <Button
          variant="text"
          fullWidth
          sx={{ mb: 2, '&:hover': { backgroundColor: hoverBackgroundColor } }}
          onClick={handleEditPlan}
          disabled={isPending}
          startIcon={<EditOutlined />}
        >
          {isPending ? 'Loading...' : 'Edit Plan'}
        </Button>
      )}
      {filteredTimelineItems.map((item, index) => {
        const isNote = item.isNote
        const authorName = isNote ? lookupUserName(item.userId ?? '') : 'System'
        const formattedTimestamp = formatDate(item.timestamp)
        const content = isNote ? item.content : `Status changed to ${item.newStatus}`

        if (isNote && content) {
          const isPlanCreationNote = content.includes('created compensation plan')
          const isPlanUpdateNote = content.includes('updated compensation plan')

          if (isPlanCreationNote || isPlanUpdateNote) {
            return (
              <Box key={index}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2} mr={2}>
                  <Box textAlign="right">
                    <Typography variant="body2" color="textSecondary">
                      {content}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formattedTimestamp}
                    </Typography>
                  </Box>
                </Box>
                {index < filteredTimelineItems.length - 1 && !filteredTimelineItems[index + 1].isNote && (
                  <Divider sx={{ my: 2 }} />
                )}
              </Box>
            )
          }

          return (
            <Paper sx={{ p: 1.5, mb: 2 }} key={index} elevation={1}>
              <Box mb={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  {authorName}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="body1">{content}</Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="caption" color="textSecondary">
                  {formattedTimestamp}
                </Typography>
              </Box>
            </Paper>
          )
        } else {
          let actionText = ''

          switch (item.newStatus) {
            case ApprovalBatchStatus.INITIAL:
              actionText = 'Ready for review'
              break
            case ApprovalBatchStatus.PENDING:
              actionText =
                item.action === 'approved'
                  ? `Approved by ${lookupUserName(item.changedById ?? '')}`
                  : 'Items Pending Approval'
              break
            case ApprovalBatchStatus.APPROVED:
              actionText = `Approved by ${lookupUserName(item.changedById ?? '')}`
              break
            case ApprovalBatchStatus.REJECTED:
              actionText = `Rejected by ${lookupUserName(item.changedById ?? '')}`
              break
            case ApprovalBatchStatus.INVALID:
              actionText = 'Batch no longer valid'
              break
            case ApprovalBatchStatus.CHANGES_REQUESTED:
              actionText = `Changes requested by ${lookupUserName(item.changedById ?? '')}`
              break
            case ApprovalBatchStatus.ITEM_UPDATED:
              actionText = 'Item updated'
              break
            default:
              actionText = `Status changed to ${item.newStatus}`
              break
          }

          return (
            <Box key={index}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2} mr={2}>
                <Box textAlign="right">
                  <Typography variant="body2" color="textSecondary">
                    {actionText}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {formattedTimestamp}
                  </Typography>
                </Box>
              </Box>
              {index < filteredTimelineItems.length - 1 && filteredTimelineItems[index + 1].isNote && (
                <Divider sx={{ my: 2 }} />
              )}
            </Box>
          )
        }
      })}
      <div ref={notesEndRef} />
      {!isApproved && (
        <TextField
          multiline
          minRows={3}
          fullWidth
          variant="outlined"
          value={newNote}
          onChange={(e) => setNewNoteLocal(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Add a new note..."
          InputProps={{
            sx: { padding: '8px' }, // Adjust the padding value as needed
          }}
        />
      )}
    </div>
  )
}

export default ApprovalNotes
