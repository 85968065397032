import type { SelectChangeEvent } from '@mui/material'
import type { FC } from 'react'
import type { Period } from 'types/periods'
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  useTheme,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SimpleTitle from 'components/SimpleTitle'
import { postWithTokenWithStatus } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { usePeriods } from 'hooks/usePeriods'
import React, { startTransition, useEffect, useMemo, useState } from 'react'
import { createIdToPeriodFromList } from 'types/periods'

const DownloadForms: FC = () => {
  const { token, profile } = useAuth()
  const theme = useTheme()
  const [formType, setFormType] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [rootPeriodId, setRootPeriodId] = useState<string>('')
  const [periods, setPeriods] = useState<Period[]>([])
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [periodMap, setPeriodMap] = useState<{ [key: string]: Period }>({})

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    rootOrganizationId,
    !!token && selectedOrganizations.length > 0 && rootOrganizationId !== ''
  )

  useEffect(() => {
    if (fetchedPeriods) {
      setPeriods(fetchedPeriods.periods || [])
      if (fetchedPeriods.periods.length > 0) {
        setRootPeriodId(fetchedPeriods.periods[0].id!)

        setPeriodMap(createIdToPeriodFromList(fetchedPeriods.periods))
      }
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (profile && profile.roles && profile.roles.length > 0 && rootOrganizationId === '') {
      const orgId = profile.roles[0].organization.rootOrganizationId
      if (orgId) {
        setRootOrganizationId(orgId)
      }
    }
  }, [profile])

  useEffect(() => {
    if (selectedOrganizations.length === 0) {
      setSelectedPeriods([])
    }
  }, [selectedOrganizations])

  const handleFormTypeChange = (event: SelectChangeEvent) => {
    setFormType(event.target.value as string)
  }

  const handleOrganizationChange = (selected: string[]) => {
    startTransition(() => {
      setSelectedPeriods([])
      setSelectedOrganizations(selected)
    })
  }

  const handlePeriodChange = (selected: string[]) => {
    setSelectedPeriods(selected)
    if (selected.length > 0) {
      const rootPeriodId = periodMap[selected[0]]?.rootPeriodId
      if (rootPeriodId) {
        setRootPeriodId(rootPeriodId)
      }
    }
  }

  const requiredSelectors = useMemo(() => {
    const map: { [key: string]: string[] } = {
      'import-team-structure': [],
      'import-users': [],
      'import-components': [],
      'import-targets': ['organization', 'period'],
      'import-team-actuals': ['organization', 'period'],
      'import-employee-actuals': ['organization', 'period'],
    }
    return map[formType] || []
  }, [formType])

  const handleSubmit = async () => {
    try {
      const formTypeMap: { [key: string]: string } = {
        'import-team-structure': 'TEAM_STRUCTURE',
        'import-users': 'USERS',
        'import-components': 'COMPONENTS',
        'import-targets': 'TARGETS',
        'import-team-actuals': 'TEAM_ACTUALS',
        'import-employee-actuals': 'EMPLOYEE_ACTUALS',
      }

      const requestBody: any = {
        formType: formTypeMap[formType],
        rootOrganizationId,
      }

      if (requiredSelectors.includes('organization')) {
        requestBody.organizationIds = selectedOrganizations
      }

      if (requiredSelectors.includes('period')) {
        requestBody.periodIds = selectedPeriods
        requestBody.rootPeriodId = rootPeriodId
      }

      const { data, status } = await postWithTokenWithStatus('forms/download', token!, requestBody)

      if (status === 200 && data.downloadUrl) {
        try {
          // Create a temporary anchor element
          const link = document.createElement('a')
          link.href = data.downloadUrl
          link.setAttribute('download', '') // This will use the filename provided by the server

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          setSnackbar({
            open: true,
            message: 'Download started. Please check your downloads folder.',
            severity: 'success',
          })
        } catch (downloadError) {
          console.error('Error initiating download:', downloadError)
          setSnackbar({ open: true, message: 'Error initiating download', severity: 'error' })
        }
      } else {
        setSnackbar({ open: true, message: data.error || 'Unexpected response from server', severity: 'error' })
      }
    } catch (error) {
      console.error('Error downloading form:', error)
      setSnackbar({ open: true, message: 'Error downloading form', severity: 'error' })
    }
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 150px)' }}>
      <SimpleTitle title="Download Forms" />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={4} sx={{ width: '50%', mt: 2, mb: 4 }} justifyContent="center">
          <Grid size={12}>
            <FormControl variant="outlined" sx={{ width: '100%' }}>
              <InputLabel shrink>Form Type</InputLabel>
              <Select
                labelId="form-type-label"
                id="form-type-select"
                value={formType}
                variant="outlined"
                label="Form Type"
                onChange={handleFormTypeChange}
                displayEmpty
                sx={{ borderRadius: '21px' }}
              >
                <MenuItem value="import-team-structure">Import Team Structure</MenuItem>
                <MenuItem value="import-users">Import Users</MenuItem>
                <MenuItem value="import-components">Import Components</MenuItem>
                <MenuItem value="import-targets">Import Targets</MenuItem>
                <MenuItem value="import-team-actuals">Import Team Actuals</MenuItem>
                <MenuItem value="import-employee-actuals">Import Employee Actuals</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {requiredSelectors.includes('organization') && (
            <Grid size={12}>
              <MultiOrganizationSelector
                userProfile={profile!}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
                variant="outlined"
                labelBackgroundColor={theme.palette.background.default}
              />
            </Grid>
          )}

          {requiredSelectors.includes('period') && (
            <Grid size={12}>
              <MultiPeriodSelector
                key="period-selector"
                periods={periods}
                selectedPeriods={selectedPeriods}
                handleChange={handlePeriodChange}
                disabled={isLoadingPeriods || selectedOrganizations.length === 0 || periods.length === 0}
                label="Selected Periods"
                variant="outlined"
                labelBackgroundColor={theme.palette.background.default}
                onlySingleRootPeriodSelection
              />
            </Grid>
          )}

          <Grid size={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                !formType ||
                requiredSelectors.some((selector) => {
                  if (selector === 'organization') return selectedOrganizations.length === 0
                  if (selector === 'period') return selectedPeriods.length === 0
                  return false
                }) ||
                rootOrganizationId === ''
              }
            >
              Download Form
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default DownloadForms
