import type { ExpandedCompensationPlan } from 'types/comp-plan'
import type { LedgerAccount } from 'types/ledger-account'
import type { User } from 'types/user'
import { Grid2 as Grid } from '@mui/material'
import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
import PlanAllocationsCard from 'components/Plan/PlanAllocationsCards'
import PlanTargetActualsCard from 'components/Plan/PlanTargetActualsCard'
import PlanTargetsCard from 'components/Plan/PlanTargetsCard'

function CompPlanContent({
  user,
  compPlan,
  ledgerAccounts,
}: {
  user: User | undefined
  compPlan: ExpandedCompensationPlan
  ledgerAccounts: LedgerAccount[] | undefined
}) {
  const componentsCard =
    compPlan.metrics != null && compPlan.metrics.length > 0 ? (
      <PlanTargetActualsCard period={compPlan.period} plan={compPlan} />
    ) : (
      <PlanTargetsCard period={compPlan.period} plan={compPlan} />
    )

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12 }}>
        <CompensationSummaryCard
          key="compensation-summary-card"
          name={`${user?.firstName} ${user?.lastName}`}
          position={user?.position}
          effectiveDate={compPlan.plan.effectiveDate}
          basePlus={compPlan?.plan.basePlus || false}
          variablePercent={compPlan?.plan.variablePercent || 0}
          baseSalary={compPlan?.plan.baseSalary || 0}
          variableCap={compPlan?.plan.variableCap || -0}
          periodProration={compPlan?.plan.periodProration || 1.0}
          period={compPlan.period}
          periodId={compPlan.plan.periodId}
        />
      </Grid>

      <Grid size={{ xs: 12 }}>{componentsCard}</Grid>

      <Grid size={{ xs: 12 }}>
        <PlanAllocationsCard key="plan-allocations-card" plan={compPlan} ledgerAccounts={ledgerAccounts || []} />
      </Grid>
    </Grid>
  )
}

export default CompPlanContent
