import type { FC } from 'react'
import type { OrganizationSettingDTO } from 'types/organization'
import { Box, Chip, Grid2 as Grid, Slider, Switch, TextField, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import { NumericField } from 'components/NumericField'
import { useEffect, useState } from 'react'
import { ComponentType } from 'types/components'

interface TemplateDefaultsProps {
  settings: OrganizationSettingDTO[]
  handleChange: (index: number, value: string) => void
  currency: string
  currencyDecimals: number
}

const TemplateDefaults: FC<TemplateDefaultsProps> = ({ settings, handleChange, currency, currencyDecimals }) => {
  const getSetting = (name: string) => settings.find((s) => s.settingName === name)?.settingValue || ''

  const [variableCompensation, setVariableCompensation] = useState<number[]>([0.1, 0.2, 0.3])
  const [maxPayout, setMaxPayout] = useState<number[]>([1.2, 1.5, 2.0])
  const [defaultTables, setDefaultTables] = useState<string[]>([])

  useEffect(() => {
    const varComp = getSetting('Default Variable Compensation').split(',').map(Number)
    const maxPay = getSetting('Default Max Payout').split(',').map(Number)
    if (varComp.length === 3) setVariableCompensation(varComp)
    if (maxPay.length === 3) setMaxPayout(maxPay)

    // Add this block to initialize defaultTables
    const tables = getSetting('Default Tables').split(',').filter(Boolean)
    setDefaultTables(tables)
  }, [settings])

  const [newTable, setNewTable] = useState('')

  const handleVariableCompChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const decimalValues = newValue.map((v) => v / 100)
      setVariableCompensation(decimalValues)
      handleChange(
        settings.findIndex((s) => s.settingName === 'Default Variable Compensation'),
        decimalValues.join(',')
      )
    }
  }

  const handleMaxPayoutChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const decimalValues = newValue.map((v) => v / 100)
      setMaxPayout(decimalValues)
      handleChange(
        settings.findIndex((s) => s.settingName === 'Default Max Payout'),
        decimalValues.join(',')
      )
    }
  }

  const handleAddTable = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newTable.trim()) {
      const updatedTables = [...defaultTables, newTable.trim()]
      setDefaultTables(updatedTables)
      handleChange(
        settings.findIndex((s) => s.settingName === 'Default Tables'),
        updatedTables.join(',')
      )
      setNewTable('')
    }
  }

  const handleDeleteTable = (tableToDelete: string) => {
    const updatedTables = defaultTables.filter((table) => table !== tableToDelete)
    setDefaultTables(updatedTables)
    handleChange(
      settings.findIndex((s) => s.settingName === 'Default Tables'),
      updatedTables.join(',')
    )
  }

  const formatPercentage = (value: number) => `${(value * 100).toFixed(0)}%`

  // Add this new function to format the tooltip values
  const formatTooltipPercentage = (value: number) => `${value.toFixed(0)}%`

  return (
    <Paper elevation={0} sx={{ mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        Template Defaults
      </Typography>
      <Grid container spacing={3} p={2}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Box display="flex" alignItems="center">
            <Typography>Default Base Plus</Typography>
            <Switch
              checked={getSetting('Default Base Plus') === 'true'}
              onChange={(e) =>
                handleChange(
                  settings.findIndex((s) => s.settingName === 'Default Base Plus'),
                  e.target.checked.toString()
                )
              }
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <NumericField
            componentFormat={ComponentType.COMPONENT_TYPE_CURRENCY}
            label="Default Example Compensation"
            value={Number(getSetting('Default Example Compensation')) || 100000}
            onValueChange={(value: any) =>
              handleChange(
                settings.findIndex((s) => s.settingName === 'Default Example Compensation'),
                value
              )
            }
            variant="outlined"
            pill
            shrink
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography gutterBottom>Default Variable Compensation</Typography>
          <Grid container justifyContent="left" spacing={2}>
            <Grid size={{ xs: 12 }}>
              <Slider
                value={variableCompensation.map((v) => v * 100)}
                onChange={handleVariableCompChange}
                valueLabelDisplay="auto"
                valueLabelFormat={formatTooltipPercentage} // Add this line
                min={0}
                max={100}
                marks={[
                  { value: 0, label: '0%' },
                  { value: 100, label: '100%' },
                  { value: variableCompensation[0] * 100, label: formatPercentage(variableCompensation[0]) },
                  { value: variableCompensation[2] * 100, label: formatPercentage(variableCompensation[2]) },
                  { value: variableCompensation[1] * 100, label: formatPercentage(variableCompensation[1]) },
                ]}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography gutterBottom>Default Max Payout</Typography>
          <Grid container justifyContent="left" spacing={2}>
            <Grid size={{ xs: 12 }}>
              <Slider
                value={maxPayout.map((v) => v * 100)}
                onChange={handleMaxPayoutChange}
                valueLabelDisplay="auto"
                valueLabelFormat={formatTooltipPercentage} // Add this line
                min={100}
                max={300}
                marks={[
                  { value: 100, label: '100%' },
                  { value: 300, label: '300%' },
                  { value: maxPayout[0] * 100, label: formatPercentage(maxPayout[0]) },
                  { value: maxPayout[2] * 100, label: formatPercentage(maxPayout[2]) },
                  { value: maxPayout[1] * 100, label: formatPercentage(maxPayout[1]) },
                ]}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography gutterBottom>Default Tables</Typography>
          <TextField
            fullWidth
            variant="standard"
            value={newTable}
            onChange={(e) => setNewTable(e.target.value)}
            onKeyDown={handleAddTable}
            placeholder="Add a table and press Enter"
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {defaultTables.map((table) => (
              <Chip key={table} label={table} onDelete={() => handleDeleteTable(table)} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TemplateDefaults
