import type { SelectChangeEvent } from '@mui/material'
import type { FC } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import { postWithTokenWithStatus } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import React, { useEffect, useState } from 'react'

const UploadForms: FC = () => {
  const [formType, setFormType] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')
  const [snackbar, setSnackbar] = useState<{
    open: boolean
    message: string
    details?: string[] | string
    severity: 'success' | 'error'
  }>({
    open: false,
    message: '',
    severity: 'success',
  })

  const { token, profile } = useAuth()

  useEffect(() => {
    if (profile && profile.roles && profile.roles.length > 0 && rootOrganizationId === '') {
      const orgId = profile.roles[0].organization.rootOrganizationId
      if (orgId) {
        setRootOrganizationId(orgId)
      }
    }
  }, [profile, rootOrganizationId])

  const handleFormTypeChange = (event: SelectChangeEvent) => {
    setFormType(event.target.value as string)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0])
    }
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0])
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleSubmit = async () => {
    if (!file || !formType || !rootOrganizationId) return

    try {
      const formTypeMap: { [key: string]: string } = {
        'import-team-structure': 'TEAM_STRUCTURE',
        'import-users': 'USERS',
        'import-components': 'COMPONENTS',
        'import-targets': 'TARGETS',
        'import-team-actuals': 'TEAM_ACTUALS',
        'import-employee-actuals': 'EMPLOYEE_ACTUALS',
      }

      // Read file as base64
      const base64Content = await new Promise<string>((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64 = reader.result as string
          // Remove the "data:*/*;base64," prefix
          resolve(base64.split(',')[1])
        }
        reader.readAsDataURL(file)
      })

      const { data, status } = await postWithTokenWithStatus('forms/upload', token!, {
        formType: formTypeMap[formType],
        rootOrganizationId,
        content: base64Content,
      })

      if (status >= 400) {
        throw new Error(data.error || 'Failed to upload form', { cause: data.details })
      }

      // Clear the form after successful upload
      setFile(null)
      setFormType('')

      setSnackbar({
        open: true,
        message: 'File uploaded successfully',
        severity: 'success',
      })
    } catch (error) {
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to upload form',
        details: error instanceof Error ? (error.cause as string[] | string) : undefined,
        severity: 'error',
      })
    }
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 150px)' }}>
      <SimpleTitle title="Upload Forms" />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={4} sx={{ width: '50%', mt: 2, mb: 4 }} justifyContent="center">
          <Grid size={{ xs: 12 }}>
            <FormControl variant="outlined" sx={{ width: '100%' }}>
              <InputLabel shrink>Form Type</InputLabel>
              <Select
                labelId="form-type-label"
                id="form-type-select"
                value={formType}
                variant="outlined"
                label="Form Type"
                onChange={handleFormTypeChange}
                displayEmpty
                sx={{ borderRadius: '21px' }}
              >
                <MenuItem value="import-team-structure">Import Team Structure</MenuItem>
                <MenuItem value="import-users">Import Users</MenuItem>
                <MenuItem value="import-components">Import Components</MenuItem>
                <MenuItem value="import-targets">Import Targets</MenuItem>
                <MenuItem value="import-team-actuals">Import Team Actuals</MenuItem>
                <MenuItem value="import-employee-actuals">Import Employee Actuals</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Box
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '15px',
                p: 4,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#f0f0f0' },
                width: '100%',
              }}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <input
                type="file"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".xlsx,.xls"
              />
              <label htmlFor="file-upload">
                <Button variant="outlined" component="span" startIcon={<UploadOutlined />} sx={{ mb: 2 }}>
                  Select File
                </Button>
              </label>
              <Typography>
                {file ? `Selected file: ${file.name}` : 'Drag and drop a file here, or click to select a file'}
              </Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" disabled={!formType || !file} onClick={handleSubmit}>
              Upload Form
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          <Typography>{snackbar.message}</Typography>
          {snackbar.details && (
            <Box sx={{ mt: 1 }}>
              {Array.isArray(snackbar.details) ? (
                snackbar.details.map((detail, index) => (
                  <Typography key={index} variant="body2" sx={{ mt: 0.5 }}>
                    • {detail}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                  • {snackbar.details}
                </Typography>
              )}
            </Box>
          )}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default UploadForms
