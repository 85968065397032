import type { FC } from 'react'
import { Box } from '@mui/material'
import React from 'react'

interface CardFooterProps {
  children: React.ReactNode
}

const CardFooter: FC<CardFooterProps> = ({ children }) => (
  <Box
    sx={{
      borderTop: '2px solid',
      borderColor: 'divider',
      py: 1,
      px: 1,
    }}
  >
    {children}
  </Box>
)

export default CardFooter
