import type { Accrual, AccrualLedgerItem } from './accruals'
import type { ID, Label, PaginationInfo, Timestamps } from './common'
import type { CompensationPlan } from './comp-plan'
import type { Component, Metric, Target } from './components'
import type { Period } from './periods'

// Enums for approval statuses and types
export enum ApprovalBatchStatus {
  ALL = 'all',
  INITIAL = 'initial',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  INVALID = 'invalid',
  CHANGES_REQUESTED = 'changes_requested',
  ITEM_UPDATED = 'item_updated',
}

export enum ApprovalType {
  PLAN = 'plan',
  PERIOD_COMPONENT = 'metric',
  ACCRUAL = 'accrual',
  TARGET = 'target',
  ACCRUAL_LEDGER = 'accrual_ledger',
}

export enum ApprovalBatchType {
  ALL = 'all',
  PLAN = 'plan',
  PERIOD_COMPONENT = 'metric',
  ACCRUAL_PROGRESS = 'accrual',
  TARGET = 'target',
  ACCRUAL_LEDGER = 'accrual_ledger',
}

// Metadata interfaces for each approval type
export interface PlanMetadata {
  id: string
  revision: string
}

export interface PeriodMetadata {
  periodId: string
  rootPeriodId: string
  periodLabel: string
  rootPeriodLabel: string
  startDate: Date
  endDate: Date
}

export interface PeriodComponentMetadata {
  componentId: string
  rootPeriodId: string
  periodId: string
  userId: string
  revision: string
}

export interface TargetMetadata {
  componentId: string
  rootPeriodId: string
  periodId: string
  userId: string
  revision: string
}

export interface AccrualMetadata {
  planId: string
  revision: string
  rowId: string
}

// Union type for ApprovalItem metadata
export type ApprovalItemMetadata = PlanMetadata | PeriodComponentMetadata | TargetMetadata | AccrualMetadata

// ApprovalItem interface
export interface ApprovalItem {
  approvalType: ApprovalType
  metadata: ApprovalItemMetadata
}

// ApprovalNote interface
export interface ApprovalNote extends ID {
  id: string
  content: string
  authorId: string
  timestamp: string
}

export interface ApprovalBatchResponse {
  batches: ApprovalBatch[]
  paginationInfo: PaginationInfo
}

// ApprovalBatch interface
export interface ApprovalBatch extends ID, Timestamps, Label {
  approvalChainId: string
  status: ApprovalBatchStatus
  batchType: ApprovalBatchType
  pendingApproverId: string
  items: ApprovalItem[]
  notes: ApprovalNote[]
}

// ApprovalBatchStatusChange interface
export interface ApprovalBatchStatusChange extends ID {
  id: string
  oldStatus: ApprovalBatchStatus
  newStatus: ApprovalBatchStatus
  action: ApprovalBatchStatus
  changedById: string
  timestamp: string // ISO 8601 format
}

// ApprovalChainStep interface
export interface ApprovalChainStep extends ID, Timestamps {
  chainId: string
  stepOrder: number
  approverId: string
}

// ApprovalChainStepDTO interface
export interface ApprovalChainStepDTO {
  stepOrder: number
  approverId: string
}

// ApprovalChainDTO interface
export interface ApprovalChainDTO {
  name: string
  rootOrganizationId: string
  organizationId: string
  steps: ApprovalChainStepDTO[]
}

// ApprovalBatchDTO interface
export interface ApprovalBatchDTO {
  approvalChainId: string
  status: ApprovalBatchStatus
  pendingApproverId: string
  items: ApprovalItem[]
  notes: ApprovalNote[]
}

// ApprovalChain interface
export interface ApprovalChain extends ID, Timestamps {
  name: string
  rootOrganizationId: string
  organizationId: string
}

export interface ExpandedApprovalChain extends ID, Timestamps {
  name: string
  rootOrganizationId: string
  organizationId: string
  stepIds: string[]
  users: UserMeta[]
}

export interface ApprovalBatchStatusChange extends ID {
  approvalBatchId: string
  oldStatus: ApprovalBatchStatus
  newStatus: ApprovalBatchStatus
  changedById: string
  timestamp: string
}

export interface UserMeta {
  id: string
  email: string
  firstName: string
  lastName: string
  position: string
}

export interface ApprovalBatchQueryParams {
  batchTypes: string[]
  page: number
  perPage: number
  status: string
  label: string
}

export interface OrganizationMeta {
  id: string
  name: string
}

// ApprovalItem interface
export interface ExpandedApprovalItem {
  approvalType: ApprovalType
  metadata: ApprovalItemMetadata
  plan?: CompensationPlan
  period?: Period
  components?: Component[]
  targets?: Target[]
  periodComponents?: Metric[]
  user?: UserMeta
  periodLabel?: string
  rootPeriodLabel?: string
  organization?: OrganizationMeta
  accrual?: Accrual
  accrualLedgerItem?: AccrualLedgerItem
}

export interface ExpandedApprovalBatch extends ID, Timestamps, Label {
  approvalChainId: string
  approvalChain: ExpandedApprovalChain
  status: ApprovalBatchStatus
  batchType: ApprovalBatchType
  pendingApproverId: string
  items: ExpandedApprovalItem[]
  notes: ApprovalNote[]
  history: ApprovalBatchStatusChange[]
}

export interface NoteDTO {
  batchId: string
  content: string
}

export interface ApprovalBatchStatusRequest {
  approvalBatchId: string
  status: ApprovalBatchStatus
}

export interface TimelineItem {
  id: string
  content?: string
  userId?: string
  timestamp: string
  oldStatus?: ApprovalBatchStatus
  newStatus?: ApprovalBatchStatus
  action?: ApprovalBatchStatus
  changedById?: string
  isNote: boolean
}
