import type { AuthContextProps } from 'contexts/AuthContext'
import { AuthContext } from 'contexts/AuthContext'
import { useContext } from 'react'

// Custom hook for convenience
function useAuth(): AuthContextProps {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export default useAuth
