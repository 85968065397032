import { DownOutlined, UpOutlined, UserOutlined } from '@ant-design/icons'
import { Box, Popover, TextField, Typography } from '@mui/material'
import type { Theme } from '@mui/system'
import { styled } from '@mui/system'
import Paper from 'components/@extended/Paper'
import SpinnerButton from 'components/SpinnerButton'
import { useCreateOrganization } from 'hooks/useOrganizations'
import debounce from 'lodash/debounce'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react'
import type { CustomNodeElementProps, RawNodeDatum, TreeNodeDatum } from 'react-d3-tree'
import Tree from 'react-d3-tree'
import type { CreateOrganizationDTO, ExpandedOrganizationHierarchy, Organization } from 'types/organization'

interface TreeNode extends Organization, Omit<TreeNodeDatum, 'children'> {
  children?: TreeNode[]
  _children?: TreeNode[]
  __rd3t: any
  userCount: number
}

interface OrganizationTreeProps {
  organizationHierarchy: ExpandedOrganizationHierarchy
  setSelectedOrganization: (id: string) => void
  theme: Theme
  token: string
  onOrganizationCreated: (organizationDTO: CreateOrganizationDTO) => Promise<void>
  isSubmitting: boolean
}

const NodeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  height: '100%',
}))

const OrganizationTree: FC<OrganizationTreeProps> = ({
  organizationHierarchy,
  setSelectedOrganization,
  theme,
  token,
  onOrganizationCreated,
  isSubmitting,
}) => {
  const [treeData, setTreeData] = useState<TreeNode | null>(null)
  const [collapsedNodes, setCollapsedNodes] = useState<Set<string>>(new Set())
  const [newOrgName, setNewOrgName] = useState('')
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null)
  const [translate, setTranslate] = useState({ x: 350, y: 100 })
  const treeContainerRef = useRef<HTMLDivElement>(null)
  const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null)
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [selectedNodeName, setSelectedNodeName] = useState<string>('')
  const [zoomLevel, setZoomLevel] = useState(1)
  const [isPending, startTransition] = useTransition()

  const { error: createError } = useCreateOrganization(token)

  const updateTranslate = useCallback(() => {
    if (treeContainerRef.current) {
      const { width, height } = treeContainerRef.current.getBoundingClientRect()
      setTranslate({ x: width / 2, y: height / 4 })
    }
  }, [])

  useEffect(() => {
    updateTranslate()
    window.addEventListener('resize', updateTranslate)
    return () => window.removeEventListener('resize', updateTranslate)
  }, [updateTranslate])

  useEffect(() => {
    if (treeData) {
      updateTranslate()
    }
  }, [treeData, updateTranslate])

  useEffect(() => {
    const convertToTreeNode = (org: ExpandedOrganizationHierarchy): TreeNode => {
      return {
        ...org.organization,
        __rd3t: undefined,
        userCount: org.users.length,
        children: org.children.map(convertToTreeNode),
      }
    }

    if (organizationHierarchy) {
      const newTreeData = convertToTreeNode(organizationHierarchy)
      setTreeData(newTreeData)
    }
  }, [organizationHierarchy])

  useEffect(() => {
    if (treeData) {
      const nodeCount = countNodes(treeData)
      const zoomFactor = Math.max(0.3, 1 - Math.log(nodeCount) / 10)
      setZoomLevel(zoomFactor)
    }
  }, [treeData])

  const countNodes = (node: RawNodeDatum): number => {
    if (!node.children) return 1
    return 1 + node.children.reduce((sum, child) => sum + countNodes(child), 0)
  }

  const handleToggleCollapse = useCallback((nodeId: string, event: React.MouseEvent) => {
    event.stopPropagation()
    setCollapsedNodes((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(nodeId)) {
        newSet.delete(nodeId)
      } else {
        newSet.add(nodeId)
      }
      return newSet
    })
  }, [])

  const handleNodeClick = useCallback(
    (nodeData: CustomNodeElementProps) => {
      const treeNode = nodeData.nodeDatum as TreeNode
      startTransition(() => {
        setSelectedNodeId(treeNode.id)
        setSelectedNodeName(treeNode.name)
        setSelectedOrganization(treeNode.id)
      })

      if ((nodeData as any).__rd3t && (nodeData as any).__rd3t.nodeElement) {
        const nodeElement = (nodeData as any).__rd3t.nodeElement as SVGGElement
        const { left, top, width, height } = nodeElement.getBoundingClientRect()

        setAnchorPosition({
          left: left + width / 2,
          top: top + height + 20,
        })
      } else {
        setAnchorPosition({
          left: window.innerWidth / 2,
          top: 270,
        })
      }
      setIsPopoverOpen(true)
    },
    [setSelectedOrganization]
  )

  const handleClosePopover = () => {
    setAnchorPosition(null)
    setNewOrgName('')
    setIsPopoverOpen(false)
  }

  const handleCreateOrganization = useCallback(
    async (organizationDTO: CreateOrganizationDTO) => {
      if (isSubmitting) {
        console.log('Submission in progress, please wait...')
        return
      }
      await onOrganizationCreated(organizationDTO)
    },
    [isSubmitting, onOrganizationCreated]
  )

  const debouncedHandleCreateOrganization = debounce(handleCreateOrganization, 300, { leading: true, trailing: false })

  const handleAddOrganization = async () => {
    if (newOrgName && selectedNodeId) {
      await debouncedHandleCreateOrganization({
        name: newOrgName,
        parentId: selectedNodeId,
        status: 'CHILD',
      })
      handleClosePopover()
    }
  }

  const handleNodeSelect = useCallback(
    (nodeId: string, nodeName: string) => {
      startTransition(() => {
        setSelectedNodeId(nodeId)
        setSelectedNodeName(nodeName)
        setSelectedOrganization(nodeId)
      })
    },
    [setSelectedOrganization]
  )

  const renderCustomNodeElement = useCallback(
    (rd3tProps: CustomNodeElementProps) => {
      const nodeData = rd3tProps.nodeDatum as TreeNode
      const hasChildren =
        (nodeData.children && nodeData.children.length > 0) || (nodeData._children && nodeData._children.length > 0)
      const isCollapsed = collapsedNodes.has(nodeData.id)
      const childCount = nodeData._children?.length || nodeData.children?.length || 0
      const userCount = nodeData.userCount

      // Calculate width based on team name length
      const minWidth = 200
      const maxWidth = 300
      const width = Math.min(Math.max(nodeData.name.length * 10, minWidth), maxWidth)

      return (
        <foreignObject width={width} height={100} x={-width / 2} y={-50}>
          <NodeContainer onMouseEnter={() => setHoveredNodeId(nodeData.id)} onMouseLeave={() => setHoveredNodeId(null)}>
            <Paper
              sx={{
                pt: 2,
                borderRadius: '8px',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.text.secondary,
                  fontSize: '10px',
                }}
              >
                <UserOutlined style={{ marginRight: '2px' }} />
                {userCount}
              </Box>
              {hasChildren && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    cursor: 'pointer',
                    zIndex: 1,
                    color: theme.palette.text.secondary,
                    opacity: 0.6,
                    padding: '10px',
                  }}
                  onClick={(e) => handleToggleCollapse(nodeData.id, e)}
                >
                  {isCollapsed ? <DownOutlined /> : <UpOutlined />}
                </Box>
              )}
              <Typography
                variant="h6"
                noWrap
                align="center"
                sx={{
                  mb: 0.5,
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                  maxWidth: '90%', // Ensure text doesn't overflow
                }}
                onClick={() => handleNodeSelect(nodeData.id, nodeData.name)}
              >
                {nodeData.name}
              </Typography>
              {hasChildren && isCollapsed ? (
                <Typography variant="caption" color="primary">
                  {childCount} child
                  {childCount !== 1 ? 'ren' : ''}
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  sx={{
                    opacity: hoveredNodeId === nodeData.id ? 1 : 0,
                    transition: 'opacity 0.2s',
                    color: 'primary.main',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleNodeClick(rd3tProps)}
                >
                  + Add Team
                </Typography>
              )}
            </Paper>
          </NodeContainer>
        </foreignObject>
      )
    },
    [hoveredNodeId, collapsedNodes, handleToggleCollapse, handleNodeSelect, handleNodeClick, theme]
  )

  const collapsedTreeData = useMemo(() => {
    const collapseNode = (node: TreeNode): TreeNode => {
      if (collapsedNodes.has(node.id)) {
        return { ...node, _children: node.children, children: undefined }
      }
      return {
        ...node,
        children: node.children?.map(collapseNode),
        _children: undefined,
      }
    }
    return treeData ? collapseNode(treeData) : null
  }, [treeData, collapsedNodes])

  const getPathClass = useCallback(() => {
    // Remove the conditional and always return the same class
    return 'tree-link'
  }, [])

  const treeStyles = useMemo(
    () => ({
      '.tree-link': {
        stroke: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[300],
        strokeWidth: 2,
      },
    }),
    [theme]
  )

  if (!collapsedTreeData) {
    return <Typography>No organization data available</Typography>
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: 'relative',
        height: '100%', // Change from '100vh' to '100%'
        overflow: 'hidden', // Add this to prevent scrolling
        ...treeStyles,
      }}
      ref={treeContainerRef}
    >
      {isPending ? (
        <Typography>Loading...</Typography>
      ) : (
        <Tree
          data={collapsedTreeData}
          orientation="vertical"
          pathFunc="step"
          renderCustomNodeElement={renderCustomNodeElement}
          nodeSize={{ x: 280, y: 100 }}
          separation={{ siblings: 1.1, nonSiblings: 1.5 }}
          translate={translate}
          centeringTransitionDuration={0}
          collapsible={false}
          initialDepth={Infinity}
          zoom={zoomLevel}
          scaleExtent={{ min: 0.1, max: 1 }}
          transitionDuration={0}
          enableLegacyTransitions={false}
          pathClassFunc={getPathClass}
        />
      )}
      <Popover
        open={isPopoverOpen}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition || undefined}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2, width: 250 }}>
          <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
            Create team under{' '}
            <Typography component="span" color="primary" fontWeight="bold">
              {selectedNodeName}
            </Typography>
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            label="Team Name"
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
            margin="normal"
            onKeyPress={(e) => e.key === 'Enter' && handleAddOrganization()}
            inputRef={inputRef}
          />
          {createError && <Typography color="error">{createError.message}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <SpinnerButton
              variant="contained"
              onClick={handleAddOrganization}
              loading={isSubmitting}
              disabled={!newOrgName.trim() || isSubmitting}
            >
              Create Team
            </SpinnerButton>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default OrganizationTree
