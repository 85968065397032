import type { ReactElement } from 'react'
import { useEffect } from 'react'

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

function ScrollTop({ children }: { children: ReactElement | null }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return children || null
}

export default ScrollTop
