import type { ExpandedApprovalItem } from 'types/approvals'
import { CardContent, Divider, Grid2 as Grid, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import React from 'react'

function formatCurrency(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value)
}

interface AccrualTableProps {
  items: ExpandedApprovalItem[]
}

const AccrualTable: React.FC<AccrualTableProps> = ({ items }) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h3" mb={2}>
        Accruals
      </Typography>
      <Paper sx={{ paddingTop: 1, width: '100%', marginBottom: '40px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid size={{ xs: 3 }}>
              <Typography variant="h5">Name</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Typography variant="h5">Account</Typography>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Typography variant="h5">Line Item</Typography>
            </Grid>
            <Grid size={{ xs: 2 }}>
              <Typography variant="h5" align="center">
                Balance
              </Typography>
            </Grid>
          </Grid>
          {items.map((item, index) => (
            <div key={index}>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Divider />
              </div>
              <Grid container spacing={2} alignItems="center">
                <Grid size={{ xs: 3 }}>
                  <Typography>{`${item.accrual?.userFirstName} ${item.accrual?.userLastName}`}</Typography>
                </Grid>
                <Grid size={{ xs: 3 }}>
                  <Typography>{item.accrual?.ledgerAccountLabel}</Typography>
                </Grid>
                <Grid size={{ xs: 4 }}>
                  <Typography>{`${item.accrual?.allocationTableName || ''} | ${item.accrual?.allocationRowName || ''}`}</Typography>
                </Grid>
                <Grid size={{ xs: 2 }}>
                  <Typography align="center">
                    {item.accrual?.payoutOverride || item.accrual?.proratedPayoutAmount
                      ? formatCurrency(item.accrual?.payoutOverride ?? item.accrual?.proratedPayoutAmount ?? 0)
                      : '$0.00'}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ))}
        </CardContent>
      </Paper>
    </div>
  )
}

export default AccrualTable
