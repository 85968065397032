import CreatePlanForEmployee from 'forms/CreatePlanForEmployee'

// project import
import DashboardLayout from 'layout/Dashboard'
import Pages from 'layout/Pages'

import ClosePeriods from 'pages/Admin/Accruals/ClosePeriods'
import Ledger from 'pages/Admin/Accruals/Ledger'
import ManageAccruals from 'pages/Admin/Accruals/ManageAccruals'
import EditApproval from 'pages/Admin/Approvals/EditApproval'
import ManageApprovals from 'pages/Admin/Approvals/ManageApprovals'
import CreateMetrics from 'pages/Admin/Components/CreateMetrics'

import CreateTargets from 'pages/Admin/Components/CreateTargets'
import EditMetric from 'pages/Admin/Components/EditMetric'
import EditTarget from 'pages/Admin/Components/EditTarget'
import CreateComponent from 'pages/Admin/Components/ManageComponents/CreateComponent'
import EditComponent from 'pages/Admin/Components/ManageComponents/EditComponent'
import ManageComponents from 'pages/Admin/Components/ManageComponents/ManageComponents'
import ManageMetrics from 'pages/Admin/Components/ManageComponents/ManageMetrics'
import ManageTargets from 'pages/Admin/Components/ManageComponents/ManageTargets'
import ManageLedgerAccounts from 'pages/Admin/LedgerAccounts/ManageLedgerAccounts'
import ManageOrganizationStructure from 'pages/Admin/Organizations/ManageOrganizationStructure'
import ManagePeriods from 'pages/Admin/Periods'
import ManagePlans from 'pages/Admin/Plans/ManagePlans'
import CopyTemplate from 'pages/Admin/Templates/CopyTemplate'
import CreateTemplate from 'pages/Admin/Templates/CreateTemplate'
import EditTemplate from 'pages/Admin/Templates/EditTemplate'
import ManageTemplates from 'pages/Admin/Templates/ManageTemplates'
import SelectTemplate from 'pages/Admin/Templates/SelectTemplate'
import CreateUser from 'pages/Admin/Users/CreateUser'
import CompensationPlan from 'pages/CompPlan'
import CompPlans from 'pages/CompPlans'
import Dashboard from 'pages/Dashboard'
import EmployeePlanView from 'pages/Dashboard/EmployeePlanView'

import JumpStartWizard from 'pages/JumpStartWizard'
import Organization from 'pages/Organization'
import PendingSetup from 'pages/Organization/PendingSetup.tsx'
import OrganizationSettings from 'pages/OrganizationSettings'
import EditProfile from 'pages/Profile'
import DownloadForms from 'pages/Reporting/DownloadForms'
import RunReports from 'pages/Reporting/RunReports'
import UploadForms from 'pages/Reporting/UploadForms'
import Templates from 'pages/Templates'

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
          protected: true,
        },
        {
          path: 'compplan',
          element: <CompensationPlan />,
          protected: true,
        },
        {
          path: 'employee/:userId/compplan/edit',
          element: <CreatePlanForEmployee />,
          protected: true,
        },
        {
          path: 'employee/:userId/compplan/all',
          element: <CompPlans />,
          protected: true,
        },
        {
          path: 'employee/:userId/compplan',
          element: <EmployeePlanView />,
          protected: true,
        },
        {
          path: 'organization',
          element: <Organization />,
          protected: true,
        },
        {
          path: 'company-settings',
          element: <OrganizationSettings />,
          protected: true,
        },
        {
          path: 'period-settings',
          element: <ManagePeriods />,
          protected: true,
        },
        {
          path: 'wizard',
          element: <JumpStartWizard />,
          protected: true,
        },
        {
          path: 'admin/users/profile',
          element: <CreateUser />,
          protected: true,
        },
        {
          path: 'admin/organizations/manage',
          element: <ManageOrganizationStructure />,
          protected: true,
        },
        {
          path: 'admin/templates',
          element: <ManageTemplates />,
          protected: true,
        },
        {
          path: 'admin/templates/create',
          element: <CreateTemplate />,
          protected: true,
        },
        {
          path: 'admin/templates/:templateId/edit',
          element: <EditTemplate />,
          protected: true,
        },
        {
          path: 'admin/templates/:templateId/copy',
          element: <CopyTemplate />,
          protected: true,
        },
        {
          path: 'admin/templates/select/:userId',
          element: <SelectTemplate />,
          protected: true,
        },
        {
          path: 'admin/plans',
          element: <ManagePlans />,
          protected: true,
        },
        {
          path: 'admin/ledger-accounts',
          element: <ManageLedgerAccounts />,
          protected: true,
        },
        {
          path: 'admin/accruals',
          element: <ManageAccruals />,
          protected: true,
        },
        {
          path: 'admin/accrual-ledger',
          element: <Ledger />,
          protected: true,
        },
        {
          path: 'admin/close-periods',
          element: <ClosePeriods />,
          protected: true,
        },
        {
          path: 'admin/components/targets/create',
          element: <CreateTargets />,
          protected: true,
        },
        {
          path: 'admin/components/actuals/create',
          element: <CreateMetrics />,
          protected: true,
        },
        {
          path: 'admin/approvals',
          element: <ManageApprovals />,
          protected: true,
        },
        {
          path: 'admin/approvals/:approvalBatchId/edit',
          element: <EditApproval />,
          protected: true,
        },
        {
          path: 'profile',
          element: <EditProfile />,
          protected: true,
        },
        {
          path: 'templates',
          element: <Templates />,
          protected: true,
        },
        {
          path: 'create-comp-plan',
          element: <CreatePlanForEmployee />,
          protected: true,
        },
        {
          path: 'forms/download',
          element: <DownloadForms />,
          protected: true,
        },
        {
          path: 'forms/upload',
          element: <UploadForms />,
          protected: true,
        },
        {
          path: 'reports/run',
          element: <RunReports />,
          protected: true,
        },
        {
          path: 'admin/components/manage',
          element: <ManageComponents />,
          protected: true,
        },
        {
          path: 'admin/components/:componentId/edit',
          element: <EditComponent />,
          protected: true,
        },
        {
          path: 'admin/components/create',
          element: <CreateComponent />,
          protected: true,
        },
        {
          path: 'admin/components/targets',
          element: <ManageTargets />,
          protected: true,
        },
        {
          path: 'admin/components/actuals',
          element: <ManageMetrics />,
          protected: true,
        },
        {
          path: 'admin/targets/edit',
          element: <EditTarget />,
          protected: true,
        },
        {
          path: 'admin/metrics/edit',
          element: <EditMetric />,
          protected: true,
        },
      ],
    },
    {
      path: '/',
      element: <Pages />,
      children: [
        {
          path: 'setup-in-progress',
          element: <PendingSetup />,
          protected: true,
        },
      ],
    },
  ],
}

export default MainRoutes
