import type { FC } from 'react'
import type { ClosePeriodAndWriteAccrualsRequest } from 'types/accruals'
import type { LockPeriodRequest, Period, ReopenPeriodRequest } from 'types/periods'
import {
  BookOutlined,
  DownOutlined,
  LockOutlined,
  RedoOutlined,
  RightOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material'
import { postWithTokenWithStatus } from 'hooks/http'
import { useEffect, useState } from 'react'
import PeriodClosureDialog from '../PeriodClosureDialog'

interface PeriodsTableProps {
  periods: Period[]
  showStatusAndActions?: boolean
  maxHeight?: string | number
  token?: string
  onPeriodsUpdate?: () => void
}

const PeriodsTable: FC<PeriodsTableProps> = ({
  periods,
  showStatusAndActions = false,
  maxHeight = 'calc(100vh - 220px)',
  token,
  onPeriodsUpdate,
}) => {
  const theme = useTheme()
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null)
  const [periodIdToLabel, setPeriodIdToLabel] = useState<Record<string, string>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [snackbar, setSnackbar] = useState<{
    open: boolean
    message: string
    severity: 'success' | 'error'
  }>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [reopenDialogOpen, setReopenDialogOpen] = useState(false)
  const [periodToReopen, setPeriodToReopen] = useState<Period | null>(null)
  const [lockDialogOpen, setLockDialogOpen] = useState(false)
  const [periodToLock, setPeriodToLock] = useState<Period | null>(null)
  const [lockedPeriodDialogOpen, setLockedPeriodDialogOpen] = useState(false)

  if (showStatusAndActions && !token) {
    throw new Error('token is required when showStatusAndActions is true')
  }

  const toggleRow = (periodId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(periodId)) {
        newSet.delete(periodId)
      } else {
        newSet.add(periodId)
      }
      return newSet
    })
  }

  const findAllOpenDescendants = (period: Period): Period[] => {
    let openPeriods: Period[] = []
    if (!period.isClosed) {
      openPeriods.push(period)
    }
    if (period.children) {
      period.children.forEach((child) => {
        openPeriods = [...openPeriods, ...findAllOpenDescendants(child)]
      })
    }
    return openPeriods
  }

  const findAllClosedDescendants = (period: Period): Period[] => {
    let closedPeriods: Period[] = []
    if (period.isClosed) {
      closedPeriods.push(period)
    }
    if (period.children) {
      period.children.forEach((child) => {
        closedPeriods = [...closedPeriods, ...findAllClosedDescendants(child)]
      })
    }
    return closedPeriods
  }

  const handleClosePeriodClick = (period: Period) => {
    setSelectedPeriod(period)
    setDialogOpen(true)
  }

  const handleConfirmClose = (request: ClosePeriodAndWriteAccrualsRequest) => {
    if (request.periodsToClose.length > 0 && token) {
      console.log(`Closing ${request.periodsToClose.length} periods with IDs: ${request.periodsToClose.join(', ')}`)
      setIsSubmitting(true)
      postWithTokenWithStatus('accruals/close-periods', token, request)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.data.error || 'Failed to close periods and write accruals')
          }
          setSnackbar({
            open: true,
            message: 'Successfully closed periods and wrote accruals',
            severity: 'success',
          })
          onPeriodsUpdate?.()
          return response.data
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: error.message || 'An error occurred while closing periods',
            severity: 'error',
          })
        })
        .finally(() => {
          setDialogOpen(false)
          setSelectedPeriod(null)
          setIsSubmitting(false)
        })
    }
  }

  const handleReopenPeriodClick = (period: Period) => {
    setPeriodToReopen(period)
    setReopenDialogOpen(true)
  }

  const handleConfirmReopen = () => {
    if (!periodToReopen || !token) return

    setIsSubmitting(true)
    const request: ReopenPeriodRequest = {
      rootOrganizationId: periodToReopen.organizationId || '',
      rootPeriodId: periodToReopen.rootPeriodId || '',
      periodsToOpen: [periodToReopen.id || ''],
    }

    postWithTokenWithStatus('periods/open', token, request)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.data.error || 'Failed to reopen period')
        }
        setSnackbar({
          open: true,
          message: 'Successfully reopened period',
          severity: 'success',
        })
        onPeriodsUpdate?.()
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: error.message || 'An error occurred while reopening period',
          severity: 'error',
        })
      })
      .finally(() => {
        setIsSubmitting(false)
        setReopenDialogOpen(false)
        setPeriodToReopen(null)
      })
  }

  const handleLockPeriodClick = (period: Period) => {
    setPeriodToLock(period)
    setLockDialogOpen(true)
  }

  const handleConfirmLock = () => {
    if (!periodToLock || !token) return

    setIsSubmitting(true)
    const request: LockPeriodRequest = {
      rootOrganizationId: periodToLock.organizationId || '',
      rootPeriodId: periodToLock.rootPeriodId || '',
      periodsToLock: [periodToLock.id || '', ...findAllClosedDescendants(periodToLock).map((p) => p.id || '')],
    }

    postWithTokenWithStatus('periods/lock', token, request)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.data.error || 'Failed to lock period')
        }
        setSnackbar({
          open: true,
          message: 'Successfully locked period',
          severity: 'success',
        })
        onPeriodsUpdate?.()
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: error.message || 'An error occurred while locking period',
          severity: 'error',
        })
      })
      .finally(() => {
        setIsSubmitting(false)
        setLockDialogOpen(false)
        setPeriodToLock(null)
      })
  }

  const renderPeriodRows = (period: Period, depth = 0) => {
    const rows = []
    const isExpanded = expandedRows.has(period.id || '')

    const status =
      period.status != null && period.status.length > 0 ? period.status : period.isClosed ? 'Closed' : 'Open'

    rows.push(
      <TableRow
        key={period.id}
        sx={{ color: status.toLowerCase() === 'locked' && showStatusAndActions ? 'text.secondary' : 'inherit' }}
      >
        <TableCell
          style={{
            paddingLeft: `${depth * 20}px`,
            color: 'inherit',
          }}
        >
          {period.children && period.children.length > 0 && (
            <IconButton size="small" onClick={() => toggleRow(period.id || '')}>
              {isExpanded ? <DownOutlined /> : <RightOutlined />}
            </IconButton>
          )}
          {period.label}
        </TableCell>
        <TableCell sx={{ color: 'inherit' }}>{period.startDate}</TableCell>
        <TableCell sx={{ color: 'inherit' }}>{period.endDate}</TableCell>
        {showStatusAndActions && (
          <>
            <TableCell sx={{ color: 'inherit' }}>
              {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
            </TableCell>
            <TableCell>
              <Box display="flex">
                {status.toLowerCase() === 'locked' ? (
                  <Tooltip title="Period is Locked">
                    <IconButton color="info" onClick={() => setLockedPeriodDialogOpen(true)}>
                      <LockOutlined />
                    </IconButton>
                  </Tooltip>
                ) : !period.isClosed ? (
                  <Tooltip title="Close Period">
                    <IconButton color="primary" onClick={() => handleClosePeriodClick(period)}>
                      <BookOutlined />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip title="Reopen Period">
                      <IconButton
                        color="primary"
                        onClick={() => handleReopenPeriodClick(period)}
                        disabled={isSubmitting}
                      >
                        <RedoOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Lock Period">
                      <IconButton color="primary" onClick={() => handleLockPeriodClick(period)} disabled={isSubmitting}>
                        <UnlockOutlined />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Box>
            </TableCell>
          </>
        )}
      </TableRow>
    )

    if (isExpanded && period.children) {
      period.children.forEach((child) => {
        rows.push(...renderPeriodRows(child, depth + 1))
      })
    }

    return rows
  }

  useEffect(() => {
    const createFullIdLabelMap = (periodsArray: Period[]): Record<string, string> => {
      const map: Record<string, string> = {}

      const addToMap = (period: Period) => {
        if (period.id) {
          map[period.id] = period.label
        }
        if (period.children) {
          period.children.forEach(addToMap)
        }
      }

      periodsArray.forEach(addToMap)
      return map
    }

    setPeriodIdToLabel(createFullIdLabelMap(periods))
  }, [periods])

  const sortPeriodsByEndDate = (periodsToSort: Period[]): Period[] => {
    return [...periodsToSort].sort((a, b) => {
      const dateA = new Date(a.endDate)
      const dateB = new Date(b.endDate)
      return dateB.getTime() - dateA.getTime()
    })
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight, minHeight: '300px' }}>
        <Table
          stickyHeader
          size="small"
          sx={{
            width: '100%',
            tableLayout: 'auto',
          }}
          aria-label="sticky table"
        >
          <TableHead
            sx={{
              '& th': {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `2px solid ${theme.palette.divider} !important`,
              },
            }}
          >
            <TableRow>
              <TableCell>Period</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              {showStatusAndActions && (
                <>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{sortPeriodsByEndDate(periods).map((period) => renderPeriodRows(period))}</TableBody>
        </Table>
      </TableContainer>

      {showStatusAndActions && (
        <PeriodClosureDialog
          open={dialogOpen}
          selectedPeriod={selectedPeriod}
          onClose={() => {
            if (!isSubmitting) {
              setDialogOpen(false)
            }
          }}
          onConfirm={handleConfirmClose}
          findAllOpenDescendants={findAllOpenDescendants}
          token={token!}
          periodIdToLabel={periodIdToLabel}
        />
      )}

      <Dialog open={reopenDialogOpen} onClose={() => !isSubmitting && setReopenDialogOpen(false)}>
        <DialogTitle>Confirm Reopen Period</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reopen the period "{periodToReopen?.label}
            "?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReopenDialogOpen(false)} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={handleConfirmReopen} disabled={isSubmitting} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={lockDialogOpen} onClose={() => !isSubmitting && setLockDialogOpen(false)}>
        <DialogTitle>Confirm Lock Period</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to lock the period "{periodToLock?.label}
            "?
            {periodToLock && (
              <>
                <br />
                <br />
                The following closed periods will also be locked:
                <ul>
                  {findAllClosedDescendants(periodToLock)
                    .filter((p) => p.id !== periodToLock.id)
                    .map((p) => (
                      <li key={p.id}>{periodIdToLabel[p.id || '']}</li>
                    ))}
                </ul>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLockDialogOpen(false)} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={handleConfirmLock} disabled={isSubmitting} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={lockedPeriodDialogOpen} onClose={() => setLockedPeriodDialogOpen(false)}>
        <DialogTitle>Period is Locked</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This period has been locked and cannot be modified. Please contact support if you need to take any further
            actions on this period.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLockedPeriodDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default PeriodsTable
