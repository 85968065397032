import type {
  ID,
  Label,
  OrganizationIDs,
  PaginationInfo,
  PeriodIDs,
  Revision,
  Timestamps,
  UserID,
  Value,
} from './common'
import type { DefaultQueryParams } from './default-params'

export enum ComponentType {
  COMPONENT_TYPE_INTEGER = 'int',
  COMPONENT_TYPE_CURRENCY = 'currency',
  COMPONENT_TYPE_PERCENTAGE = 'percent',
  COMPONENT_TYPE_BOOL = 'bool',
  COMPONENT_TYPE_FLOAT = 'float',
}

export enum InputSourceType {
  INPUT_SOURCE_TYPE_USER = 'user',
  INPUT_SOURCE_TYPE_ORGANIZATION = 'organization',
}

export interface ComponentID {
  componentId: string
}

export interface Slug {
  slug: string
}

export enum ProrationMode {
  PRORATION_MODE_NONE = 'none',
  PRORATION_MODE_AMORTIZE = 'amortize',
  PRORATION_MODE_CUMULATIVE = 'cumulative',
  PRORATION_MODE_AVERAGE = 'average',
}

export interface Component extends ID, OrganizationIDs, Label, Slug, Timestamps {
  ledgerAccountId: string | null
  isOrganizationLevel: boolean
  isTargetEditable: boolean
  restrictedVisibility: boolean
  targetMin: string
  targetMax: string
  sourceType: InputSourceType
  componentType: ComponentType
}

export interface TargetDTO extends OrganizationIDs, UserID, PeriodIDs, ComponentID, Label, Value, Slug {}

export interface CreateTargetDTO {
  organizationId: string
  rootOrganizationId: string
  periodId: string
  rootPeriodId: string
  label: string
  isOrganizationLevel: boolean
  isRestricted: boolean
  componentType: ComponentType
  targetMin: string
  targetMax: string
  value: string
}

export interface CreateMetricDTO {
  componentId: string
  organizationId: string
  rootOrganizationId: string
  periodId: string
  rootPeriodId: string
  userId: string
  value: string
}

export interface Target extends TargetDTO, Slug, Revision, Pick<Timestamps, 'createdAt'> {
  componentType: ComponentType | null
}

export interface MetricDTO extends OrganizationIDs, PeriodIDs, UserID, ComponentID, Value {}

export interface Metric extends MetricDTO, Revision, Pick<Timestamps, 'createdAt'> {
  label: string | null
  componentType: ComponentType | null
}

export interface ExpandedTarget {
  target: Target
  component: Component
}

export interface ComponentSearchParams {
  periodId?: string[]
  organizationIds?: string[]
  userIds?: string[]
  searchTerm?: string
  perPage?: number
  page?: number
}

export interface ComponentQueryParams extends DefaultQueryParams {
  organizationIds: string[]
  periodIds: string[]
  userIds: string[]
}

export interface ComponentSearchResponse {
  components: Component[]
  paginationInfo: PaginationInfo
}

export interface TargetQueryParams extends DefaultQueryParams {
  organizationIds: string[]
  periodIds: string[]
}

export interface TargetSearchResponse {
  targets: Target[]
  paginationInfo: PaginationInfo
}

export interface MetricQueryParams extends DefaultQueryParams {
  organizationIds: string[]
  periodIds: string[]
}

export interface ExpandedTargetSearchResponse {
  targets: ExpandedTarget[]
  paginationInfo: PaginationInfo
}

export interface MetricSearchResponse {
  metrics: Metric[]
  paginationInfo: PaginationInfo
}

export function formatValue(
  value: string | number,
  componentType: ComponentType,
  includeCurrencySymbol: boolean = true
): string {
  const parsedValue = typeof value === 'string' ? Number.parseFloat(value) : value

  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return parsedValue.toLocaleString()
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      if (!includeCurrencySymbol) {
        return parsedValue.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      }
      return parsedValue.toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return `${parsedValue.toFixed(2)}%`
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return parsedValue.toFixed(2)
    case ComponentType.COMPONENT_TYPE_BOOL:
      return value.toLocaleString().toLocaleLowerCase() === 'true' ? 'True' : 'False'
    default:
      return value.toString()
  }
}

export function unformatValue(formattedValue: string, componentType: ComponentType): string | number {
  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_INTEGER:
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return formattedValue.replace(/[$,]/g, '')
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return Number.parseFloat(formattedValue.replace('%', ''))
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return Number.parseFloat(formattedValue)
    case ComponentType.COMPONENT_TYPE_BOOL:
      return formattedValue.toLocaleLowerCase() === 'yes' || formattedValue.toLocaleLowerCase() === 'true'
        ? 'true'
        : 'false'
    default:
      return formattedValue
  }
}

export function getTargetCompositeId(target: Target): string {
  return `${target.componentId}_${target.periodId}_${target.userId}`
}

export interface NeededTargetMetric {
  componentId: string
  rootOrganizationId: string
  organizationId: string
  rootPeriodId: string
  componentType: ComponentType
  periodId: string
  userId: string
  label: string
  value: string
  firstName: string
  lastName: string
  newValue?: string
}

export interface SearchNeededTargetMetricsParams {
  organizationIds: string[]
  periodIds: string[]
  excludeMetricsEntered?: boolean
  limit?: number
}

export interface TargetDetailsParams {
  componentId: string
  organizationId: string
  periodId: string
  userId?: string | null
}

export interface MetricDetailsParams {
  componentId: string
  organizationId: string
  periodId: string
  userId?: string | null
}

interface TargetRevision {
  componentId: string
  rootOrganizationId: string
  organizationId: string
  rootPeriodId: string
  periodId: string
  userId: string | null
  label: string
  slug: string
  revision: number
  value: string
  createdAt: string
  approvalBatchId: string | null
  approvalStatus: string
}

interface TargetComponent {
  id: string
  rootOrganizationId: string
  organizationId: string
  ledgerAccountId: string | null
  label: string
  isOrganizationLevel: boolean
  isTargetEditable: boolean
  restrictedVisibility: boolean
  slug: string
  targetMin: string
  targetMax: string
  format: string
  sourceType: string
  componentType: string
  createdAt: string
  updatedAt: string
}

interface TemplateInfo {
  id: string
  revision: number
  label: string
}

interface PlanInfo {
  id: string
  revision: number
  userId: string
  firstName: string
  lastName: string
  approvalBatchId: string | null
  approvalStatus: string
}

export interface TargetDetailsResponse {
  revisions: TargetRevision[]
  component: TargetComponent
  templates: TemplateInfo[]
  plans: PlanInfo[]
  organization: {
    id: string
    name: string
    parentId: string | null
    rootOrganizationId: string
    createdAt: string
    updatedAt: string
  }
  period: {
    id: string
    parentId: string | null
    organizationId: string
    label: string
    startDate: string
    endDate: string
    payDate: string | null
    createdAt: string
    updatedAt: string
  }
  user: {
    id: string
    email: string
    firstName: string
    lastName: string
    startDate: string
    endDate: string | null
    position: string
    metadata: Record<string, unknown>
    ledgerAccountId: string | null
    createdAt: string
    updatedAt: string
  } | null
  coreSettings: {
    organizationId: string
    defaultLedgerAccount: string
    currency: string
    currencyDecimals: number
    defaultBasePlus: boolean
    defaultExampleCompensation: number
    defaultVariableCompensation: string
    defaultMaxPayout: string
    defaultTemplateTables: string
    targetApprovalChain: string
    metricApprovalChain: string
    planApprovalChain: string
    accrualApprovalChain: string
  }
}

export interface MetricDetailsResponse {
  revisions: MetricRevision[]
  component: TargetComponent
  templates: TemplateInfo[]
  plans: PlanInfo[]
  organization: {
    id: string
    name: string
    parentId: string | null
    rootOrganizationId: string
    createdAt: string
    updatedAt: string
  }
  period: {
    id: string
    parentId: string | null
    organizationId: string
    label: string
    startDate: string
    endDate: string
    payDate: string | null
    createdAt: string
    updatedAt: string
  }
  user: {
    id: string
    email: string
    firstName: string
    lastName: string
    startDate: string
    endDate: string | null
    position: string
    metadata: Record<string, unknown>
    ledgerAccountId: string | null
    createdAt: string
    updatedAt: string
  } | null
  coreSettings: {
    organizationId: string
    defaultLedgerAccount: string
    currency: string
    currencyDecimals: number
    defaultBasePlus: boolean
    defaultExampleCompensation: number
    defaultVariableCompensation: string
    defaultMaxPayout: string
    defaultTemplateTables: string
    targetApprovalChain: string
    metricApprovalChain: string
    planApprovalChain: string
    accrualApprovalChain: string
  }
}

export interface MetricRevision {
  componentId: string
  rootOrganizationId: string
  organizationId: string
  rootPeriodId: string
  periodId: string
  userId: string
  revision: number
  value: string
  approvalBatchId: string
  createdAt: string
  approvalStatus: string
}

export function getInputFormatFromComponentType(componentType: ComponentType): ComponentType {
  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return ComponentType.COMPONENT_TYPE_INTEGER
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return ComponentType.COMPONENT_TYPE_CURRENCY
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return ComponentType.COMPONENT_TYPE_PERCENTAGE
    case ComponentType.COMPONENT_TYPE_BOOL:
      return ComponentType.COMPONENT_TYPE_BOOL
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return ComponentType.COMPONENT_TYPE_FLOAT
    default:
      throw new Error(`Unknown component type: ${componentType}`)
  }
}

export interface LabelResponse {
  labels: string[]
}

export interface ComponentDetails {
  component: Component
  approvedTargets: number
  approvedMetrics: number
  templates: TemplateInfo[]
  plans: PlanInfo[]
}
