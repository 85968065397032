import type { VariableAllocationTable } from 'types/comp-allocations'
import type { Period } from 'types/periods'
import { Box, CardContent, Grid2 as Grid, Typography } from '@mui/material'
import Card from 'components/@extended/Card'
import Paper from 'components/@extended/Paper'
import CompensationChart from 'components/CompensationChart'
import React from 'react'
import { getRowTypeLabel, RowType } from 'types/comp-allocations'

interface TemplateReviewProps {
  allocationTables: VariableAllocationTable[]
  periodIdToLabelMap: Record<string, string>
  label: string
  position: string
  periodId: string
  basePlus: boolean
  exampleSalary: number
  variableCapMin: number
  variableCapDefault: number
  variableCapMax: number
  variablePercentMin: number
  variablePercentDefault: number
  variablePercentMax: number
  organizationNames: string[] | null
  currency: string
  currencyDecimals: number
  period: Period
}

const TemplateReview: React.FC<TemplateReviewProps> = ({
  allocationTables,
  periodIdToLabelMap,
  label,
  position,
  periodId,
  basePlus,
  exampleSalary,
  variableCapMin,
  variableCapDefault,
  variableCapMax,
  variablePercentMin,
  variablePercentDefault,
  variablePercentMax,
  organizationNames,
  currency,
  currencyDecimals,
  period,
}) => {
  const calculateCompensations = (
    exampleSalary: number,
    variablePercent: number,
    basePlus: boolean,
    periodProration: number
  ) => {
    const bonusAmount = exampleSalary * variablePercent
    const baseSalary = basePlus ? exampleSalary : exampleSalary - bonusAmount
    const proratedBaseSalary = baseSalary * periodProration
    const proratedBonusAmount = basePlus
      ? proratedBaseSalary * variablePercent
      : exampleSalary * periodProration - proratedBaseSalary

    return { proratedBaseSalary, proratedBonusAmount }
  }

  const { proratedBaseSalary, proratedBonusAmount } = calculateCompensations(
    exampleSalary,
    variablePercentDefault,
    basePlus,
    period.periodProration || 1.0
  )

  const getAcceleratorLabel = (accelerator: string, rowType: RowType) => {
    if (rowType != RowType.CALCULATION_ROW) {
      return '-'
    }

    return accelerator.replace(/[^\d.-]/g, '')
  }

  const getMaxPayoutLabel = (maxPayout: number, rowType: RowType) => {
    if (rowType == RowType.DISPLAY_ROW) {
      return '-'
    }

    return maxPayout ? `${Math.round(Number(maxPayout) * 100)}%` : '0%'
  }

  const getAllocationPercentageLabel = (allocationPercentage: number, rowType: RowType) => {
    if (rowType == RowType.DISPLAY_ROW) {
      return '-'
    }

    return allocationPercentage ? `${Math.round(Number(allocationPercentage) * 100)}%` : '0%'
  }

  const formatPercentage = (value: number) => `${(value * 100).toFixed(2)}%`
  const formatCurrency = (value: number) =>
    value.toLocaleString(undefined, {
      style: 'currency',
      currency,
      minimumFractionDigits: currencyDecimals,
      maximumFractionDigits: currencyDecimals,
    })

  return (
    <Grid key="template-review" container spacing={1}>
      <Grid size={{ xs: 12 }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container>
              <Grid size={{ xs: 8 }}>
                <Typography variant="h3" gutterBottom>
                  {label}
                </Typography>

                <Grid container spacing={2}>
                  <Grid size={{ xs: 6 }}>
                    <Typography variant="body1">
                      <strong>Position:</strong> {position}
                    </Typography>
                    <Typography variant="body1">
                      <strong>
                        Organization
                        {organizationNames && organizationNames.length > 1 ? 's' : ''}:
                      </strong>{' '}
                      {organizationNames ? organizationNames.join(', ') : 'N/A'}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Fiscal Year:</strong> {periodIdToLabelMap[periodId] || 'N/A'}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Base Plus:</strong> {basePlus ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <Typography variant="body1">
                      <strong>Example Annual Base Salary:</strong> {formatCurrency(exampleSalary)}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Variable Cap:</strong> {formatPercentage(variableCapMin)} (Min),{' '}
                      {formatPercentage(variableCapDefault)} (Default),
                      {formatPercentage(variableCapMax)} (Max)
                    </Typography>
                    <Typography variant="body1">
                      <strong>Variable Percent:</strong> {formatPercentage(variablePercentMin)} (Min),{' '}
                      {formatPercentage(variablePercentDefault)} (Default),
                      {formatPercentage(variablePercentMax)} (Max)
                    </Typography>
                    <Typography variant="body1">
                      <strong>Base Compensation:</strong> {formatCurrency(proratedBaseSalary)}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Variable Compensation:</strong> {formatCurrency(proratedBonusAmount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <CompensationChart
                  salary={proratedBaseSalary}
                  variable={proratedBonusAmount}
                  currency={currency}
                  currencyDecimals={currencyDecimals}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Box sx={{ width: '100%' }}>
        {allocationTables.map((table, tableIndex) => (
          <Paper sx={{ padding: 3, marginTop: 3, width: '100%' }}>
            <Grid size={{ xs: 12 }} key={tableIndex}>
              <Typography variant="h4" sx={{ mb: 3 }}>
                {table.label}
              </Typography>
              <Grid container spacing={2}>
                {/* Header */}
                <Grid size={{ xs: 12 }} container>
                  <Grid size={{ xs: 2 }}>
                    <Typography variant="subtitle1">Name</Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                    <Typography variant="subtitle1">Type</Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                    <Typography variant="subtitle1">Period</Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                    <Typography variant="subtitle1" align="center">
                      Allocation %
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                    <Typography variant="subtitle1" align="center">
                      Max Payout
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                    <Typography variant="subtitle1" align="center">
                      Accelerator
                    </Typography>
                  </Grid>
                </Grid>
                {/* Rows */}
                {table.rows.map((row, rowIndex) => (
                  <Grid size={{ xs: 12 }} container key={rowIndex}>
                    <Grid size={{ xs: 2 }}>
                      <Typography>{row.label}</Typography>
                    </Grid>
                    <Grid size={{ xs: 2 }}>
                      <Typography>{getRowTypeLabel(row.rowType)}</Typography>
                    </Grid>
                    <Grid size={{ xs: 2 }}>
                      <Typography>{periodIdToLabelMap[row.periodId || ''] || 'N/A'}</Typography>
                    </Grid>
                    <Grid size={{ xs: 2 }}>
                      <Typography align="center">
                        {getAllocationPercentageLabel(row.allocationPercentage, row.rowType)}
                      </Typography>
                    </Grid>
                    <Grid size={{ xs: 2 }}>
                      <Typography align="center">{getMaxPayoutLabel(row.defaultPayout, row.rowType)}</Typography>
                    </Grid>
                    <Grid size={{ xs: 2 }}>
                      <Typography align="center">
                        {getAcceleratorLabel(row.achievementMultiplier, row.rowType)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Box>
    </Grid>
  )
}

export default TemplateReview
