import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import { TextField as MuiTextField } from '@mui/material'
import { styled } from '@mui/material/styles'

type StyleProps = MuiTextFieldProps & {
  pill?: boolean
}

export const StyledTextField = styled(({ pill, ...props }: StyleProps) => <MuiTextField {...props} />)(
  ({ theme, pill }) => ({
    ...(pill && {
      '& .MuiOutlinedInput-root': {
        borderRadius: theme.shape.borderRadius * 5.25,
        '& fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.dark,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.secondary.main,
        },
      },
    }),
  })
)

type Props = MuiTextFieldProps & {
  pill?: boolean
  shrink?: boolean
}

export function TextField({ children, pill = false, shrink = false, ...rest }: Props) {
  return (
    <StyledTextField {...rest} pill slotProps={{ inputLabel: { shrink } }}>
      {children}
    </StyledTextField>
  )
}
