import type { AccrualLedgerQueryParams } from 'types/accruals'
import type { SortParams } from 'types/default-params'
import type { Period } from 'types/periods'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import { useAccrualLedger } from 'hooks/useAccruals'
import useAuth from 'hooks/useAuth'
import { useFetchAllLedgerAccounts } from 'hooks/useLedgerAccounts'
import useLocalStorage from 'hooks/useLocalStorage'
import { usePeriods } from 'hooks/usePeriods'
import React, { Suspense, useCallback, useEffect, useState, useTransition } from 'react'
import { createIdLabelMap } from 'types/periods'

interface Column {
  id: string
  label: string
  width: number
  format?: (row: any) => React.ReactNode
}

const Ledger: React.FC = () => {
  const theme = useTheme()
  const { token, profile } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useLocalStorage<number>('ledger-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('ledger-rows-per-page', 100)
  const [sort, setSort] = useLocalStorage<string>('ledger-sort', 'userLastName')
  const [order, setOrder] = useLocalStorage<string>('ledger-order', 'asc')
  const [sortParams, setSortParams] = useState<SortParams[]>([])
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'ledger-selected-organizations',
    []
  )
  const [selectedPeriods, setSelectedPeriods] = useLocalStorage<string[]>('ledger-selected-periods', [])
  const [periods, setPeriods] = useState<Period[]>([])
  const [isPending, startTransition] = useTransition()
  const [periodLabels, setPeriodLabels] = useState<Record<string, string>>({})
  const [isInitialized, setIsInitialized] = useState(false)
  const [selectedLedgerAccount, setSelectedLedgerAccount] = useState<string>('All')

  const { ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useFetchAllLedgerAccounts(
    selectedOrganizations,
    token!,
    selectedOrganizations.length > 0 && !!token
  )

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    selectedOrganizations[0] || '',
    !!token && selectedOrganizations.length > 0
  )

  useEffect(() => {
    if (fetchedPeriods) {
      setPeriods(fetchedPeriods.periods || [])
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (fetchedPeriods && fetchedPeriods.periods.length > 0) {
      const rootPeriod = fetchedPeriods.periods[0]
      const idLabelMap = createIdLabelMap(rootPeriod)
      setPeriodLabels(idLabelMap)
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (profile && profile.roles.length > 0 && !isInitialized) {
      const allOrganizationIds = profile.roles.map((role) => role.organization.id)
      if (selectedOrganizations.length === 0) {
        setSelectedOrganizations(allOrganizationIds)
      }
      setIsInitialized(true)
    }
  }, [profile, isInitialized])

  useEffect(() => {
    if (sort === 'employee') {
      if (order === 'asc') {
        setSortParams([
          { field: 'userFirstName', order: 'asc' },
          { field: 'userLastName', order: 'asc' },
        ])
      } else {
        setSortParams([
          { field: 'userLastName', order: 'desc' },
          { field: 'userFirstName', order: 'desc' },
        ])
      }
    } else {
      setSortParams([{ field: sort, order }])
    }
  }, [sort, order])

  const queryParams: AccrualLedgerQueryParams = {
    organizationIds: selectedOrganizations,
    periodIds: selectedPeriods,
    searchTerm,
    sort: sortParams,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    ledgerAccountId: selectedLedgerAccount !== 'All' ? selectedLedgerAccount : undefined,
  }

  const {
    accrualLedgers = [],
    pagination,
    isLoading,
    isError,
  } = useAccrualLedger({
    token: token!,
    queryParams,
    shouldFetch: !!token && selectedOrganizations.length > 0 && selectedPeriods.length > 0 && isInitialized,
  })

  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    startTransition(() => {
      setPage(newPage)
    })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    startTransition(() => {
      setRowsPerPage(Number.parseInt(event.target.value, 10))
      setPage(0)
    })
  }

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const columns: Column[] = [
    {
      id: 'employee',
      label: 'Employee',
      width: 200,
      format: (row) => `${row.userFirstName} ${row.userLastName}`,
    },
    {
      id: 'account',
      label: 'Account',
      width: 150,
      format: (row) => row.ledgerAccountLabel || '-',
    },
    {
      id: 'periodId',
      label: 'Period',
      width: 150,
      format: (row) => periodLabels[row.periodId] || row.periodId,
    },
    {
      id: 'lineItem',
      label: 'Line Item',
      width: 200,
      format: (row) => `${row.allocationTableName} | ${row.allocationRowName}`,
    },
    {
      id: 'balance',
      label: 'Balance',
      width: 150,
      format: (row) =>
        row.balance !== null
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Number(row.balance))
          : '-',
    },
  ]

  const handleOrganizationChange = (newSelectedOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newSelectedOrganizations)
      setPage(0)
    })
  }

  const handlePeriodChange = (newSelectedPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newSelectedPeriods)
      setPage(0)
    })
  }

  return (
    <>
      <Grid container pb={2}>
        <Grid size={{ xs: 4 }}>
          <SimpleTitle title="Accrual Ledger" />
        </Grid>
      </Grid>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 3 }}>
          <SearchInput
            id="ledger-search-input"
            key="ledger-search-input"
            onSearch={handleSearch}
            initialValue={searchTerm}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>

        <Grid size={{ xs: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink sx={{ '&.MuiInputLabel-shrink': { background: theme.palette.background.default } }}>
              Ledger Accounts
            </InputLabel>
            <Select
              fullWidth
              value={selectedLedgerAccount}
              onChange={(e) => setSelectedLedgerAccount(e.target.value as string)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  },
                },
              }}
              style={{ borderRadius: '21px' }}
            >
              <MenuItem value="All">All</MenuItem>
              {isLoadingLedgerAccounts ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : (
                ledgerAccounts?.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.label}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid size={{ xs: 3 }}>
          <MultiPeriodSelector
            key="period-selector"
            periods={periods}
            selectedPeriods={selectedPeriods}
            handleChange={handlePeriodChange}
            rootPeriodOnly={false}
            sortOrder="asc"
            timeFilter="pastOnly"
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>

        <Grid size={{ xs: 4 }}>
          <MultiOrganizationSelector
            key="organization-selector"
            userProfile={profile!}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={handleOrganizationChange}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
      </Grid>

      <Suspense fallback={<CircularProgress />}>
        {isLoadingPeriods || isLoading || isPending || isLoadingLedgerAccounts ? (
          <CircularProgress />
        ) : isError ? (
          <Typography color="error">Error loading ledger entries</Typography>
        ) : (
          <Box>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" size="small" component={Paper}>
                <TableHead
                  sx={{
                    '& th': {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `2px solid ${theme.palette.divider} !important`,
                    },
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} style={{ width: column.width, minWidth: column.width }}>
                        {column.id === 'lineItem' || column.id === 'account' ? (
                          column.label
                        ) : (
                          <TableSortLabel
                            active={sort === column.id}
                            direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                            onClick={() => handleSort(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accrualLedgers.map((row) => (
                    <TableRow key={row.id} hover>
                      {columns.map((column) => (
                        <TableCell key={column.id}>
                          {column.format ? column.format(row) : row[column.id as keyof typeof row]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[100, 250, 500]}
              component="div"
              count={pagination?.totalItems || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Suspense>
    </>
  )
}

export default Ledger
