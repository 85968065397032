import type {
  ApprovalBatch,
  ApprovalBatchQueryParams,
  ApprovalBatchResponse,
  ApprovalBatchStatusRequest,
  ApprovalChain,
  ExpandedApprovalBatch,
  NoteDTO,
} from 'types/approvals'
import useSWR from 'swr'
import { fetchWithToken, postWithToken } from './http'

// Update function signature
export function useFetchApprovalChains(token: string, organizationId: string) {
  const { data, error, isLoading } = useSWR(
    organizationId ? [`approval-chains?organizationId=${organizationId}`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null)
  )

  return {
    approvalChains: data as ApprovalChain[],
    isLoading,
    isError: error,
  }
}

export function useFetchApprovalBatchById(token: string, batchId: string) {
  const { data, error, isLoading } = useSWR([`approvals/${batchId}`, token], ([url, token]) =>
    fetchWithToken(url, token, null)
  )

  return {
    approvalBatch: data as ExpandedApprovalBatch,
    isLoading,
    isError: error,
  }
}

export function useFetchApprovalBatches(token: string, params: ApprovalBatchQueryParams | null) {
  const arrayParams = Object.entries(params || {}).filter(([key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0
    }
    return value !== ''
  })

  const { data, error, isLoading } = useSWR(['approvals', token, arrayParams], ([url, token, arrayParams]) =>
    fetchWithToken(url, token, arrayParams)
  )

  return {
    approvals: data as ApprovalBatchResponse,
    isLoading,
    isError: error,
  }
}

export function useSubmitApprovalNote(token: string) {
  const submitNote = async (note: NoteDTO): Promise<ApprovalBatch> => {
    try {
      const response = await postWithToken(`approvals/notes`, token, note)
      return response as ApprovalBatch
    } catch (error) {
      console.error('Failed to submit approval note:', error)
      throw error
    }
  }

  return submitNote
}

export function useSubmitStatusChange(token: string) {
  const submitStatusChange = async (statusChange: ApprovalBatchStatusRequest): Promise<ExpandedApprovalBatch> => {
    try {
      const response = await postWithToken(`approvals/status`, token, statusChange)
      return response as ExpandedApprovalBatch
    } catch (error) {
      console.error('Failed to submit status change:', error)
      throw error
    }
  }

  return submitStatusChange
}
