export default function LinearProgress() {
  return {
    MuiLinearProgress: {
      defaultProps: {
        color: 'info',
      },
      variants: [],
      styleOverrides: {
        root: {
          height: 6,
          borderRadius: 100,
        },
        bar: {
          borderRadius: 100,
        },
      },
    },
  }
}
