import { SearchOutlined } from '@ant-design/icons'
import { InputAdornment } from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { TextField } from '../@extended/TextField'

interface SearchInputProps {
  id: string
  searchKey?: string
  onSearch: (value: string) => void
  initialValue?: string
  debounceTime?: number
  labelColor?: string
  labelBackgroundColor?: string
}

const SearchInput: React.FC<SearchInputProps> = ({
  id,
  searchKey = null,
  onSearch,
  initialValue = '',
  debounceTime = 300,
  labelColor = undefined,
  labelBackgroundColor = undefined,
}) => {
  const [inputValue, setInputValue] = useState(initialValue)

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      onSearch(value)
    }, debounceTime),
    [onSearch, debounceTime]
  )

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setInputValue(value)
      debouncedSearch(value)
    },
    [debouncedSearch]
  )

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  return (
    <TextField
      id={id}
      key={searchKey}
      label="Search"
      placeholder="enter search"
      value={inputValue}
      variant="outlined"
      pill
      shrink
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
      sx={{
        userSelect: 'none',
        width: '100%',
        '& .MuiInputLabel-root': {
          backgroundColor: 'transparent',
        },
        '&.MuiInputLabel-shrink': {
          background: labelBackgroundColor || undefined,
          color: labelColor || undefined,
        },
      }}
      onChange={handleSearchChange}
    />
  )
}

export default React.memo(SearchInput)
