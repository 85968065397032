import type { LoginHistoryItem } from 'types/login-history'
import useSWR from 'swr'
import { fetchWithToken } from './http'

export function useLoginHistory(token: string) {
  const shouldFetch = token != null && token.trim() !== ''

  const { data, error, isValidating } = useSWR(
    shouldFetch ? [`login-history`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: false,
      revalidateIfStale: true,
      revalidateOnFocus: false,
    }
  )

  return {
    loginHistory: data as LoginHistoryItem[],
    isLoading: !data && isValidating,
    isError: !!error,
  }
}
