import type { JSX } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import {
  Alert,
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import useAuth from 'hooks/useAuth'
import { useMFAEmail, useMFAEnrollments } from 'hooks/useProfile'
import React, { useState } from 'react'

const TwoStepAuthSection: React.FC = () => {
  const { token } = useAuth()
  const { triggerMFAEmail } = useMFAEmail(token!)
  const { mfaEnrollments, isLoading, deleteMFAEnrollment, revalidate } = useMFAEnrollments(token!)
  const [openModal, setOpenModal] = useState(false)
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState<string | null>(null)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleAddAuthMethod = async () => {
    try {
      await triggerMFAEmail()
      setOpenSnackbar(true)
    } catch (error) {
      console.error('Error triggering MFA email:', error)
      // You might want to show an error message here
    }
  }

  const handleDeleteClick = (enrollmentId: string) => {
    setSelectedEnrollmentId(enrollmentId)
    setOpenModal(true)
  }

  const handleConfirmDelete = async () => {
    if (selectedEnrollmentId) {
      try {
        await deleteMFAEnrollment(selectedEnrollmentId)
        setOpenModal(false)
        revalidate()
        // You might want to show a success message here
      } catch (error) {
        console.error('Error deleting MFA enrollment:', error)
        // You might want to show an error message here
      }
    }
  }

  interface ColumnProps {
    id: string
    label: string
    minWidth: number
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
    format?: (value: any, row: any) => string | boolean | JSX.Element
  }

  const columns: ColumnProps[] = [
    { id: 'type', label: 'Type', minWidth: 100 },
    { id: 'identifier', label: 'Identifier', minWidth: 170 },
    { id: 'enrolled_at', label: 'Enrolled At', minWidth: 170 },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'right',
      format: (value: any, row: any) => (
        <IconButton onClick={() => handleDeleteClick(row.id)} color="error">
          <DeleteOutlined />
        </IconButton>
      ),
    },
  ]

  return (
    <Box width="100%">
      <Typography variant="h4" gutterBottom>
        Two-step authentication
      </Typography>
      <Typography variant="body1">
        Increase security for your account by using multiple authentication methods. You can enable SMS, authenticator
        apps.
      </Typography>
      <Box display="flex" justifyContent="flex-start" marginTop="20px" marginBottom="20px">
        <Button variant="contained" color="primary" onClick={handleAddAuthMethod}>
          Add authentication method
        </Button>
      </Box>

      {!isLoading && mfaEnrollments.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {mfaEnrollments.map((row) => (
                <TableRow key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id as keyof typeof row]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value, row) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography sx={{ mt: 2 }}>Are you sure you want to delete this authentication method?</Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setOpenModal(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          MFA setup email sent successfully!
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default TwoStepAuthSection
