import type { Accrual, AccrualLedgerItem } from './accruals'
import type { ID, OrganizationIDs, PaginationInfo, PeriodIDs, Revision, Timestamps, UserID } from './common'
import type { VariableAllocationTable } from './comp-allocations'
import type { Component, Metric, Target, TargetDTO } from './components'
import type { Period } from './periods'

export interface CompensationPlanDTO extends ID, OrganizationIDs, UserID, PeriodIDs, Revision {
  templateId: string | null
  templateRevision: number | null
  position: string
  basePlus: boolean
  baseSalary: number
  periodId: string
  periodProration: number
  variablePercent: number
  variableCap: number
  variableAllocations: VariableAllocationTable[]
  effectiveDate: string
}

export interface CompensationPlan extends CompensationPlanDTO, Timestamps {}

export interface ExpandedCompensationPlanDTO {
  plan: CompensationPlanDTO
  components: string[]
}

export interface ExpandedCompensationPlan {
  plan: CompensationPlan
  components: Component[]
  targets: Target[]
  metrics: Metric[]
  period: Period
  progress?: Accrual[]
  ledger?: AccrualLedgerItem[]
}

export interface CompensationPlanCreateDTO {
  plan: CompensationPlanDTO
  components: string[]
  targets: TargetDTO[]
}

export interface CompensationPlansResponse {
  plans: CompensationPlan[]
  pagination: PaginationInfo
}

export interface ExpandedCompensationPlansResponse {
  plans: ExpandedCompensationPlan[]
  pagination: PaginationInfo
}

export interface ArchivePlanParams {
  rootOrganizationId: string
  planId: string
}

export interface ArchivePlanResponse {
  message: string
}

export interface ClosePlanParams {
  planId: string
  endDate: string
  rootOrganizationId: string
}

export function getSlugLabelMapForPlan(plan: ExpandedCompensationPlan) {
  const targetSlugMap =
    plan.targets != null
      ? plan.targets.reduce(
          (acc, target) => {
            acc[target.slug] = `${target.label}_Target`.replace(/\s+/g, '_')
            return acc
          },
          {} as { [key: string]: string }
        )
      : {}

  const componentSlugMap =
    plan.components != null
      ? plan.components.reduce(
          (acc, component) => {
            acc[component.slug] = `${component.label}`.replace(/\s+/g, '_')
            return acc
          },
          {} as { [key: string]: string }
        )
      : {}

  return {
    ...targetSlugMap,
    ...componentSlugMap,
  }
}
