import React from 'react'
import useSWR from 'swr'
import type { MFAEnrollmentsResponse } from 'types/auth'
import { deleteWithTokenWithStatus, fetchWithTokenWithStatus, postWithTokenWithStatus } from './http'

export function usePasswordReset(token: string) {
  const triggerPasswordReset = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/password-reset', token, {})
      return response
    } catch (error) {
      console.error('Error triggering password reset:', error)
      throw error
    }
  }, [token])

  return { triggerPasswordReset }
}

export function useMFAEmail(token: string) {
  const triggerMFAEmail = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/mfa-email', token, {})
      return response
    } catch (error) {
      console.error('Error triggering MFA email:', error)
      throw error
    }
  }, [token])

  return { triggerMFAEmail }
}

export function useSignOutAll(token: string) {
  const triggerSignOutAll = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/sign-out-all', token, {})
      return response
    } catch (error) {
      console.error('Error signing out of all devices:', error)
      throw error
    }
  }, [token])

  return { triggerSignOutAll }
}

export function useMFAEnrollments(token: string, shouldFetch: boolean = true) {
  const key = shouldFetch ? ['auth/mfa-enrollments', token] : null
  const { data, error, isLoading, mutate } = useSWR<MFAEnrollmentsResponse>(
    key,
    ([url, token]) => fetchWithTokenWithStatus(url, token, null),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const deleteMFAEnrollment = React.useCallback(
    async (enrollmentId: string) => {
      try {
        const response = await deleteWithTokenWithStatus(`auth/mfa-enrollments/${enrollmentId}`, token)
        await mutate() // Refetch the enrollments after deletion
        return response
      } catch (error) {
        console.error('Error deleting MFA enrollment:', error)
        throw error
      }
    },
    [token, mutate]
  )

  const revalidate = () => {
    if (key) {
      mutate()
    }
  }

  return {
    mfaEnrollments: data?.data || [],
    isLoading,
    isError: error,
    deleteMFAEnrollment,
    revalidate,
  }
}
