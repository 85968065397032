// types
import type { GuardProps } from 'types/auth'
// project import
import { APP_DEFAULT_PATH } from 'config'

import useAuth from 'hooks/useAuth'
import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

// ==============================|| GUEST GUARD ||============================== //

function GuestGuard({ children }: GuardProps) {
  const { isLoggedIn } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      // Check window.history.state for targetUrl
      let targetUrl = window.history.state?.targetUrl || APP_DEFAULT_PATH

      // If targetUrl is '/', change it to '/dashboard'
      if (targetUrl === '/') {
        targetUrl = '/dashboard'
      }

      console.log('targetUrl', targetUrl)

      // Clear the targetUrl from window.history.state
      const newState = { ...window.history.state }
      delete newState.targetUrl
      window.history.replaceState(newState, document.title)

      navigate(targetUrl, {
        replace: true,
        state: { from: window.location.pathname },
      })
    }
  }, [isLoggedIn, navigate])

  return children
}

export default GuestGuard
