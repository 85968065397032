import type { AccrualsQueryParams } from 'types/accruals'
import type { SortParams } from 'types/default-params'
import type { LedgerAccount } from 'types/ledger-account'
import type { Period } from 'types/periods'
import type { UserRole } from 'types/role'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import { useAccruals } from 'hooks/useAccruals'
import useAuth from 'hooks/useAuth'
import { useFetchAllLedgerAccounts } from 'hooks/useLedgerAccounts'
import useLocalStorage from 'hooks/useLocalStorage'
import { usePeriods } from 'hooks/usePeriods'
import React, { Suspense, useCallback, useEffect, useState, useTransition } from 'react'
import { ThemeMode } from 'types/config'
import { createIdLabelMap } from 'types/periods'
import AccrualDetails from './AccrualDetails'

interface Column {
  id: string
  label: string
  width: number
  format?: (row: any) => React.ReactNode
}

const ManageAccruals: React.FC = () => {
  const theme = useTheme()
  const { token, profile } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useLocalStorage<number>('accruals-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('accruals-rows-per-page', 100)
  const [sort, setSort] = useLocalStorage<string>('accruals-sort', 'createdAt')
  const [order, setOrder] = useLocalStorage<string>('accruals-order', 'desc')
  const [sortParams, setSortParams] = useState<SortParams[]>([])
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'accruals-selected-organizations',
    []
  )
  const [selectedPeriods, setSelectedPeriods] = useLocalStorage<string[]>('accruals-selected-periods', [])
  const [periods, setPeriods] = useState<Period[]>([])
  const [isPending, startTransition] = useTransition()
  const [periodLabels, setPeriodLabels] = useState<Record<string, string>>({})
  const [status, setStatus] = useLocalStorage<AccrualsQueryParams['status']>('accruals-status', 'Approved')
  const [selectedAccrual, setSelectedAccrual] = useState<any | null>(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [periodsInitialized, setPeriodsInitialized] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  // const [isQueueing, setIsQueueing] = useState(false)
  // const { enqueueSnackbar } = useSnackbar()

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  const { ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useFetchAllLedgerAccounts(
    selectedOrganizations,
    token!,
    selectedOrganizations.length > 0 && !!token
  )

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    selectedOrganizations[0] || '',
    !!token && selectedOrganizations.length > 0
  )

  useEffect(() => {
    if (sort === 'employee') {
      if (order === 'asc') {
        setSortParams([
          { field: 'userFirstName', order: 'asc' },
          { field: 'userLastName', order: 'asc' },
        ])
      } else {
        setSortParams([
          { field: 'userLastName', order: 'desc' },
          { field: 'userFirstName', order: 'desc' },
        ])
      }
    } else {
      setSortParams([{ field: sort, order }])
    }
  }, [sort, order])

  useEffect(() => {
    if (fetchedPeriods) {
      setPeriods(fetchedPeriods.periods || [])

      // Select the first root period if available
      if (
        fetchedPeriods.periods &&
        fetchedPeriods.periods.length > 0 &&
        selectedPeriods.length === 0 &&
        !periodsInitialized
      ) {
        setPeriodsInitialized(true)
      }
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (fetchedPeriods && fetchedPeriods.periods.length > 0) {
      const rootPeriod = fetchedPeriods.periods[0]
      const idLabelMap = createIdLabelMap(rootPeriod)
      setPeriodLabels(idLabelMap)
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (profile && profile.roles.length > 0 && !isInitialized) {
      const allOrganizationIds = profile.roles.map((role) => role.organization.id)
      if (selectedOrganizations.length === 0) {
        setSelectedOrganizations(allOrganizationIds)
      }
      setIsInitialized(true)
    }
  }, [profile, isInitialized])

  const queryParams: AccrualsQueryParams = {
    organizationIds: selectedOrganizations,
    periodIds: selectedPeriods,
    status,
    searchTerm,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: sortParams,
  }

  const {
    accruals = [],
    pagination,
    isLoading,
    isError,
  } = useAccruals(
    token!,
    queryParams,
    !!token && selectedOrganizations.length > 0 && selectedPeriods.length > 0 && isInitialized
  )

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    startTransition(() => {
      setPage(newPage)
    })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    startTransition(() => {
      setRowsPerPage(Number.parseInt(event.target.value, 10))
      setPage(0)
    })
  }

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const organizationLabels: Record<string, string> = profile!.roles.reduce(
    (acc: Record<string, string>, role: UserRole) => {
      acc[role.organization.id] = role.organization.name
      return acc
    },
    {} as Record<string, string>
  )

  const ledgerAccountLabels: Record<string, string> =
    ledgerAccounts?.reduce(
      (acc: Record<string, string>, ledgerAccount: LedgerAccount) => {
        acc[ledgerAccount.id] = ledgerAccount.label
        return acc
      },
      {} as Record<string, string>
    ) || {}

  // const handleViewAccrual = (accrual: any) => {
  //   setSelectedAccrual(accrual)
  //   setOpenDialog(true)
  // }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedAccrual(null)
  }

  const columns: Column[] = [
    {
      id: 'employee',
      label: 'Employee',
      width: 200,
    },
    {
      id: 'organizationName',
      label: 'Team',
      width: 150,
      format: (row) => row.organizationName || '-',
    },
    {
      id: 'periodId',
      label: 'Period',
      width: 150,
      format: (row) => periodLabels[row.periodId] || row.periodId,
    },
    {
      id: 'status',
      label: 'Status',
      width: 100,
      format: (row) => row.status.charAt(0).toUpperCase() + row.status.slice(1),
    },
    {
      id: 'lineItem',
      label: 'Line Item',
      width: 200,
      format: (row) =>
        row.lineItemCount
          ? `(${row.lineItemCount}) Line Items`
          : `${row.allocationTableName} | ${row.allocationRowName}`,
    },
    {
      id: 'total',
      label: 'Balance',
      width: 100,
      format: (row) =>
        row.proratedPayoutAmount !== null
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Number(row.proratedPayoutAmount))
          : '-',
    },
    // {
    //   id: 'actions',
    //   label: 'Actions',
    //   width: 100,
    //   format: (row) => (
    //     <IconButton onClick={() => handleViewAccrual(row)}>
    //       <EyeOutlined />
    //     </IconButton>
    //   ),
    // },
  ]

  const handleOrganizationChange = (newSelectedOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newSelectedOrganizations)
      setPage(0)
    })
  }

  const handlePeriodChange = (newSelectedPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newSelectedPeriods)
      setPage(0)
    })
  }

  return (
    <>
      <Grid container pb={2}>
        <Grid size={{ xs: 4 }}>
          <SimpleTitle title="Progress" />
        </Grid>
        <Grid size={{ xs: 8 }}></Grid>
      </Grid>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 4 }}>
          <SearchInput
            id="accrual-search-input"
            key="accrual-search-input"
            onSearch={handleSearch}
            initialValue={searchTerm}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink sx={{ '&.MuiInputLabel-shrink': { background: theme.palette.background.default } }}>
              Select Approval Status
            </InputLabel>
            <Select
              fullWidth
              value={status}
              onChange={(e) => setStatus(e.target.value as AccrualsQueryParams['status'])}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: hoverColor,
                    },
                  },
                },
              }}
              style={{ borderRadius: '21px' }}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 2 }}>
          <MultiPeriodSelector
            key="period-selector"
            periods={periods}
            selectedPeriods={selectedPeriods}
            handleChange={handlePeriodChange}
            rootPeriodOnly={false}
            sortOrder="asc"
            timeFilter="pastOnly"
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Grid container>
            <Grid size={{ xs: 11 }}>
              <MultiOrganizationSelector
                key="organization-selector"
                userProfile={profile!}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
                labelBackgroundColor={theme.palette.background.default}
              />
            </Grid>
            <Grid size={{ xs: 1 }}>
              <Tooltip title="Clear Filters">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setStatus('Draft')
                    setSelectedPeriods([])
                    setSelectedOrganizations([])
                    setSearchTerm('')
                  }}
                >
                  <CloseCircleOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Suspense fallback={<CircularProgress />}>
        {isLoadingPeriods || isLoading || isPending || isLoadingLedgerAccounts ? (
          <CircularProgress />
        ) : isError ? (
          <Typography color="error">Error loading accruals</Typography>
        ) : (
          <Box>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" size="small" component={Paper}>
                <TableHead
                  sx={{
                    '& th': {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `2px solid ${theme.palette.divider} !important`,
                    },
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} style={{ width: column.width, minWidth: column.width }}>
                        {column.id === 'actions' ||
                        column.id === 'periodId' ||
                        column.id === 'lineItem' ||
                        column.id === 'account' ||
                        column.id === 'total' ? (
                          column.label
                        ) : (
                          <TableSortLabel
                            active={sort === column.id}
                            direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                            onClick={() => handleSort(column.id as string)}
                          >
                            {column.label}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accruals.map((accrual) => (
                    <TableRow key={accrual.id} role="checkbox" tabIndex={-1}>
                      {columns.map((column) => (
                        <TableCell key={column.id} style={{ width: column.width }}>
                          {column.id === 'employee'
                            ? `${accrual.userFirstName} ${accrual.userLastName}`.trim()
                            : column.format
                              ? column.format(accrual)
                              : accrual[column.id as keyof typeof accrual]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[100, 250, 500]}
              component="div"
              count={pagination?.totalItems || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Suspense>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogContent>
          {selectedAccrual && (
            <AccrualDetails
              accrual={selectedAccrual}
              organizationLabels={organizationLabels}
              periodLabels={periodLabels}
              ledgerAccountLabels={ledgerAccountLabels}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ManageAccruals
