import type { ReactNode } from 'react'
// import { useLocation } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'

function ProtectedRoute({ children }: { children: ReactNode }) {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0()
  // const location = useLocation();

  console.log('ProtectedRoute Debug:', {
    isAuthenticated,
    isLoading,
    user,
    cookies: document.cookie, // Check if cookies exist
    location: window.location.href,
  })

  if (isLoading) {
    console.log('Auth is loading...')
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    console.log('Not authenticated, redirecting...')
    // loginWithRedirect({
    //   appState: { returnTo: location.pathname + location.search },
    // });
    void loginWithRedirect()
    return null
  }

  console.log('Authenticated, rendering children')
  return <>{children}</>
}

export { ProtectedRoute }
