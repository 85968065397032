import type { FC } from 'react'
import { Grid2 as Grid } from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import { deleteWithToken } from 'hooks/http'
import TemplatesTable from './TemplatesTable'

function deleteTemplate(token: string, id: string): Promise<{ message: string }> {
  return deleteWithToken(`/compensation/templates/${id}`, token)
}

const Templates: FC = () => {
  return (
    <>
      <Grid container pb={4}>
        <Grid size={{ xs: 4 }}>
          <SimpleTitle title="Templates" />
        </Grid>
        <Grid size={{ xs: 8 }} />
      </Grid>
      <TemplatesTable withCreateButtons deleteTemplate={deleteTemplate} />
    </>
  )
}

export default Templates
