import type { PaperProps as MuiPaperProps, SxProps } from '@mui/material'
import type { FC } from 'react'
import { Paper as MuiPaper } from '@mui/material'
import React from 'react'

interface PaperProps extends MuiPaperProps {
  children: React.ReactNode
  sx?: SxProps
}

const Paper: FC<PaperProps> = ({ children, sx, ...rest }) => {
  return (
    <MuiPaper
      sx={{
        borderRadius: '15px',
        padding: '15px',
        ...sx,
      }}
      elevation={0}
      {...rest}
    >
      {children}
    </MuiPaper>
  )
}

export default Paper
