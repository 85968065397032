import { Autocomplete, CircularProgress, Grid2 as Grid, Stack, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Paper from 'components/@extended/Paper'
import { TextField } from 'components/@extended/TextField'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useCreatePlanContext } from 'contexts/CreatePlanContextProvider'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import { useFetchUser, useSearchUsers } from 'hooks/useUsers'
import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import type { CompensationPlan } from 'types/comp-plan'
import { findPeriodById } from 'types/periods'
import type { UserRole } from 'types/role'
import type { User } from 'types/user'
import type { UserProfile } from 'types/user-profile'
import { getMemberRole } from 'types/user-profile'

const EmployeeDetailForm: FC = () => {
  // Context and Auth
  const { token } = useAuth()
  const { compensationTemplate, plan, setPlan, mode, setEmployeeName, originalEffectiveDate, withUserId } =
    useCreatePlanContext()

  // State
  const [profile, setProfile] = useState<UserProfile>({
    roles: [] as UserRole[],
    user: { firstName: '', lastName: '', position: '' } as User,
  } as UserProfile)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState<string>('')
  const [effectiveDate, setEffectiveDate] = useState<dayjs.Dayjs | null>(null)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [hasInitializedEffectiveDate, setHasInitializedEffectiveDate] = useState(false)

  // Fetching Users
  const { users, isLoading: userSearchIsLoading } = useSearchUsers(
    token!,
    {
      organizationIds: compensationTemplate?.template.organizationIds,
      perPage: 1000,
    },
    compensationTemplate != null && compensationTemplate.template.organizationIds != null
  )

  const userId = withUserId || (plan && plan.userId ? plan.userId : null)
  const { userProfile, isLoading: userIsLoading } = useFetchUser(
    token!,
    userId,
    [['explicitRolesOnly', 'true']],
    !!userId
  )

  // Extract the period end date from the compensation template
  const maxEffectiveDate = useMemo(() => {
    if (compensationTemplate?.period && compensationTemplate.template.periodId) {
      const matchingPeriod = findPeriodById([compensationTemplate.period], compensationTemplate.template.periodId)
      return matchingPeriod?.endDate ? dayjs(matchingPeriod.endDate) : undefined
    }
    return undefined
  }, [compensationTemplate])

  // Extract the period end date from the compensation template
  const templatePeriodStartDate = useMemo(() => {
    if (compensationTemplate?.period && compensationTemplate.template.periodId) {
      const matchingPeriod = findPeriodById([compensationTemplate.period], compensationTemplate.template.periodId)
      return matchingPeriod?.startDate ? dayjs(matchingPeriod.startDate) : undefined
    }
    return undefined
  }, [compensationTemplate])

  // Effects
  useEffect(() => {
    if (!userIsLoading && !userSearchIsLoading) {
      setIsDataLoaded(true)
    }
  }, [userIsLoading, userSearchIsLoading, userProfile])

  useEffect(() => {
    if (!hasInitializedEffectiveDate && maxEffectiveDate !== undefined && templatePeriodStartDate !== undefined) {
      if (plan.effectiveDate) {
        console.log('setting plan.effectiveDate')
        setEffectiveDate(dayjs(plan.effectiveDate))
      } else {
        const today = dayjs()

        if (maxEffectiveDate && today.isAfter(maxEffectiveDate)) {
          console.log('setting maxEffectiveDate')
          setEffectiveDate(templatePeriodStartDate)
        } else {
          console.log('setting today')
          setEffectiveDate(today)
        }
      }
      setHasInitializedEffectiveDate(true)
    }
  }, [plan.effectiveDate, hasInitializedEffectiveDate, maxEffectiveDate, templatePeriodStartDate])

  useEffect(() => {
    const tempPlan = { ...plan }
    // tempPlan.organizationId = ''
    if (effectiveDate) {
      tempPlan.effectiveDate = effectiveDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    } else {
      tempPlan.effectiveDate = ''
    }
    setPlan(tempPlan)
  }, [effectiveDate])

  useEffect(() => {
    if (userProfile && userProfile.roles && userProfile.roles.length > 0) {
      const memberRole = getMemberRole(userProfile)
      if (memberRole) {
        setOrganizationHandler(memberRole.organization.id)
      }
    }
  }, [userProfile])

  useEffect(() => {
    if (isDataLoaded && userProfile?.user) {
      setEmployeeName(`${userProfile.user.firstName} ${userProfile.user.lastName}`)
      if (!plan.position) {
        setPlan((prevPlan: CompensationPlan) => ({ ...prevPlan, position: '' }))
      }
      if (!plan.userId) {
        setPlan((prevPlan: CompensationPlan) => ({
          ...prevPlan,
          userId: userProfile.user.id,
          position: userProfile.user.position,
        }))
      }
      setProfile(userProfile)
    }
  }, [isDataLoaded, userProfile])

  // Handlers
  const setOrganizationHandler = (value: string) => {
    setSelectedOrganization(value)
    const tempPlan = { ...plan }
    tempPlan.organizationId = value
    setPlan(tempPlan)
  }

  const onChangeEffectiveDate = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      const userStartDate = profile.user?.startDate ? dayjs(profile.user.startDate) : null
      if (userStartDate && newValue.isBefore(userStartDate)) {
        setEffectiveDate(userStartDate)
        const tempPlan = { ...plan }
        tempPlan.effectiveDate = userStartDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        setPlan(tempPlan)
        return
      }

      setEffectiveDate(newValue)
      const tempPlan = { ...plan }
      tempPlan.effectiveDate = newValue.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setPlan(tempPlan)
    }
  }

  const onChangeSelectedEmployee = (event: any, newValue: User | null) => {
    if (compensationTemplate) {
      setSelectedUser(newValue)

      const tempPlan = { ...plan }
      tempPlan.userId = newValue?.id || ''
      tempPlan.position = newValue?.position || ''
      setEmployeeName(newValue ? `${newValue.firstName} ${newValue.lastName}` : '')
      setPlan(tempPlan)
    }
  }

  const onChangePosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempPlan = { ...plan }
    tempPlan.position = event.target.value
    setPlan(tempPlan)
  }

  // Loading State
  if (userSearchIsLoading || userIsLoading || !isDataLoaded) {
    return (
      <Grid container spacing={12}>
        <Grid
          size={{ xs: 12 }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress color="primary" />
          Loading
        </Grid>
      </Grid>
    )
  }

  console.log('maxEffectiveDate', maxEffectiveDate)

  // JSX Return
  return (
    <Paper sx={{ p: 3 }}>
      <Grid container size={{ xs: 12 }} spacing={4}>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h4">Employee Details</Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid size={{ xs: 12, lg: 4 }}>
              <TextField
                fullWidth
                label="Template Name"
                InputProps={{
                  readOnly: true,
                }}
                value={compensationTemplate?.template.label}
                variant="outlined"
                placeholder="Template Name"
                disabled={!!plan.id}
                shrink
                pill
              />
            </Grid>
            <Grid size={{ xs: 12, lg: 4 }}>
              <TextField
                fullWidth
                label="Intended Role"
                InputProps={{
                  readOnly: true,
                }}
                value={compensationTemplate?.template.position}
                variant="outlined"
                disabled={!!plan.id}
                shrink
                pill
              />
            </Grid>
            <Grid size={{ xs: 12, lg: 4 }}>
              <TextField
                label="Position"
                fullWidth
                type="text"
                placeholder="Position"
                value={plan.position || ''}
                onChange={onChangePosition}
                InputProps={{
                  readOnly: mode === 'REVIEW',
                }}
                variant="outlined"
                shrink
                pill
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container size={{ xs: 12 }} spacing={2} alignItems="flex-start">
          <Grid size={{ xs: 12, lg: 4 }}>
            {userProfile?.user && (plan.id || withUserId) ? (
              <TextField
                key="employee-select"
                fullWidth
                label="Employee Name"
                InputProps={{
                  readOnly: true,
                }}
                value={
                  profile.user &&
                  profile.user.firstName &&
                  profile.user.lastName &&
                  profile.user.firstName !== '' &&
                  profile.user.lastName !== ''
                    ? `${profile.user.firstName} ${profile.user.lastName}`
                    : null
                }
                variant="outlined"
                shrink
                pill
              />
            ) : (
              <Autocomplete
                id="employee-select"
                options={users}
                fullWidth
                autoHighlight
                getOptionLabel={(option) => (option ? `${option.firstName} ${option.lastName}` : '')}
                onChange={onChangeSelectedEmployee}
                value={selectedUser}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee Name"
                    placeholder="Select Employee"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                    shrink
                    pill
                  />
                )}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, lg: 4 }}>
            <SingleOrganizationSelector
              key="employee-organization-select"
              userProfile={profile}
              selectedOrganizationId={selectedOrganization}
              handleChange={(value) => setOrganizationHandler(value)}
              showLabelAbove
              style={{ marginTop: 12, paddingBottom: 0 }}
              memberRolesOnly
              variant="outlined"
              disabled={
                !plan.userId ||
                (selectedOrganization !== '' &&
                  userProfile &&
                  userProfile.roles.filter((role) => role.role.name === 'Member').length === 1)
              }
              space={1}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 4 }}>
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Effective Date"
                  value={effectiveDate}
                  onChange={onChangeEffectiveDate}
                  disabled={mode === 'REVIEW'}
                  minDate={profile.user?.startDate ? dayjs(profile.user.startDate) : undefined}
                  maxDate={maxEffectiveDate}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      InputLabelProps: {
                        shrink: true,
                        sx: {
                          '&.Mui-focused': {
                            color: (theme) => theme.palette.secondary.main,
                          },
                        },
                      },
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          borderRadius: (theme) => theme.shape.borderRadius * 5.25,
                          '& fieldset': {
                            borderColor: (theme) => theme.palette.primary.main,
                          },
                          '&:hover fieldset': {
                            borderColor: (theme) => theme.palette.primary.dark,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: (theme) => theme.palette.secondary.main,
                          },
                        },
                      },
                      helperText: originalEffectiveDate
                        ? `Original effective date: ${dayjs(originalEffectiveDate).format('MMM D, YYYY')}`
                        : profile.user?.startDate
                          ? `Employee start date: ${dayjs(profile.user.startDate).format('MMM D, YYYY')}`
                          : undefined,
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default EmployeeDetailForm
