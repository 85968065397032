import type { FC, JSX } from 'react'
import type { ClosePeriodAndWriteAccrualsRequest, PrepareLedgerItem } from 'types/accruals'
import type { Period } from 'types/periods'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import CurrencyField from 'components/CurrencyField'
import PercentageField from 'components/PercentageField'
import { usePrepareLedger } from 'hooks/useAccruals'
import { useEffect, useState } from 'react'
import { ThemeMode } from 'types/config'

interface PeriodClosureDialogProps {
  open: boolean
  selectedPeriod: Period | null
  onClose: () => void
  onConfirm: (request: ClosePeriodAndWriteAccrualsRequest) => void
  findAllOpenDescendants: (period: Period) => Period[]
  token: string
  periodIdToLabel: Record<string, string>
}

interface PeriodsColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify'
  format?: (value: any, row: Period) => string | JSX.Element
}

interface LedgerColumnProps {
  id: string
  label: string
  minWidth: number
  width?: string
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify'
  format?: (value: any, row: PrepareLedgerItem) => string | JSX.Element | number
}

// Add new interface for adjustment types
interface AdjustmentValue {
  percentChange: number
  fixedChange: number
  activeType: 'percentage' | 'currency'
  currentBalance: number
  originalBalance: number
}

// Add a default adjustment value constant
const DEFAULT_ADJUSTMENT: AdjustmentValue = {
  percentChange: 1.0,
  fixedChange: 0.0,
  activeType: 'percentage' as const,
  currentBalance: 0.0,
  originalBalance: 0.0,
}

const PeriodClosureDialog: FC<PeriodClosureDialogProps> = ({
  open,
  selectedPeriod,
  onClose,
  onConfirm,
  findAllOpenDescendants,
  token,
  periodIdToLabel,
}) => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const [adjustments, setAdjustments] = useState<Record<string, AdjustmentValue>>({})
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({})

  const SELECT_PERIODS_STEP = 0
  const ADJUSTMENT_STEP = 1
  const REVIEW_STEP = 3

  console.log(selectedPeriod)

  const { ledgerItems, isLoading } = usePrepareLedger({
    token,
    rootOrganizationId: selectedPeriod?.organizationId || '',
    rootPeriodId: selectedPeriod?.rootPeriodId || '',
    periodIds: selectedPeriod
      ? findAllOpenDescendants(selectedPeriod)
          .map((p) => p.id || '')
          .filter((id) => id !== '')
      : [],
    shouldFetch: open && activeStep >= 1 && selectedPeriod?.rootPeriodId != '',
  })

  useEffect(() => {
    if (open) {
      setActiveStep(0)
      setAdjustments({})
    }
  }, [open])

  useEffect(() => {
    if (ledgerItems) {
      const initialChecked = ledgerItems.reduce(
        (acc, item) => ({
          ...acc,
          [getCompositeId(item)]: true,
        }),
        {}
      )
      setCheckedItems(initialChecked)
    }
  }, [ledgerItems])

  useEffect(() => {
    if (ledgerItems) {
      const initialAdjustments = ledgerItems.reduce(
        (acc, item) => ({
          ...acc,
          [getCompositeId(item)]: {
            percentChange: 1.0,
            fixedChange: item.balance,
            activeType: 'percentage' as const,
            currentBalance: item.balance,
            originalBalance: item.balance,
          },
        }),
        {}
      )
      setAdjustments(initialAdjustments)
    }
  }, [ledgerItems])

  // Use ledgerItems directly instead of creating a new mapped array
  const lineItems: PrepareLedgerItem[] = ledgerItems ?? []

  const handleNext = () => {
    switch (activeStep) {
      case SELECT_PERIODS_STEP:
        setActiveStep(ADJUSTMENT_STEP)
        break
      case ADJUSTMENT_STEP:
        setActiveStep(REVIEW_STEP)
        break
      case REVIEW_STEP:
        // Prepare the request object
        const request: ClosePeriodAndWriteAccrualsRequest = {
          rootOrganizationId: selectedPeriod?.organizationId || '',
          rootPeriodId: selectedPeriod?.rootPeriodId || '',
          periodsToClose: selectedPeriod
            ? findAllOpenDescendants(selectedPeriod)
                .map((p) => p.id || '')
                .filter((id) => id !== '')
            : [],
          accrualLedgerEntries: lineItems
            .filter((item) => checkedItems[getCompositeId(item)])
            .map((item) => ({
              userId: item.userId,
              rootOrganizationId: item.rootOrganizationId,
              organizationId: item.organizationId,
              ledgerAccountId: item.ledgerAccountId,
              planId: item.planId,
              planRevision: item.planRevision,
              allocationTableId: item.allocationTableId,
              allocationTableName: item.allocationTableName,
              allocationRowId: item.allocationRowId,
              allocationRowName: item.allocationRowName,
              rootPeriodId: item.rootPeriodId,
              periodId: item.periodId,
              balance: getAdjustedBalance(item),
            })),
        }
        onConfirm(request)
        break
    }
  }

  const handleBack = () => {
    switch (activeStep) {
      case ADJUSTMENT_STEP:
        setActiveStep(SELECT_PERIODS_STEP)
        break
      case REVIEW_STEP:
        setActiveStep(ADJUSTMENT_STEP)
        break
    }
  }

  const handleAdjustmentChange = (compositeId: string) => (value: string) => {
    setAdjustments((prev) => {
      const adjustment = prev[compositeId]
      const newValue = Number.parseFloat(value) || 0
      const isPercentage = adjustment.activeType === 'percentage'

      const newCurrentBalance = isPercentage ? adjustment.originalBalance * newValue : newValue

      return {
        ...prev,
        [compositeId]: {
          ...adjustment,
          [isPercentage ? 'percentChange' : 'fixedChange']: newValue,
          currentBalance: newCurrentBalance,
          // When percentage changes, update fixedChange to match the new current balance
          ...(isPercentage && { fixedChange: newCurrentBalance }),
        },
      }
    })
  }

  const handleFixedAdjustmentChange = (compositeId: string) => (value: string) => {
    setAdjustments((prev) => {
      const adjustment = prev[compositeId]
      const newValue = Number.parseFloat(value) || 0

      return {
        ...prev,
        [compositeId]: {
          ...adjustment,
          fixedChange: newValue,
          currentBalance: newValue,
        },
      }
    })
  }

  // Add getter function for adjustments
  const getAdjustment = (row: PrepareLedgerItem): AdjustmentValue => {
    const compositeId = getCompositeId(row)
    return adjustments[compositeId] || { ...DEFAULT_ADJUSTMENT, fixedChange: row.balance }
  }

  // Update getAdjustedBalance to use the getter
  const getAdjustedBalance = (row: PrepareLedgerItem) => {
    const adjustment = getAdjustment(row)
    return adjustment.currentBalance
  }

  const handleCheckboxChange = (compositeId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems((prev) => ({
      ...prev,
      [compositeId]: event.target.checked,
    }))
  }

  const getCompositeId = (item: PrepareLedgerItem) => `${item.allocationTableId}-${item.allocationRowId}`

  const renderStepContent = () => {
    const formatMoney = (amount: number) => {
      return amount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }

    const periodsColumns: PeriodsColumnProps[] = [
      {
        id: 'period_label',
        label: `Period (${selectedPeriod ? findAllOpenDescendants(selectedPeriod).length : 0})`,
        minWidth: 170,
      },
      {
        id: 'period_startDate',
        label: 'Start Date',
        minWidth: 120,
        format: (value) => new Date(value).toLocaleDateString(),
      },
      {
        id: 'period_endDate',
        label: 'End Date',
        minWidth: 120,
        format: (value) => new Date(value).toLocaleDateString(),
      },
    ]

    const getTotalBalance = () => {
      const total = lineItems
        .filter((item) => checkedItems[getCompositeId(item)] ?? true)
        .reduce((sum, item) => sum + getAdjustment(item).currentBalance, 0)
      return formatMoney(total)
    }

    const getTotalBalanceChange = () => {
      const total = lineItems
        .filter((item) => checkedItems[getCompositeId(item)] ?? true)
        .reduce((sum, item) => sum + (getAdjustment(item).currentBalance - item.previousBalance), 0)
      return formatMoney(total)
    }

    const ledgerColumns: LedgerColumnProps[] = [
      {
        id: 'ledger_checkbox',
        label: 'Include',
        minWidth: 70,
        width: '5%',
        format: (value, row) => {
          const compositeId = getCompositeId(row)
          return <Checkbox checked={checkedItems[compositeId] ?? true} onChange={handleCheckboxChange(compositeId)} />
        },
      },
      {
        id: 'ledger_lineItem',
        label: `Line Item (${lineItems.length})`,
        minWidth: 170,
        width: '35%',
        format: (value, row) =>
          `${row.firstName} ${row.lastName} | ${periodIdToLabel[row.periodId] || 'Unknown Period'} | ${row.allocationTableName} | ${row.allocationRowName}`,
      },
      {
        id: 'ledger_percentage',
        label: 'Adjustment %',
        minWidth: 120,
        width: '15%',
        format: (value, row) => {
          const compositeId = getCompositeId(row)
          const adjustment = getAdjustment(row)
          const isChecked = checkedItems[compositeId] ?? true

          return (
            <PercentageField
              value={adjustment.percentChange.toString()}
              onChange={handleAdjustmentChange(compositeId)}
              name={`percentage-${compositeId}`}
              style={{ width: '100%' }}
              variant="outlined"
              disabled={!isChecked}
              decimalPlaces={2}
            />
          )
        },
      },
      {
        id: 'ledger_balance',
        label: 'FY to Date Balance',
        minWidth: 120,
        width: '15%',
        format: (value, row) => `$${formatMoney(getAdjustedBalance(row))}`,
      },
      {
        id: 'ledger_fixed',
        label: 'Adjusted Balance',
        minWidth: 120,
        width: '15%',
        format: (value, row) => {
          const compositeId = getCompositeId(row)
          const adjustment = getAdjustment(row)
          const isChecked = checkedItems[compositeId] ?? true

          return (
            <CurrencyField
              key={`fixed-${compositeId}-field`}
              value={adjustment.fixedChange.toString()}
              onChange={handleFixedAdjustmentChange(compositeId)}
              name={`fixed-${compositeId}`}
              style={{ width: '100%' }}
              variant="outlined"
              disabled={!isChecked}
              currency="USD"
              currencyDecimals={2}
            />
          )
        },
      },
      {
        id: 'ledger_balance_change',
        label: 'Balance Change',
        minWidth: 120,
        width: '15%',
        format: (value, row) => {
          const adjustment = getAdjustment(row)
          const change = adjustment.currentBalance - row.previousBalance
          return `$${formatMoney(change)}`
        },
      },
    ]

    const reviewColumns: LedgerColumnProps[] = [
      {
        id: 'review_lineItem',
        label: `Line Item (${lineItems.length})`,
        minWidth: 170,
        width: '70%',
        format: (value, row) =>
          `${row.firstName} ${row.lastName} | ${periodIdToLabel[row.periodId] || 'Unknown Period'} | ${row.allocationTableName} | ${row.allocationRowName}`,
      },
      {
        id: 'review_balance',
        label: `FY to Date Balance`,
        minWidth: 120,
        width: '15%',
        format: (value, row) => `$${formatMoney(getAdjustedBalance(row))}`,
      },
      {
        id: 'review_balance_change',
        label: 'Balance Change',
        minWidth: 120,
        width: '15%',
        format: (value, row) => {
          const adjustment = getAdjustment(row)
          const change = adjustment.currentBalance - row.previousBalance
          return `$${formatMoney(change)}`
        },
      },
    ]

    const PeriodsTable = () => (
      <TableContainer
        component={Paper}
        sx={{
          flex: 1,
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Table stickyHeader size="small">
          <TableHead
            sx={{
              '& th': {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `2px solid ${theme.palette.divider} !important`,
              },
            }}
          >
            <TableRow>
              {periodsColumns.map((column) => (
                <TableCell
                  key={`periods-column-${column.id}-cell`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    position: 'sticky !important',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedPeriod &&
              findAllOpenDescendants(selectedPeriod)
                .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
                .map((row) => (
                  <TableRow key={`periods-row-${row.id}`}>
                    {periodsColumns.map((column) => {
                      const value = row[column.id.replace('period_', '') as keyof Period]
                      return (
                        <TableCell key={`periods-column-${column.id}-cell`} align={column.align}>
                          {column.format ? column.format(value, row) : String(value)}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    )

    switch (activeStep) {
      case SELECT_PERIODS_STEP:
        return (
          <>
            <p>
              The following periods will be closed and their most recent approved accrual progress will be written to
              the accrual ledger. Additionally, any approved progress for parent periods (e.g., Quarters, Half-Years,
              and Fiscal Year) will also be written to the accrual ledger.
            </p>
            <PeriodsTable />
          </>
        )
      case ADJUSTMENT_STEP:
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 0 }}>
              <p style={{ margin: 0, marginRight: 30 }}>
                You can adjust the balances below before they are written to the accrual ledger. Enter adjustment
                percentages to modify the final amounts that will be recorded.
              </p>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Paper sx={{ p: 1.5, minWidth: '150px', border: 1, borderColor: 'divider' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    FY to Date Balance
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                    ${getTotalBalance()}
                  </Typography>
                </Paper>
                <Paper sx={{ p: 1.5, minWidth: '150px', border: 1, borderColor: 'divider' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Balance Change
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                    ${getTotalBalanceChange()}
                  </Typography>
                </Paper>
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                flex: 1,
                overflow: 'auto',
                position: 'relative',
                marginTop: 2,
              }}
            >
              <Table stickyHeader size="small">
                <TableHead
                  sx={{
                    '& th': {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `2px solid ${theme.palette.divider} !important`,
                    },
                  }}
                >
                  <TableRow>
                    {ledgerColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, width: column.width }}
                        sx={{
                          position: 'sticky !important',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* ... existing loading state handling ... */}
                  {!isLoading &&
                    lineItems.map((row) => {
                      const compositeId = getCompositeId(row)
                      const isChecked = checkedItems[compositeId] ?? true
                      return (
                        <TableRow
                          key={getCompositeId(row)}
                          sx={{
                            opacity: isChecked ? 1 : 0.5,
                            backgroundColor: (theme) =>
                              !isChecked ? (theme.palette.mode === 'light' ? '#f5f5f5' : '#2a2a2a') : 'inherit',
                          }}
                        >
                          {ledgerColumns.map((column) => {
                            const value = row[column.id as keyof typeof row]
                            return (
                              <TableCell key={column.id} align={column.align} style={{ width: column.width }}>
                                {column.format ? column.format(value, row) : value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      case REVIEW_STEP:
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 0 }}>
              <Box>
                <p>Please review all information before confirming the period closure.</p>
                <p
                  style={{
                    color:
                      theme.palette.mode === ThemeMode.LIGHT
                        ? theme.palette.warning.darker
                        : theme.palette.warning.main,
                  }}
                >
                  Warning: These balances will enter the approval process and will overwrite any unapproved balances in
                  the ledger. Once approved, these balances will be permanent and cannot be removed or adjusted.
                </p>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Paper sx={{ p: 1.5, minWidth: '150px', border: 1, borderColor: 'divider' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    FY to Date Balance
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                    ${getTotalBalance()}
                  </Typography>
                </Paper>
                <Paper sx={{ p: 1.5, minWidth: '150px', border: 1, borderColor: 'divider' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Balance Change
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                    ${getTotalBalanceChange()}
                  </Typography>
                </Paper>
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                flex: 1,
                overflow: 'auto',
                position: 'relative',
                marginTop: 3,
              }}
            >
              <Table stickyHeader size="small">
                <TableHead
                  sx={{
                    '& th': {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `2px solid ${theme.palette.divider} !important`,
                    },
                  }}
                >
                  <TableRow>
                    {reviewColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, width: column.width }}
                        sx={{
                          position: 'sticky !important',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItems
                    .filter((row) => checkedItems[getCompositeId(row)])
                    .map((row) => (
                      <TableRow key={getCompositeId(row)}>
                        {reviewColumns.map((column) => {
                          const value = row[column.id as keyof typeof row]
                          return (
                            <TableCell key={column.id} align={column.align} style={{ width: column.width }}>
                              {column.format ? column.format(value, row) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      }}
    >
      <DialogTitle>
        Confirm Period Closure
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          <Step>
            <StepLabel>Select Periods</StepLabel>
          </Step>
          <Step>
            <StepLabel>Adjust Balances</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review</StepLabel>
          </Step>
        </Stepper>
        {renderStepContent()}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          pb: 2,
        }}
      >
        <Button onClick={onClose} variant="contained" color="warning">
          Cancel
        </Button>
        <div>
          {activeStep > SELECT_PERIODS_STEP && (
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          <Button onClick={handleNext} variant="contained" color="primary">
            {activeStep === REVIEW_STEP ? 'Confirm' : 'Next'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default PeriodClosureDialog
