import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'

const Logout: React.FC = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    localStorage.clear()
    // On load, log out the user and redirect to the homepage (or origin)
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL,
      },
    })
  }, [logout])

  return null
}

export default Logout
