import type { SelectChangeEvent } from '@mui/material'
import type { JSX } from 'react'
import type { KeyedObject } from 'types/root'
import type { UserQueryParams, UserWithPlanInfo } from 'types/user'
import {
  AuditOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  PlusOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'components/@extended/AnimateButton'
import Paper from 'components/@extended/Paper'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import useLocalStorage from 'hooks/useLocalStorage'
import { useSearchUsersWithPlanInfo } from 'hooks/useUsers'
import * as React from 'react'
import { startTransition, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Permissions } from 'types/permissions'
import { getOrganizationIdsWithPermissions, getOrganizationLabelMap } from 'types/user-profile'

const Organization: React.FC = () => {
  const [search, setSearch] = useState('')
  const theme = useTheme()
  const [page, setPage] = useLocalStorage<number>('team-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('team-rows-per-page', 100)
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>('team-selected-organizations', [])
  const [planFilters, setPlanFilters] = useLocalStorage<string[]>('team-plan-filters', [
    'withoutPlans',
    'unapproved',
    'approved',
  ])
  const [sort, setSort] = useLocalStorage<string>('team-sort', 'lastName')
  const [order, setOrder] = useLocalStorage<string>('team-order', 'asc')
  const tableContainerRef = useRef(null)

  const { token, profile } = useAuth()

  const queryParams: UserQueryParams = {
    organizationIds: selectedOrganizations,
    searchTerm: search,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order }],
    includeWithoutPlans: planFilters.includes('withoutPlans'),
    includeUnapproved: planFilters.includes('unapproved'),
    includeApproved: planFilters.includes('approved'),
  }

  const {
    users = [],
    isLoading,
    pagination = { totalItems: 0, totalPages: 1 },
  } = useSearchUsersWithPlanInfo(token!, queryParams)

  const organizationIdsWithMembersWrite =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.MEMBERS_WRITE]) : []
  const organizationIdsWithPlansRead =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.PLANS_READ]) : []
  const organizationIdsWithPlanWrite =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.PLANS_WRITE]) : []

  const canManageTeamStructure = organizationIdsWithMembersWrite.length > 0
  const canManagePlans = organizationIdsWithPlansRead.length > 0

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  useEffect(() => {
    if (tableContainerRef.current) {
      ;(tableContainerRef.current as HTMLElement).scrollTop = 0
    }
  }, [sort, order, page, rowsPerPage])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleFilterChange = (event: SelectChangeEvent<string[]>) => {
    setPlanFilters(event.target.value as string[])
  }

  // table columns
  interface ColumnProps {
    id: string
    label: string
    sx?: any
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
    format?: (value: any, row: any) => string | boolean | JSX.Element
  }

  const navigate = useNavigate()

  const columns: ColumnProps[] = [
    {
      id: 'firstName',
      label: 'First Name',
      sx: { minWidth: 100, maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      format: (value: any, row: any) => row.firstName,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      sx: { minWidth: 100, maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      format: (value: any, row: any) => row.lastName,
    },
    {
      id: 'organizationName',
      label: 'Team',
      sx: {
        minWidth: 170,
        maxWidth: 250,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      format: (value: any, row: UserWithPlanInfo) => row.organizationName,
    },
    {
      id: 'position',
      label: 'Position',
      sx: {
        minWidth: 170,
        maxWidth: 250,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left',
      sx: {
        minWidth: 170,
        maxWidth: 250,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      sx: {
        minWidth: 100,
        maxWidth: 100,
      },
      format: (userId: string, row: UserWithPlanInfo) => {
        // const hasApprovedPlan = row.planApproved
        const hasUnapprovedPlan = row.approvalBatchId != null && !row.planApproved
        const hasNoPlan = !row.approvalBatchId

        return (
          <Box display="flex">
            {organizationIdsWithPlansRead.includes(row.organizationId) && (
              <Tooltip title="View Employee Compensation Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/employee/${row.id}/compplan`)
                    })
                  }}
                >
                  <EyeOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasUnapprovedPlan && (
              <Tooltip title="Review Unapproved Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      // Note: You'll need to replace this with the actual way to get the approval ID
                      navigate(`/admin/approvals/${row.approvalBatchId}/edit`)
                    })
                  }}
                >
                  <AuditOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasNoPlan && organizationIdsWithPlanWrite.includes(row.organizationId) && (
              <Tooltip title="Create New Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/admin/templates/select/${row.id}`)
                    })
                  }}
                >
                  <FormOutlined />
                </IconButton>
              </Tooltip>
            )}
            {!hasNoPlan && organizationIdsWithPlanWrite.includes(row.organizationId) && (
              <Tooltip title="Edit Employee Compensation Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/create-comp-plan?userId=${row.id}`)
                    })
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            )}
            {organizationIdsWithMembersWrite.includes(row.organizationId) && (
              <Tooltip title="Edit Employee Profile">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/admin/users/profile?userId=${row.id}`)
                    })
                  }}
                >
                  <UserOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
  ]

  return (
    <>
      <Grid container pb={2}>
        <Grid size={{ xs: 4 }}>
          <SimpleTitle title="Team" />
        </Grid>
        <Grid size={{ xs: 6 }}></Grid>
        {canManageTeamStructure && (
          <Grid size={{ xs: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<TeamOutlined />}
              onClick={() => navigate('/admin/organizations/manage')}
              sx={{ borderColor: 'transparent', whiteSpace: 'nowrap', width: '100%' }}
            >
              Manage Team Structure
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 3 }}>
          <SearchInput
            id="team-search-input"
            key="team-search-input"
            onSearch={handleSearch}
            initialValue={search}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 3 }}></Grid>
        {canManagePlans ? (
          <Grid size={{ xs: 2 }}>
            <Box sx={{ width: '100%' }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel sx={{ background: `${theme.palette.background.default} !important` }}>
                  Select Plan Filters
                </InputLabel>
                <Select
                  labelId="filter-label"
                  variant="outlined"
                  multiple
                  value={planFilters}
                  onChange={handleFilterChange}
                  renderValue={(selected) => {
                    const count = selected.length
                    return `Filtered Plans (${count})`
                  }}
                  style={{ borderRadius: '21px' }}
                  fullWidth
                >
                  {[
                    { value: 'withoutPlans', label: 'No Plan' },
                    { value: 'unapproved', label: 'Plan Not Approved' },
                    { value: 'approved', label: 'Plan Approved' },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={planFilters.includes(option.value)} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        ) : (
          <Grid size={{ xs: 2 }}></Grid>
        )}
        <Grid size={{ xs: 4 }}>
          <Grid container>
            <Grid size={{ xs: 11 }}>
              <MultiOrganizationSelector
                userProfile={profile!}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={setSelectedOrganizations}
                labelBackgroundColor={theme.palette.background.default}
              />
            </Grid>
            <Grid size={{ xs: 1 }}>
              <Tooltip title="Clear Filters">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedOrganizations([])
                    setPlanFilters(['withoutPlans', 'unapproved', 'approved'])
                    setSearch('')
                  }}
                >
                  <CloseCircleOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            size={{ xs: 12 }}
            style={{
              display: 'flex', // Enables flexbox
              justifyContent: 'center', // Centers horizontally
              alignItems: 'center', // Centers vertically
              height: '100%', // Takes full height of the container
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          {/* table */}
          <TableContainer ref={tableContainerRef} sx={{ maxHeight: 'calc(100vh - 400px)', minHeight: '300px' }}>
            <Table
              stickyHeader
              size="small"
              aria-label="team-table"
              sx={{
                width: '100%',
                tableLayout: 'fixed',
                '& .MuiTableCell-stickyHeader': {
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                },
              }}
            >
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      id={`cell-col-${column.id || ''}`}
                      sx={{
                        ...column.sx,
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      {column.id === 'actions' ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          active={sort === column.id}
                          direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((row: KeyedObject) => (
                    <TableRow
                      id={`table-row-${row.id}`}
                      sx={{ py: 3 }}
                      role="checkbox"
                      tabIndex={-1}
                      key={`table-row-${row.id}`}
                    >
                      {columns.map((column) => {
                        let value
                        if (column.id === 'name') {
                          value = `${row.firstName} ${row.lastName}`
                        } else if (column.id === 'organization') {
                          const orgId = row.metadata?.organizationId
                          value = orgId ? organizationMap[orgId] || '' : ''
                        } else {
                          value = row[column.id]
                        }
                        return (
                          <TableCell
                            id={`cell-${column.id}`}
                            key={`cell-${column.id}`}
                            align={column.align}
                            sx={{
                              ...column.sx,
                            }}
                          >
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{ px: '20px' }}>
            {canManageTeamStructure ? (
              <AnimateButton>
                <Tooltip title="Add a new team member">
                  <Button
                    variant="contained"
                    startIcon={<PlusOutlined />}
                    color="primary"
                    onClick={() =>
                      startTransition(() => {
                        navigate('/admin/users/profile')
                      })
                    }
                  >
                    Team Member
                  </Button>
                </Tooltip>
              </AnimateButton>
            ) : (
              <div />
            )}
            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={pagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          {/* table pagination */}
        </Paper>
      )}
    </>
  )
}

export default Organization
