// Import the correct ColorProps type
import type { ColorProps } from 'types/extended'
import type { LinkTarget, NavItemType } from 'types/menu'

import type { ColorMap } from '.'
// material-ui
import { Avatar, Box, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { handlerActiveItem, handlerDrawerOpen, handlerHorizontalActiveItem, useGetMenuMaster } from 'api/menu'

// project import
import Dot from 'components/@extended/Dot'
import IconButton from 'components/@extended/IconButton'

import useConfig from 'hooks/useConfig'
import { useEffect } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
// types
import { MenuOrientation } from 'types/config'

import { NavActionType } from 'types/menu'

// Update the mapStringToColorProps function
function mapStringToColorProps(color: string): ColorProps {
  const colorMap: { [key: string]: ColorProps } = {
    primary: 'primary',
    secondary: 'secondary',
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
  }
  return colorMap[color] || 'primary' // Default to 'primary' if no match
}

interface Props {
  item: NavItemType
  level: number
  isParents?: boolean
  colorMap: ColorMap
}

function NavItem({ item, level, isParents = false, colorMap }: Props) {
  const theme = useTheme()

  const { menuMaster } = useGetMenuMaster()
  const drawerOpen = menuMaster.isDashboardDrawerOpened
  const openItem = menuMaster.openedItem

  const downLG = useMediaQuery(theme.breakpoints.down('lg'))

  const { menuOrientation } = useConfig()
  let itemTarget: LinkTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  const Icon = item.icon!
  const itemIcon = item.icon ? (
    <Icon
      style={{
        fontSize: drawerOpen ? '1rem' : '1.25rem',
        ...(menuOrientation === MenuOrientation.HORIZONTAL && isParents && { fontSize: 20, stroke: '1.5' }),
      }}
    />
  ) : (
    false
  )

  const { pathname } = useLocation()
  const isSelected = !!matchPath({ path: item.url!, end: false }, pathname) || openItem === item.id

  // active menu item on page load
  useEffect(() => {
    if (pathname === item.url) handlerActiveItem(item.id!)
    // eslint-disable-next-line
  }, [pathname])

  const getColors = () => {
    const mode = theme.palette.mode
    const isCollapsed = !drawerOpen
    return isCollapsed ? colorMap[`${mode}Collapsed`] : colorMap[`${mode}Expanded`]
  }

  const colors = getColors()

  return (
    <>
      {menuOrientation === MenuOrientation.VERTICAL || downLG ? (
        <Box sx={{ position: 'relative' }}>
          <ListItemButton
            component={Link}
            to={item.url!}
            target={itemTarget}
            disabled={item.disabled}
            selected={isSelected}
            sx={{
              zIndex: 1201,
              pl: drawerOpen ? `${level * 28}px` : 1.5,
              py: !drawerOpen && level === 1 ? 1.25 : 1,
              ...(drawerOpen && {
                '&:hover': {
                  bgcolor: colors.itemHoverBackground,
                },
                '&.Mui-selected': {
                  bgcolor: colors.itemSelectedBackground,
                  borderRight: `2px solid ${colors.itemSelectedIcon}`,
                  color: colors.itemSelectedText,
                  '&:hover': {
                    color: colors.itemSelectedText,
                    bgcolor: colors.itemSelectedBackground,
                  },
                },
              }),
              ...(!drawerOpen && {
                '&:hover': {
                  bgcolor: 'transparent',
                },
                '&.Mui-selected': {
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  bgcolor: 'transparent',
                },
              }),
            }}
            {...(downLG && {
              onClick: () => handlerDrawerOpen(false),
            })}
          >
            {itemIcon && (
              <ListItemIcon
                sx={{
                  minWidth: 28,
                  color: isSelected ? colors.itemSelectedIcon : colors.itemText,
                  ...(!drawerOpen && {
                    borderRadius: 1.5,
                    width: 36,
                    height: 36,
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      bgcolor: colors.itemHoverBackground,
                    },
                  }),
                  mr: 1,
                  ...(!drawerOpen &&
                    isSelected && {
                      bgcolor: colors.itemSelectedBackground,
                      '&:hover': {
                        bgcolor: colors.itemSelectedBackground,
                      },
                    }),
                }}
              >
                {itemIcon}
              </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color: isSelected ? colors.itemSelectedText : colors.itemText,
                      // fontWeight: isSelected ? 'bold' : 'normal',
                    }}
                  >
                    {item.title}
                  </Typography>
                }
              />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
              <Chip
                color={item.chip.color}
                variant={item.chip.variant}
                size={item.chip.size}
                label={item.chip.label}
                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              />
            )}
          </ListItemButton>
          {(drawerOpen || (!drawerOpen && level !== 1)) &&
            item?.actions &&
            item?.actions.map((action, index) => {
              const ActionIcon = action.icon!
              const callAction = action?.function
              return (
                <IconButton
                  key={index}
                  {...(action.type === NavActionType.FUNCTION && {
                    onClick: (event) => {
                      event.stopPropagation()
                      callAction()
                    },
                  })}
                  {...(action.type === NavActionType.LINK && {
                    component: Link,
                    to: action.url,
                    target: action.target ? '_blank' : '_self',
                  })}
                  color="secondary"
                  variant="outlined"
                  sx={{
                    position: 'absolute',
                    top: 12,
                    right: 20,
                    zIndex: 1202,
                    width: 20,
                    height: 20,
                    mr: -1,
                    ml: 1,
                    color: isSelected ? colors.itemSelectedIcon : colors.itemText,
                    borderColor: isSelected ? colors.itemSelectedIcon : colors.itemText,
                    '&:hover': { borderColor: isSelected ? colors.itemSelectedIcon : colors.itemText },
                  }}
                >
                  <ActionIcon style={{ fontSize: '0.625rem' }} />
                </IconButton>
              )
            })}
        </Box>
      ) : (
        <ListItemButton
          component={Link}
          to={item.url!}
          target={itemTarget}
          disabled={item.disabled}
          selected={isSelected}
          {...(isParents && {
            onClick: () => {
              handlerHorizontalActiveItem(item.id!)
            },
          })}
          sx={{
            zIndex: 1201,
            '&:hover': {
              bgcolor: 'transparent',
            },
            ...(isParents && {
              p: 1,
              mr: 1,
            }),
            '&.Mui-selected': {
              bgcolor: 'transparent',
              '&:hover': {
                bgcolor: 'transparent',
              },
            },
          }}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 28,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 28,
                  height: 28,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }),
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          {!itemIcon && (
            <ListItemIcon
              sx={{
                color: isSelected ? colors.itemSelectedIcon : colors.itemText,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }),
              }}
            >
              <Dot size={4} color={mapStringToColorProps(isSelected ? colors.itemSelectedIcon : colors.itemText)} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography variant="h6" color={isSelected ? colors.itemSelectedText : colors.itemText}>
                {item.title}
              </Typography>
            }
          />
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      )}
    </>
  )
}

export default NavItem
