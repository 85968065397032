import type { Period } from 'types/periods'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import { v4 as uuidv4 } from 'uuid'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
const dateFormatString = 'YYYY-MM-DD'

export enum PeriodLevels {
  MONTH = 'MONTH',
  QUARTERLY = 'QUARTERLY',
  BIANNUAL = 'BIANNUAL',
  ANNUAL = 'ANNUAL',
}

function formatDate(date: Date): string {
  // Extract month, day, and year from the date
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()

  // Combine them into the desired format
  return `${year}-${month}-${day}`
}

export function generatePeriods(rootOrg: string, start: Date, level: PeriodLevels, years: number = 1): Period[] {
  // Ensure the start date is the first of the month
  const rootPeriodStart = new Date(start.getFullYear(), start.getMonth(), 1)
  const year = rootPeriodStart.getFullYear().toString()
  const rootPeriodEnd = new Date(rootPeriodStart)
  rootPeriodEnd.setFullYear(rootPeriodEnd.getFullYear() + 1)
  rootPeriodEnd.setDate(rootPeriodEnd.getDate() - 1)

  const newPeriods: Period[] = []

  for (let y = 0; y < years; y++) {
    const yStartDate = new Date(rootPeriodStart)
    yStartDate.setFullYear(yStartDate.getFullYear() + y)
    const yEndDate = new Date(rootPeriodEnd)
    yEndDate.setFullYear(yEndDate.getFullYear() + y)
    const yPeriod: Period = {
      id: uuidv4(),
      organizationId: rootOrg,
      label: `FY-${Number.parseInt(year) + y}`,
      startDate: formatDate(yStartDate),
      endDate: formatDate(yEndDate),
      isClosed: false,
      children: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null,
    }

    // Generate half-year periods

    for (let h = 0; h < 2; h++) {
      const hStartDate = new Date(rootPeriodStart)
      hStartDate.setFullYear(hStartDate.getFullYear() + y)
      hStartDate.setMonth(hStartDate.getMonth() + 6 * h)
      const hEndDate = new Date(hStartDate)
      hEndDate.setMonth(hEndDate.getMonth() + 6)
      hEndDate.setDate(hEndDate.getDate() - 1)

      const hPeriod: Period = {
        id: uuidv4(),
        organizationId: rootOrg,
        label: `FY-${Number.parseInt(year) + y} H${h + 1}`,
        startDate: formatDate(hStartDate),
        endDate: formatDate(hEndDate),
        isClosed: false,
        children: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        deletedAt: null,
      }

      yPeriod.children.push(hPeriod)

      // Generate quarters
      for (let q = 0; q < 2; q++) {
        const qStartDate = new Date(hStartDate)
        qStartDate.setMonth(qStartDate.getMonth() + 3 * q)
        const qEndDate = new Date(qStartDate)
        qEndDate.setMonth(qEndDate.getMonth() + 3)
        qEndDate.setDate(qEndDate.getDate() - 1)

        const qPeriod: Period = {
          id: uuidv4(),
          organizationId: rootOrg,
          label: `FY-${Number.parseInt(year) + y} Q${h * 2 + q + 1}`,
          startDate: formatDate(qStartDate),
          endDate: formatDate(qStartDate),
          isClosed: false,
          children: [],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          deletedAt: null,
        }

        hPeriod.children.push(qPeriod)

        // Generate months
        for (let m = 0; m < 3; m++) {
          const mStartDate = new Date(qStartDate)
          mStartDate.setMonth(mStartDate.getMonth() + m)
          const mEndDate = new Date(mStartDate)
          mEndDate.setMonth(mEndDate.getMonth() + 1)
          mEndDate.setDate(mEndDate.getDate() - 1)

          const mPeriod: Period = {
            id: uuidv4(),
            organizationId: rootOrg,
            label: `FY-${Number.parseInt(year) + y} M${(mStartDate.getMonth() + 1).toString().padStart(2, '0')}`,
            startDate: formatDate(mStartDate),
            endDate: formatDate(mStartDate),
            isClosed: false,
            children: [],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            deletedAt: null,
          }

          qPeriod.children.push(mPeriod)
        }
      }
    }
    newPeriods.push(yPeriod)
  }

  return newPeriods
}

// Check if the date in already covered by the existing periods. This is done at the root level
// of the tree of periods. If the newDate isBetween the dates of a root period then the period
// range cover the date of the newDate.
export function checkDateAgainstExistingPeriods(existingPeriods: Period[], newDate: dayjs.Dayjs): boolean {
  if (!existingPeriods) {
    return false
  }
  return existingPeriods.some((p) =>
    newDate.isBetween(dayjs(p.startDate, dateFormatString), dayjs(p.endDate, dateFormatString), null, '[]')
  )
}

// Check if any of the newly generate periods conflict with the existing periods.
export function hasPeriodOverlapAgainstExistingPeriods(existingPeriods: Period[], newPeriods: Period[]): boolean {
  // Loop through the root periods of newPeriods
  if (!existingPeriods) {
    return false
  }
  for (const newPeriod of newPeriods) {
    // Check if this new period overlaps with any existing period
    const hasOverlap = existingPeriods.some(
      (existingPeriod) =>
        dayjs(newPeriod.startDate, dateFormatString).isBetween(
          dayjs(existingPeriod.startDate, dateFormatString),
          dayjs(existingPeriod.endDate, dateFormatString),
          null,
          '[]'
        ) ||
        dayjs(newPeriod.endDate, dateFormatString).isBetween(
          dayjs(existingPeriod.startDate, dateFormatString),
          dayjs(existingPeriod.endDate, dateFormatString),
          null,
          '[]'
        ) ||
        dayjs(existingPeriod.startDate, dateFormatString).isBetween(
          dayjs(newPeriod.startDate, dateFormatString),
          dayjs(newPeriod.endDate, dateFormatString),
          null,
          '[]'
        )
    )

    return hasOverlap
  }

  return false
}

export function getCurrentRootPeriod(periods: Period[]): Period | null {
  if (!periods || periods.length === 0) {
    return null
  }
  const currentDate = dayjs()
  const currentPeriod = periods.find((p) =>
    currentDate.isBetween(dayjs(p.startDate, dateFormatString), dayjs(p.endDate, dateFormatString), null, '[]')
  )

  return currentPeriod || null
}
