import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

function Login() {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    // Trigger the Auth0 login redirect when the component mounts
    void loginWithRedirect()
  }, [loginWithRedirect])

  // Optionally, you can return a placeholder while redirecting:
  return null
}

export default Login
